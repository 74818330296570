define("atcm-client/utils/number-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // HACK: Used to help format table cells, should be refactored or use a library
  // TODO(azirbel): Should be a handlebars helper
  var _default = {
    toCurrency: function (num) {
      let value;

      if (isNaN(num) || !isFinite(num)) {
        return "-";
      }

      value = Math.abs(num).toFixed(2);
      value = value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      return (num < 0 ? "-" : "") + value;
    },
    toPercent: function (num) {
      if (isNaN(num) || !isFinite(num)) {
        return "-";
      }

      return Math.abs(num * 100).toFixed(2) + "%";
    }
  };
  _exports.default = _default;
});