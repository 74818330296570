define("atcm-client/controllers/client/models/issuer-fundamental", ["exports", "jquery", "atcm-client/enums/focussus"], function (_exports, _jquery, _focussus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    esgRatios: null,
    focussus: _focussus.default,

    init() {
      this._super(...arguments);

      this.selectedIssuers = [];
    },

    disabled: Ember.computed("selectedIssuers.length", function () {
      return this.get("selectedIssuers.length") === 0;
    }),
    chartOptions: Ember.computed("esgRatios", function () {
      return {
        chart: {
          type: "bar",
          height: this.esgRatios.categories.length * 20
        },
        title: {
          text: "Issuer Sustainability Ranking"
        },
        xAxis: {
          categories: this.esgRatios.categories,
          labels: {
            minTickInterval: 1,
            useHTML: true,
            formatter: function () {
              return "<label>" + this.value.name + '</label>&nbsp;<input type="checkbox" class="issuer" id="' + this.value.id + '">';
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: "Total Sustainability"
          }
        },
        legend: {
          reversed: true
        },
        plotOptions: {
          bar: {
            pointWidth: 10
          },
          series: {
            stacking: "normal"
          }
        }
      };
    }),
    chartData: Ember.computed("esgRatios", function () {
      return this.esgRatios.chartData;
    }),
    _checkboxObserver: Ember.on("init", function () {
      this.set("selectedIssuers", (0, _jquery.default)(".issuer[id]").map(function () {
        if (this.checked) {
          return this.id;
        }
      }).get());
      Ember.run.later(this, this._checkboxObserver, 500);
    }),
    actions: {
      showSecurityFundamentals() {
        this.set("selectedIssuers", (0, _jquery.default)(".issuer[id]").map(function () {
          if (this.checked) {
            return this.id;
          }
        }).get());
        this.transitionToRoute("client.models.security-fundamental", this.model);
      }

    }
  });

  _exports.default = _default;
});