define("atcm-client/router", ["exports", "atcm-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    /* everything after the /admin route should be authorised, the AdminRoute will redirect */
    this.route("tenants");
    this.route("client", function () {
      this.route("profile");
      this.route("core", function () {
        this.route("currencies", function () {
          this.route("entry");
          this.route("edit", {
            path: "/:currency_id"
          });
        });
        this.route("organisations", function () {
          this.route("entry");
          this.route("edit", {
            path: "/:organisation_id"
          });
        });
        this.route("gicsindustrysubs", {
          path: "gics-industry-subs"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:gics_industry_sub_id"
          });
        });
        this.route("gicsindustries", {
          path: "gics-industries"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:gics_industries_id"
          });
        });
        this.route("gicsindustrygroups", {
          path: "gics-industry-groups"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:gics_industry_group_id"
          });
        });
        this.route("gicssectors", {
          path: "gics-sectors"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:gics_sector_id"
          });
        });
        this.route("impactobjectivemains", {
          path: "impact-objective-mains"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:impact_objective_main_id"
          });
          this.route("impactobjectivesubs", {
            path: "/:impact_objective_main_id/impactobjectivesubs"
          });
        });
        this.route("impactobjectivesubs", {
          path: "impact-objective-subs"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:impact_objective_sub_id"
          });
          this.route("indicators", {
            path: "/:impact_objective_sub_id/indicators"
          });
        });
        this.route("impactobjectiveindicators", {
          path: "impact-objective-indicators"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:impact_objective_indicator_id"
          });
        });
        this.route("indicatorratiojoins", {
          path: "indicator-ratio-joins"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:indicator_ratio_join_id"
          });
        });
        this.route("organisations", function () {
          this.route("entry");
          this.route("edit", {
            path: "/:organisation_id"
          });
        });
        this.route("organisationtypes", {
          path: "organisation-types"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:organisation_type_id"
          });
        });
        this.route("users", function () {
          this.route("entry");
          this.route("edit", {
            path: "/:user_id"
          });
        });
        this.route("ratiogroupsubs", {
          path: "ratio-group-subs"
        }, function () {
          this.route("entry");
          this.route("ratios", {
            path: "/:ratio_group_sub_id/ratios"
          });
        });
        this.route("ratios", function () {
          this.route("entry");
          this.route("edit", {
            path: "/:ratio_id"
          });
        });
      });
      this.route("susty", function () {
        this.route("securityfundamentals", {
          path: "security-fundamentals"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:security_fundamental_id"
          });
        });
        this.route("countryfundamentals", {
          path: "country-fundamentals"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:country_fundamental_id"
          });
        });
        this.route("issuerfundamentals", {
          path: "issuer-fundamentals"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:issuer_fundamental_id"
          });
        });
      }); // all the routes below the IMPACT menu

      this.route("impact", function () {
        this.route("impactobservations", {
          path: "impact-observations"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:impact_observation_id"
          });
        });
        this.route("impactobservationtypes", {
          path: "impact-observation-types"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:impact_observation_type_id"
          });
        });
        this.route("companyimpacts", {
          path: "company-impacts"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:company_impact_id"
          });
          this.route("performances", {
            path: "/:company_impact_id/companyimpactperformances"
          });
        });
        this.route("companyimpactperformances", {
          path: "company-impact-performances"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:company_impact_performance_id"
          });
        });
        this.route("meassurementunits", {
          path: "meassurement-units"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:meassurement_unit_id"
          });
        });
        this.route("issuerassessments", {
          path: "issuer-assessments"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:issuer_assessment_id"
          });
        });
        this.route("unglobalcompacts", {
          path: "companies"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:un_global_compact_id"
          });
        });
        this.route("unglobaltypes", {
          path: "un-global-types"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:un_global_type_id"
          });
        });
        this.route("unglobalsectors", {
          path: "un-global-sectors"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:un_global_sector_id"
          });
        });
      });
      this.route("marketanalyses", function () {
        this.route("assetmanagers", {
          path: "asset-managers"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:asset_manager_id"
          });
          this.route("funds", {
            path: "/:asset_manager_id/funds"
          });
        });
        this.route("managedfundholdings", {
          path: "managed-fund-holdings"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:managed_fund_holding_id"
          });
        });
        this.route("companyimpacts", {
          path: "company-self-assessments"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:company_impacts_id"
          });
        });
        this.route("companies", {
          path: "companies"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:company_id"
          });
        });
        this.route("managedfunds", {
          path: "managed-funds"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:managed_fund_id"
          });
          this.route("holdings", {
            path: "/:managed_fund_id/managedfundholdings"
          });
        });
        this.route("companyimpactperformances", {
          path: "company-impact-performances"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:company_impact_performance_id"
          });
        });
      });
      this.route("staticdata", function () {
        this.route("issuerstatics", {
          path: "issuer-statics"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:issuer_static_id"
          });
          this.route("ratings", {
            path: "/:issuer_static_id/issuerratings"
          });
          this.route("securities", {
            path: "/:company_static_id/securitystatics"
          });
          this.route("impacts", {
            path: "/:company_static_id/companyimpacts"
          });
        });
        this.route("issuerratings", {
          path: "issuer-ratings"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:issuer_rating_id"
          });
        });
        this.route("companyimpacts", {
          path: "company-impacts"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:company_impact_id"
          });
        });
        this.route("indexstatics", {
          path: "index-statics"
        }, function () {
          this.route("entry");
          this.route("constituents", {
            path: "/:index_static_id/index-constituents"
          });
        });
        this.route("intereststatics", {
          path: "interest-statics"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:interest_static_id"
          });
        });
        this.route("futuresstatics", {
          path: "futures-statics"
        }, function () {
          this.route("entry");
          this.route("futuresstaticdeliverymonths", {
            path: "/:futures_static_id/futures-static-delivery-months"
          });
        });
        this.route("futuresstaticdeliverymonths", {
          path: "futures-static-delivery-months"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:futures_static_delivery_month_id"
          });
        });
        this.route("referencerates", {
          path: "reference-rates"
        }, function () {
          this.route("entry");
          this.route("intereststatics", {
            path: "/:reference_rate_id/interest-statics"
          });
        });
        this.route("indexconstituents", {
          path: "index-constituents"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:index_constituent_id"
          });
        });
        this.route("securitystatics", {
          path: "security-statics"
        }, function () {
          this.route("entry");
          this.route("securitystaticratings", {
            path: "/:security_static_id/security-static-ratings"
          });
          this.route("securitylistings", {
            path: "/:security_static_id/security-listings"
          });
        });
        this.route("securitylistings", {
          path: "security-listings"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:security_listing_id"
          });
        });
        this.route("securitystaticratings", {
          path: "security-static-ratings"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:security_static_rating_id"
          });
        });
        this.route("exchangestatics", {
          path: "exchange-statics"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:exchange_static_id"
          });
        });
        this.route("custodianstatics", {
          path: "custodian-statics"
        }, function () {
          this.route("entry");
          this.route("edit");
          this.route("accounts", {
            path: "/:custodian_static_id/custodian-static-accounts"
          });
          this.route("fees", {
            path: "/:custodian_static_id/custodian-static-fee-schedules"
          });
        });
        this.route("custodianstaticaccounts", {
          path: "custodian-accounts"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:custodian_static_account_id"
          });
        });
        this.route("custodianstaticfeeschedules", {
          path: "custodian-static-fee-schedules"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:custodian_static_fee_schedule_id"
          });
        });
        this.route("brokerstatics", {
          path: "broker-statics"
        }, function () {
          this.route("entry");
          this.route("brokerstaticaccounts", {
            path: "/:broker_static_id/broker-static-accounts"
          });
        });
        this.route("brokerstaticaccounts", {
          path: "broker-static-accounts"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:broker_static_account_id"
          });
        });
        this.route("issuerstaticratings", {
          path: "issuer-static-ratings"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:issuer_static_rating_id"
          });
        });
        this.route("datavendorstatics", {
          path: "datavendor-statics"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:data_vendor_static_id"
          });
        });
        this.route("accountmasters", {
          path: "account-masters"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:account_master_id"
          });
          this.route("positions", {
            path: "/:account_master_id/positions"
          });
          this.route("participations", {
            path: "/:account_master_id/participations"
          });
        });
        this.route("orderbaskets", {
          path: "order-baskets"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:order_basket_id"
          });
        });
        this.route("participationstatics", {
          path: "participations"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:participation_static_id"
          });
        });
        this.route("holidaycalendarmains", {
          path: "holiday-calendar-mains"
        }, function () {
          this.route("entry");
          this.route("holidaycalendarsubs", {
            path: "/:holiday_calendar_main_id/holiday-calendar-subs"
          });
        });
        this.route("holidaycalendarsubs", {
          path: "holiday-calendar-subs"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:holiday_calendar_sub_id"
          });
        });
        this.route("counterpartystatics", {
          path: "counterparty-statics"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:counterparty_static_id"
          });
          this.route("accounts", {
            path: "/:account_master_id/accounts"
          });
          this.route("ratings", {
            path: "/:account_master_id/ratings"
          });
        });
        this.route("counterpartystaticratings", {
          path: "counterparty-static-ratings"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:counterparty_static_rating_id"
          });
        });
        this.route("ratingstatics", {
          path: "rating-statics"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:rating_static_id"
          });
        });
        this.route("counterpartystaticaccounts", {
          path: "counterparty-accounts"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:counterparty_static_account_id"
          });
        });
        this.route("exchangerates", {
          path: "exchange-rates"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:exchange_rate_id"
          });
        });
        this.route("interestrates", {
          path: "interest-rates"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:interest_rate_id"
          });
        });
        this.route("securityprices", {
          path: "security-prices"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:security_price_id"
          });
        });
      });
      this.route("analyses", function () {
        this.route("technical");
        this.route("blends");
        this.route("impactobservations", {
          path: "impact-observations"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:impact_observation_id"
          });
        });
        this.route("countryratings", {
          path: "country-ratings"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:country_rating_id"
          });
        });
      });
      this.route("models", function () {
        this.route("investmentprofiles", {
          path: "investment-profiles"
        }, function () {
          this.route("entry");
          this.route("investmentprofileratios", {
            path: "/:investment_profile_id/investment-profile-ratios"
          });
        });
        this.route("investmentprofileratios", {
          path: "investment-profile-ratios"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:investment_profile_ratio_id"
          });
        });
        this.route("entry");
        this.route("edit", {
          path: "/:investment_model_id"
        });
        this.route("country-fundamental", {
          path: "/:investment_model_id/country-fundamental"
        });
        this.route("issuer-fundamental", {
          path: "/:investment_model_id/issuer-fundamental"
        });
        this.route("security-fundamental", {
          path: "/:investment_model_id/security-fundamental"
        });
        this.route("all-fundamental", {
          path: "/:investment_model_id/all-fundamental"
        });
      });
      this.route("compliance", function () {
        this.route("compliancerules", {
          path: "compliance-rules"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:compliance_rule_id"
          });
        });
        this.route("compliancebaskets", {
          path: "compliance-baskets"
        }, function () {
          this.route("entry");
          this.route("complianceaccountbaskets", {
            path: "/:compliance_basket_id/compliance-accountbasketss"
          });
        });
        this.route("compliancerulevalues", {
          path: "compliance-rule-values"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:compliance_rule_value_id"
          });
        });
        this.route("complianceaccountbaskets", {
          path: "compliance-accounts-baskets"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:compliance_account_basket_id"
          });
        });
      });
      this.route("transactions", function () {
        this.route("block", {
          path: "/:block_trade_id"
        });
        this.route("securitytransactions", {
          path: "security-transactions"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:security_transaction_id"
          });
        });
        this.route("securitytransactionfills", {
          path: "security-transaction-fills"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:security_transaction_fill_id"
          });
        });
        this.route("foreignexchangecontracts", {
          path: "fx-contracts"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:foreign_exchange_contract_id"
          });
        });
        this.route("orders", function () {
          this.route("entry");
          this.route("edit", {
            path: "/:security_order_main_id"
          });
          this.route("compliance", {
            path: "/compliance/:investment_model_order_id"
          });
        });
        this.route("investmentmodelorders", {
          path: "investment-model-orders"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:investment_model_order_id"
          });
        });
      }); // all the routes below the performances menu

      this.route("performances", function () {
        this.route("accountmasterfundamentals", {
          path: "account-master-fundamentals"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:account_master_fundamental_id"
          });
        });
      });
      this.route("reports", function () {
        this.route("securitypositions", {
          path: "security-positions"
        }, function () {
          this.route("entry");
          this.route("edit", {
            path: "/:security_position_id"
          });
        });
      });
    });
  });
});