define("atcm-client/utils/socket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import notifier from './notifier';
  var _default = {
    initialize: function (io, config, store, adapter, token) {
      if (!Ember.isEmpty(token)) {
        const socket = io.connect(config.APP.SOCKETHOST, {
          query: "token=" + token
        });
        let sessionId = ""; //inject the socket in all routes

        socket.on("connect", () => {
          sessionId = socket.io.engine.id;
          adapter.set("headers.socket", sessionId);
        });
        socket.on("new", message => {
          if (message.origin !== sessionId) {
            if (!store.hasRecordForId(message.type, message.data.id)) {// var type = store.modelFor(message.type);
              // var serializer = store.serializerFor(message.type);
              // var payload = serializer.extract(store, type, message.data, null, 'single');
              //
              // store.push(type, payload);
              // notifier.processNewObject('new', message.type, payload);
            } else {
              throw "Tried to push new object in store with duplicate ID!";
            }
          }
        });
        socket.on("update", message => {
          if (message.origin !== sessionId) {// var type = store.modelFor(message.type);
            // var serializer = store.serializerFor(message.type);
            // var payload = serializer.extract(store, type, message.data, null, 'single');
            // store.push(type, payload);
          }
        });
        socket.on("delete", message => {
          if (message.origin !== sessionId) {
            store.findRecord(message.type, message.id).then(model => {
              Ember.run.next(() => {
                if (!model.get("isDirty")) {
                  store.unloadRecord(model);
                }
              });
            });
          }
        });
        socket.on("disconnect", () => {});
      }
    }
  };
  _exports.default = _default;
});