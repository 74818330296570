define("atcm-client/controllers/client/staticdata/indexstatics/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Currency",
        displayField: "currencies",
        queryField: "currency",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Underlying Type",
        displayField: "underlyingTypes",
        queryField: "underlyingType",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Geographic Scope",
        displayField: "geographicScopes",
        queryField: "geographicScope",
        displayType: "typeahead",
        isTypeahead: true
      }];
      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true
      }, {
        displayName: "Short",
        displayField: "descriptionShort",
        required: true
      }, {
        displayName: "ISIN Code",
        displayField: "isinCode",
        required: true
      }, {
        displayName: "FIGI Code",
        displayField: "figiCode",
        required: true
      }, {
        displayName: "Geographic Scope",
        displayField: "geographicScope.description"
      }, {
        displayName: "Underlying Type",
        displayField: "underlyingType.description"
      }, {
        displayName: "Currency",
        displayField: "currency.isoCode",
        required: true
      }, {
        displayName: "Exchange",
        displayField: "exchangeStatic.descriptionShort",
        required: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short",
        displayField: "descriptionShort"
      }, {
        displayName: "ISIN Code",
        displayField: "isinCode"
      }, {
        displayName: "FIGI Code",
        displayField: "figiCode"
      }, {
        displayName: "Exchange",
        selectionField: "exchangeStatic",
        displayField: "descriptionShort",
        model: "exchangeStatics",
        required: true
      }, {
        displayName: "Select Currency",
        selectionField: "currency",
        displayField: "isoCode",
        model: "currencies",
        required: true
      }, {
        displayName: "Select Underlying Type",
        selectionField: "underlyingType",
        displayField: "description",
        model: "underlyingTypes",
        required: true
      }, {
        displayName: "Select Geographic Scope...",
        selectionField: "geographicScope",
        displayField: "description",
        model: "geographicScopes",
        required: true
      }];
    },

    actions: {
      showConstituents: function (indexStatic) {
        this.transitionToRoute("client.staticdata.indexstatics.constituents", indexStatic);
        return false;
      }
    }
  });

  _exports.default = _default;
});