define("atcm-client/components/simple-table-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    searchService: Ember.inject.service("search"),
    actions: {
      search(modelName, query) {
        return this.searchService.search(modelName, query);
      }

    }
  });

  _exports.default = _default;
});