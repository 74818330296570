define("atcm-client/controllers/client/reports/contractpositions/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Account Master",
        displayField: "accountMaster.descriptionShort"
      }, {
        displayName: "Contract Type",
        displayField: "instrumentType.descriptionShort"
      }, {
        displayName: "Counterparty",
        displayField: "counterpartyStatic.descriptionShort"
      }, {
        displayName: "Contract Number",
        displayField: "contractnumber"
      }];
      this.tableFilters = [{
        displayName: "Account Master",
        displayField: "accountMasters",
        queryField: "accountMaster",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Contract Type",
        displayField: "instrumentTypes",
        queryField: "instrumentType",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Counter Party",
        displayField: "counterpartyStatics",
        queryField: "counterpartyStatic",
        displayType: "typeahead",
        isTypeahead: true
      }];
    },

    editRoute: null
  });

  _exports.default = _default;
});