define("atcm-client/controllers/client/marketanalyses/companies/index", ["exports", "atcm-client/gql/search/companies"], function (_exports, _companies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
      this.tableFields = [{
        name: "Description",
        valuePath: "description"
      }, {
        name: "Legal Enitity Identifier",
        valuePath: "legalEntityIdentifier"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short description",
        displayField: "descriptionShort",
        required: true
      }, {
        displayName: "Investment objective",
        selectionField: "investmentObjective",
        displayField: "descriptionShort",
        model: "investmentObjectives",
        required: true
      }, {
        displayName: "Currency listed...",
        selectionField: "currency",
        displayField: "isoCode",
        model: "currencies",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Legal Entity Identifier",
        displayField: "legalEntityIdentifier",
        required: true
      }];
    },

    actions: {
      fetchMore() {
        if (this.get("model.companies.length") % 10 === 0) {
          const variables = {
            limit: 10,
            offset: this.get("model.companies.length")
          };
          this.apollo.query({
            query: _companies.default,
            variables
          }).then(result => {
            this.get("model.companies").pushObjects(result.companies);
          });
        }
      }

    }
  });

  _exports.default = _default;
});