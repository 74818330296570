define("atcm-client/controllers/client/staticdata/futuresstatics/index", ["exports", "atcm-client/gql/queries/futureStatics"], function (_exports, _futureStatics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.formFields = [{
        displayName: "Asset Class",
        displayField: "instrumentType.assetClass.descriptionShort",
        alignLeft: true
      }, {
        displayName: "Instrument Type",
        displayField: "instrumentType.descriptionShort",
        alignLeft: true
      }, {
        displayName: "Description",
        displayField: "description",
        flex: 15,
        alignLeft: true
      }, {
        displayName: "ISIN Code",
        displayField: "isinCode"
      }, {
        displayName: "FIGI Code",
        displayField: "figiCode"
      }, {
        displayName: "Currency Listed",
        displayField: "currency.isoCode"
      }, {
        displayName: "Country Listed",
        displayField: "country.isoThree"
      }, {
        displayName: "Exchange Listed",
        displayField: "exchangeStatic.descriptionShort"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Instrument type",
        selectionField: "instrumentType",
        displayField: "description",
        model: "instrumentTypes",
        required: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short escription",
        displayField: "descriptionShort",
        required: true
      }, {
        displayName: "ISIN Code",
        displayField: "isinCode"
      }, {
        displayName: "FIGI Code",
        displayField: "figiCode"
      }, {
        displayName: "Exchange Symbol",
        displayField: "exchangeSymbol"
      }, {
        displayName: "Currency",
        selectionField: "currency",
        displayField: "isoCode",
        model: "currencies",
        required: true
      }, {
        displayName: "Country",
        selectionField: "country",
        displayField: "description",
        model: "countries",
        required: true
      }, {
        displayName: "Underlying",
        selectionField: "underlyingType",
        displayField: "description",
        model: "underlyingTypes",
        required: true
      }, {
        displayName: "Underlying Sub",
        selectionField: "underlyingTypeSub",
        displayField: "description",
        model: "underlyingTypeSubs",
        required: true
      }, {
        displayName: "Exchange",
        selectionField: "exchangeStatic",
        displayField: "description",
        model: "exchangeStatics",
        required: true
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Currency",
        displayField: "currencies",
        queryField: "currency",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Country",
        displayField: "countries",
        queryField: "country",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Exchange",
        displayField: "exchangeStatics",
        queryField: "exchangeStatic",
        displayType: "typeahead",
        isTypeahead: true
      }];
    },

    fetchMore() {
      if (this.get("model.futureStatics.length") % 10 === 0) {
        const variables = {
          limit: 10,
          offset: this.get("model.futureStatics.length")
        };
        this.apollo.query({
          query: _futureStatics.default,
          variables
        }).then(result => {
          this.get("model.futureStatics").pushObjects(result.futureStatics);
        });
      }
    },

    actions: {
      showFuturesStaticDeliveryMonths: function (futuresStatic) {
        this.transitionToRoute("client.staticdata.futuresstatics.futuresstaticdeliverymonths", futuresStatic);
        return false;
      }
    }
  });

  _exports.default = _default;
});