define("atcm-client/routes/client/staticdata/securitystatics/securitylistings", ["exports", "atcm-client/routes/secure", "atcm-client/gql/queries/securityListings", "atcm-client/gql/queries/securities"], function (_exports, _secure, _securityListings, _securities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const variables = {
    orderBy: {
      description: "asc"
    }
  };

  var _default = _secure.default.extend({
    apollo: Ember.inject.service(),

    model() {
      const params = this.paramsFor("client.staticdata.securitystatics.securitylistings");
      const variables = {
        id: params.security_static_id
      };
      return this.apollo.query({
        query: _securities.default,
        variables
      }).then(gqlresult => {
        return gqlresult["securityStatics"] && gqlresult["securityStatics"].length ? gqlresult["securityStatics"][0] : null;
      }).catch(error => {
        throw error;
      });
    },

    setupController: function (controller) {
      this._super(...arguments);

      const params = this.paramsFor("client.staticdata.securitystatics.securitylistings");
      controller.set("presetFilters", {
        securityStatic: params.security_static_id
      });
      controller.set("dataVariables", variables);
      controller.set("query", _securityListings.default);
      return;
    }
  });

  _exports.default = _default;
});