define("atcm-client/routes/client/models/issuer-fundamental", ["exports", "atcm-client/routes/secure", "atcm-client/routes/client/models/getInvestmentModel"], function (_exports, _secure, _getInvestmentModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    ajax: Ember.inject.service("ajax"),
    apollo: Ember.inject.service("apollo"),
    model: function (params) {
      return this.apollo.query({
        query: _getInvestmentModel.default,
        variables: {
          id: params.investment_model_id
        }
      }).then(gqlResult => {
        return gqlResult["investmentModels"][0];
      });
    },
    afterModel: function () {
      const countries = this.controllerFor("client.models.country-fundamental").get("selectedCountries");
      const controller = this.controllerFor("client.models.issuer-fundamental");
      return this.ajax._getCustomEndpoint("/issuer-esg-ratios", {
        countryId: countries
      }).then(result => {
        const issuers = Ember.Map.create(),
              categories = [],
              chartData = [],
              chartDataMap = Ember.Map.create(),
              issuerIdMap = Ember.Map.create();
        result.forEach(ratio => {
          issuerIdMap.set(ratio.issuer, ratio.issuerId);
          const weightedValue = parseFloat(ratio.value);

          if (!chartDataMap.has(ratio.ratioGroup)) {
            chartDataMap.set(ratio.ratioGroup, []);
          }

          let issuerMap;

          if (issuers.has(ratio.issuer)) {
            issuerMap = issuers.get(ratio.issuer);

            if (issuerMap.has(ratio.ratioGroup)) {
              issuerMap.set(ratio.ratioGroup, issuerMap.get(ratio.ratioGroup) + weightedValue);
              issuerMap.set("total-" + ratio.ratioGroup, issuerMap.get("total-" + ratio.ratioGroup) + weightedValue);
            } else {
              issuerMap.set(ratio.ratioGroup, weightedValue);
              issuerMap.set("total-" + ratio.ratioGroup, weightedValue);
            }
          } else {
            issuerMap = Ember.Map.create();
            issuerMap.set(ratio.ratioGroup, weightedValue);
            issuerMap.set("total-" + ratio.ratioGroup, weightedValue);
            issuers.set(ratio.issuer, issuerMap);
          }
        });

        const ratioGroupsArray = chartDataMap._keys.toArray(),
              tempIssuers = Ember.A([]);

        const totalScoreAllIssuers = Ember.Map.create();
        issuers.forEach((value, issuer) => {
          const obj = Ember.Object.create();
          obj.set("issuer", issuer);
          obj.set("issuerId", issuerIdMap.get(issuer));
          ratioGroupsArray.forEach(ratioGroup => {
            obj.set("total-" + ratioGroup, value.get("total-" + ratioGroup));

            if (totalScoreAllIssuers.has(ratioGroup)) {
              totalScoreAllIssuers.set(ratioGroup, totalScoreAllIssuers.get(ratioGroup) + (value.get("total-" + ratioGroup) !== undefined ? value.get("total-" + ratioGroup) : 0));
            } else {
              totalScoreAllIssuers.set(ratioGroup, value.get("total-" + ratioGroup) !== undefined ? value.get("total-" + ratioGroup) : 0);
            }
          });
          tempIssuers.pushObject(obj);
        });
        const gemiddeldeMap = Ember.Map.create();
        totalScoreAllIssuers.forEach((value, key) => {
          const gemiddelde = value / tempIssuers.get("length");
          gemiddeldeMap.set(key, gemiddelde);
        });
        const mathMap = Ember.Map.create();
        totalScoreAllIssuers.forEach((value, key) => {
          let variantieTotal = 0;
          tempIssuers.forEach(issuer => {
            const a = (issuers.get(issuer.get("issuer")).get(key) !== undefined ? issuers.get(issuer.get("issuer")).get(key) : 0) - gemiddeldeMap.get(key);
            variantieTotal += a * a;
          });
          const variantie = variantieTotal / (tempIssuers.get("length") - 1);
          const standaardafwijking = Math.sqrt(variantie);
          mathMap.set(key, {
            variantie: variantie,
            standaardafwijking: standaardafwijking,
            gemiddelde: gemiddeldeMap.get(key)
          });
        }); // sumweights per ratioGroup * zscore land

        const minzscores = Ember.Map.create(),
              maxzscores = Ember.Map.create();
        tempIssuers.forEach(issuer => {
          ratioGroupsArray.forEach(ratioGroup => {
            //TODO is the default value 0? or AVG?
            let value = 0;

            if (issuers.get(issuer.get("issuer")).has(ratioGroup)) {
              value = issuers.get(issuer.get("issuer")).get(ratioGroup);
            }

            const zscore = (value - mathMap.get(ratioGroup).gemiddelde) / mathMap.get(ratioGroup).standaardafwijking;
            issuer.set("zscore-" + ratioGroup, zscore);

            if (minzscores.has(ratioGroup)) {
              if (minzscores.get(ratioGroup) > zscore) {
                minzscores.set(ratioGroup, zscore);
              }
            } else {
              minzscores.set(ratioGroup, zscore);
            }

            if (maxzscores.has(ratioGroup)) {
              if (maxzscores.get(ratioGroup) < zscore) {
                maxzscores.set(ratioGroup, zscore);
              }
            } else {
              maxzscores.set(ratioGroup, zscore);
            }
          });
        }); // zscores omzetten in punten

        tempIssuers.forEach(issuer => {
          let total = 0;
          ratioGroupsArray.forEach(ratioGroup => {
            const absmin = Math.abs(minzscores.get(ratioGroup));
            const newmax = maxzscores.get(ratioGroup) + absmin;
            const score = (issuer.get("zscore-" + ratioGroup) + absmin) / newmax * 100; // TODO what about this?
            // if (ratioGroup === "Environmental") {
            //   score = score * 0.3;
            // } else if (ratioGroup === "Social") {
            //   score = score * 0.3333;
            // }

            issuer.set("score-" + ratioGroup, score / 20);
            total += score;
          });
          issuer.set("totalscore", total / 20);
        });
        tempIssuers.sortBy("totalscore").toArray().reverse().forEach(issuer => {
          chartDataMap.forEach((value, key) => {
            chartDataMap.get(key).push(issuer.get("score-" + key));
          });
          categories.push({
            name: issuer.get("issuer"),
            id: issuer.get("issuerId")
          });
        });
        chartDataMap.forEach((value, key
        /* , map*/
        ) => {
          chartData.push({
            name: key,
            data: value
          });
        });
        controller.set("esgRatios", {
          categories: categories,
          chartData: chartData
        });
      });
    }
  });

  _exports.default = _default;
});