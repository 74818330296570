define("atcm-client/models/country", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    description: DS.attr("string"),
    isoTwo: DS.attr("string"),
    isoThree: DS.attr("string"),
    isoNumber: DS.attr("number"),
    bloombergCode: DS.attr("string"),
    fipsCode: DS.attr("string"),

    /* TODO this needs to be a relation, or the name needs to change, this will never work */
    // weekendStartId: DS.attr('string'),
    // weekendEndId: DS.attr('string'),
    region: DS.belongsTo("region", {
      async: true
    }),
    custodianStatics: DS.hasMany("custodian-static"),
    securityStatics: DS.hasMany("security-static"),
    cities: DS.hasMany("city"),
    exchangeStatics: DS.hasMany("exchange-static"),
    issuerStatics: DS.hasMany("issuer-static"),
    brokerStatics: DS.hasMany("broker-static"),
    datavendorStatics: DS.hasMany("datavendor-static"),
    counterpartyStatics: DS.hasMany("counterparty-static"),
    countryFundamentals: DS.hasMany("country-fundamental"),
    countryRatings: DS.hasMany("country-rating"),
    custodianStaticFeeSchedules: DS.hasMany("custodian-static-fee-schedule"),
    futuresStatics: DS.hasMany("futures-static"),
    unGlobalCompacts: DS.hasMany("un-global-compact"),
    accountMastersListed: DS.hasMany("account-master", {
      inverse: "listedCountry"
    }),
    accountMastersFiscal: DS.hasMany("account-master", {
      inverse: "fiscalCountry"
    }),
    issuerStaticsRisk: DS.hasMany("issuer-static", {
      inverse: "riskCountry"
    }),
    issuerStaticsHq: DS.hasMany("issuer-static", {
      inverse: "hqCountry"
    }),
    niceString: Ember.computed("description", "isoThree", function () {
      return this.description + " - " + this.isoThree;
    })
  });

  _exports.default = _default;
});