define("atcm-client/templates/client/core/organisations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/il5I+pe",
    "block": "{\"symbols\":[],\"statements\":[[8,\"gql-table\",[],[[\"@dataQuery\",\"@dataVariables\",\"@title\",\"@type\",\"@formFields\",\"@fields\",\"@filters\",\"@actionColumns\",\"@actionColumnAction\"],[[34,0],[34,1],\"Organisations\",\"organisations\",[34,2],[34,3],[34,4],[34,5],\"processActionColumnAction\"]],null]],\"hasEval\":false,\"upvars\":[\"query\",\"dataVariables\",\"formFields\",\"tableFields\",\"tableFilters\",\"actionColumns\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/core/organisations/index.hbs"
    }
  });

  _exports.default = _default;
});