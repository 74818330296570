define("atcm-client/models/security-order-main", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    investedPerAccountMaster: Ember.computed("securityOrderAllocs.@each.orderBaseAmount", function () {
      const re = [];
      this.securityOrderAllocs.forEach(alloc => {
        re.push({
          accountMasterId: alloc.get("accountMaster.id"),
          orderBaseAmount: alloc.get("orderBaseAmount"),
          price: alloc.get("price")
        });
      });
      return re;
    }),
    orderBaseQuantities: Ember.computed.mapBy("securityOrderAllocs", "orderBaseQuantity"),
    orderBaseAmounts: Ember.computed.mapBy("securityOrderAllocs", "orderBaseAmount"),
    allocationPrices: Ember.computed.mapBy("investedPerAccountMaster", "price"),
    totalAmount: Ember.computed.sum("orderBaseAmounts"),
    totalQuantity: Ember.computed.sum("orderBaseQuantities"),
    canBeTraded: Ember.computed("statusTransaction.id", function () {
      return parseInt(this.get("statusTransaction.id")) === 6;
    }),
    sortedComplianceChecks: Ember.computed("complianceChecks.@each.timestamp", function () {
      return this.complianceChecks.sortBy("timestamp");
    }),
    lastComplianceCheck: Ember.computed("complianceChecks.@each.timestamp", function () {
      return this.get("sortedComplianceChecks.length") ? this.sortedComplianceChecks.objectAt(0) : null;
    }),
    isCompliant: Ember.computed("lastComplianceCheck", function () {
      return this.lastComplianceCheck ? this.get("lastComplianceCheck.isCompliant") : null;
    }),
    _securityStaticDidChange: Ember.observer("securityStatic", function () {
      const security = this.securityStatic;

      if (security) {
        this.set("securityTicker", security.get("tickerCode"));
        this.set("securityRic", security.get("ricCode"));
        this.set("securityDescriptionShort", security.get("descriptionShort"));
        this.set("securityIsinCode", security.get("isinCode"));
        this.set("securityCurrency", security.get("currency"));
        this.set("securityLastMarketPrice", security.get("lastMarketPrice"));
        this.set("securityExchangeStatic", security.get("exchangeStatic"));
      }
    }),
    niceString: Ember.computed("securityTicker", "securityCurrency", function () {
      return this.securityTicker + " - " + this.securityCurrency;
    }),
    isReadOnly: Ember.computed("statusTransaction", function () {
      const id = parseInt(this.get("statusTransaction.id"));
      return id !== 3 && id !== 6 && id !== 7;
    }),
    isClosed: Ember.computed("statusTransaction", function () {
      return parseInt(this.get("statusTransaction.id")) === 2;
    })
  });

  _exports.default = _default;
});