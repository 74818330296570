define("atcm-client/routes/client/models/country-fundamental", ["exports", "atcm-client/routes/secure", "atcm-client/routes/client/models/countryFundamentals", "atcm-client/routes/client/models/getInvestmentModel"], function (_exports, _secure, _countryFundamentals, _getInvestmentModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    apollo: Ember.inject.service("apollo"),
    model: function (params) {
      return this.apollo.query({
        query: _getInvestmentModel.default,
        variables: {
          id: params.investment_model_id
        }
      }).then(gqlResult => {
        return gqlResult["investmentModels"][0];
      });
    },
    afterModel: function (model) {
      const controller = this.controllerFor("client.models.country-fundamental");
      const selectedCountries = model.investmentModelCountries ? model.investmentModelCountries.map(country => country.countryId) : [];
      controller.set("selectedCountries", selectedCountries);
      return this.apollo.query({
        query: _countryFundamentals.default
      }).then(gqlResult => {
        const result = gqlResult["countryFundamentals"];
        const countries = new Map(),
              categories = [],
              chartData = [],
              chartDataMap = new Map(),
              countryIdMap = new Map();
        result.forEach(countryFundamental => {
          const ratioGroup = countryFundamental.ratio.ratioGroupSub.ratioGroupMain.description;
          const country = countryFundamental.country.description;
          countryIdMap.set(country, countryFundamental.country.id);
          const weightedValue = parseFloat(countryFundamental.value);

          if (!chartDataMap.has(ratioGroup)) {
            chartDataMap.set(ratioGroup, []);
          }

          let countryMap;

          if (countries.has(country)) {
            countryMap = countries.get(country);

            if (countryMap.has(ratioGroup)) {
              const newSum = countryMap.get(ratioGroup) + weightedValue;
              countryMap.set(ratioGroup, newSum);
              countryMap.set("total-" + ratioGroup, countryMap.get("total-" + ratioGroup) + weightedValue);
            } else {
              countryMap.set(ratioGroup, weightedValue);
              countryMap.set("total-" + ratioGroup, weightedValue);
            }
          } else {
            countryMap = new Map();
            countryMap.set(ratioGroup, weightedValue);
            countryMap.set("total-" + ratioGroup, weightedValue);
            countries.set(country, countryMap);
          }
        });
        const ratioGroupsArray = [...chartDataMap.keys()],
              tempCountries = Ember.A([]);
        const totalScoreAllCountries = new Map();
        countries.forEach((value, country) => {
          const obj = Ember.Object.create();
          obj.set("country", country);
          obj.set("countryId", countryIdMap.get(country));
          ratioGroupsArray.forEach(ratioGroup => {
            obj.set("total-" + ratioGroup, value.get("total-" + ratioGroup));

            if (totalScoreAllCountries.has(ratioGroup)) {
              totalScoreAllCountries.set(ratioGroup, totalScoreAllCountries.get(ratioGroup) + value.get("total-" + ratioGroup));
            } else {
              totalScoreAllCountries.set(ratioGroup, value.get("total-" + ratioGroup));
            }
          });
          tempCountries.pushObject(obj);
        });
        const gemiddeldeMap = new Map();
        totalScoreAllCountries.forEach((value, key) => {
          const gemiddelde = value / tempCountries.get("length");
          gemiddeldeMap.set(key, gemiddelde);
        });
        const mathMap = new Map();
        totalScoreAllCountries.forEach((value, key) => {
          let variantieTotal = 0;
          tempCountries.forEach(country => {
            const a = countries.get(country.get("country")).get(key) - gemiddeldeMap.get(key);
            variantieTotal += a * a;
          });
          const variantie = variantieTotal / (tempCountries.get("length") - 1);
          const standaardafwijking = Math.sqrt(variantie);
          mathMap.set(key, {
            variantie: variantie,
            standaardafwijking: standaardafwijking,
            gemiddelde: gemiddeldeMap.get(key)
          });
        }); // sumweights per ratioGroup * zscore land

        const minzscores = new Map(),
              maxzscores = new Map();
        tempCountries.forEach(country => {
          ratioGroupsArray.forEach(ratioGroup => {
            //TODO is the default value 0? or AVG?
            let value = 0;

            if (countries.get(country.get("country")).has(ratioGroup)) {
              value = countries.get(country.get("country")).get(ratioGroup);
            }

            const zscore = (value - mathMap.get(ratioGroup).gemiddelde) / mathMap.get(ratioGroup).standaardafwijking;
            country.set("zscore-" + ratioGroup, zscore);

            if (minzscores.has(ratioGroup)) {
              if (minzscores.get(ratioGroup) > zscore) {
                minzscores.set(ratioGroup, zscore);
              }
            } else {
              minzscores.set(ratioGroup, zscore);
            }

            if (maxzscores.has(ratioGroup)) {
              if (maxzscores.get(ratioGroup) < zscore) {
                maxzscores.set(ratioGroup, zscore);
              }
            } else {
              maxzscores.set(ratioGroup, zscore);
            }
          });
        }); // zscores omzetten in punten

        tempCountries.forEach(country => {
          let total = 0;
          ratioGroupsArray.forEach(ratioGroup => {
            const absmin = Math.abs(minzscores.get(ratioGroup));
            const newmax = maxzscores.get(ratioGroup) + absmin;
            let score = (country.get("zscore-" + ratioGroup) + absmin) / newmax * 100;

            if (ratioGroup === "Environmental") {
              score = score * 0.3;
            } else if (ratioGroup === "Social") {
              score = score * 0.3333;
            }

            country.set("score-" + ratioGroup, score / 20);
            total += score;
          });
          country.set("totalscore", total / 20);
        });
        tempCountries.sortBy("totalscore").toArray().reverse().forEach(country => {
          chartDataMap.forEach((value, key) => {
            chartDataMap.get(key).push(country.get("score-" + key));
          });
          categories.push({
            name: country.get("country"),
            id: country.get("countryId"),
            selected: selectedCountries.includes(country.get("countryId")) ? " checked" : ""
          });
        });
        chartDataMap.forEach((value, key
        /* , map*/
        ) => {
          chartData.push({
            name: key,
            data: value
          });
        });
        controller.set("esgRatios", {
          categories: categories,
          chartData: chartData
        });
      });
    }
  });

  _exports.default = _default;
});