define("atcm-client/models/investment-model", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    isIndex: Ember.computed("focus", function () {
      return this.focus === "Index";
    })
  });

  _exports.default = _default;
});