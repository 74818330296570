define("atcm-client/routes/client/staticdata/funds/securityratings", ["exports", "atcm-client/routes/secure"], function (_exports, _secure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    model: function (params) {
      return this.store.findRecord("securityStatic", params.securityStatic_id);
    },
    setupController: function (controller, model) {
      return this.store.query("securityRating", {
        filter: {
          securityStaticId: model.get("id")
        }
      }).then(securityratings => {
        controller.set("securityratings", securityratings);
        controller.set("model", model);
        return;
      });
    }
  });

  _exports.default = _default;
});