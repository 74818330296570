define("atcm-client/models/latest-exchange-rate", ["exports", "atcm-client/models/exchange-rate"], function (_exports, _exchangeRate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _exchangeRate.default.extend({});

  _exports.default = _default;
});