define("atcm-client/templates/tenants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GW9Mkbnu",
    "block": "{\"symbols\":[\"card\",\"tenant\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"layout-row layout-align-center-center world-foto\"],[12],[2,\"\\n\\t\"],[8,\"paper-card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[8,[32,1,[\"content\"]],[],[[\"@class\"],[\"layout-row layout-align-space-between\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\"],[8,\"paper-select\",[],[[\"@disabled\",\"@label\",\"@options\",\"@selected\",\"@onChange\"],[false,\"Organisatie\",[34,0],[34,1],[30,[36,2],[[32,0],\"selectTenant\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[32,2]],[2,\"\\n\\t\\t\\t\\t\\t\"]],\"parameters\":[2]}]]],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"selectedTentant\",\"action\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/tenants.hbs"
    }
  });

  _exports.default = _default;
});