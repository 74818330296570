define("atcm-client/controllers/client/compliance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AdminComplianceController = Ember.Controller.extend({});
  var _default = AdminComplianceController;
  _exports.default = _default;
});