define("atcm-client/routes/client/transactions/index", ["exports", "atcm-client/routes/secure"], function (_exports, _secure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    model: function () {
      const store = this.store;
      return store.query("blockTrade");
    }
  }); // TODO what to show here?
  // import SecureRoute from 'atcm-client/routes/secure';
  // export default SecureRoute.extend({
  //   model: function () {
  //     return this.get('store').query('security-position');
  //   }
  // });


  _exports.default = _default;
});