define("atcm-client/routes/client/impact/companyimpacts/performances", ["exports", "atcm-client/routes/secure", "atcm-client/gql/queries/companyImpactPerformances", "atcm-client/gql/queries/companyImpacts"], function (_exports, _secure, _companyImpactPerformances, _companyImpacts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    apollo: Ember.inject.service(),

    model() {
      const params = this.paramsFor("client.impact.companyimpacts.performances");
      const impactVariables = {
        id: params.issuer_impact_id
      };
      return this.apollo.query({
        query: _companyImpacts.default,
        variables: impactVariables
      }).then(gqlresult => {
        return gqlresult["impact_companyImpacts"] && gqlresult["impact_companyImpacts"].length ? gqlresult["impact_companyImpacts"][0] : null;
      }).catch(error => {
        throw error;
      });
    },

    setupController: function (controller, model) {
      controller.set("model", model);
      const params = this.paramsFor("client.impact.companyimpacts.performances");
      const variables = {
        orderBy: {
          description: "asc"
        },
        companyImpact: params.issuer_impact_id
      };
      controller.set("dataVariables", variables);
      controller.set("query", _companyImpactPerformances.default);
    }
  });

  _exports.default = _default;
});