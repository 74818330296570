define("atcm-client/gql/queries/managedFund", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "ManagedFund"
      },
      "variableDefinitions": [{
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "id"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "reportingDate"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "ibor_managedFunds"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "where"
            },
            "value": {
              "kind": "ObjectValue",
              "fields": [{
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "id"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "_eq"
                    },
                    "value": {
                      "kind": "Variable",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      }
                    }
                  }]
                }
              }]
            }
          }],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "id"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "description"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "descriptionShort"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "legalEntityIdentifier"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "assetManager"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "logo"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "description"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "descriptionShort"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "currency"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "isoCode"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "managedFundHoldings_aggregate"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "where"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "reportingDate"
                    },
                    "value": {
                      "kind": "ObjectValue",
                      "fields": [{
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "_eq"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "reportingDate"
                          }
                        }
                      }]
                    }
                  }]
                }
              }],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "aggregate"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "count"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "sum"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "marketValueAccountCurrency"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }]
                  }
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "sdgExposures"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "where"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "reportingDate"
                    },
                    "value": {
                      "kind": "ObjectValue",
                      "fields": [{
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "_eq"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "reportingDate"
                          }
                        }
                      }]
                    }
                  }]
                }
              }],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "sdgDescription"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "calculationDate"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "marketValueAccountCurrency"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "weight"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "reportingDate"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "countryExposures_aggregate"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "where"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "reportingDate"
                    },
                    "value": {
                      "kind": "ObjectValue",
                      "fields": [{
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "_eq"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "reportingDate"
                          }
                        }
                      }]
                    }
                  }]
                }
              }],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "aggregate"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "sum"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "marketValueAccountCurrency"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }]
                  }
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "countryExposures"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "where"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "reportingDate"
                    },
                    "value": {
                      "kind": "ObjectValue",
                      "fields": [{
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "_eq"
                        },
                        "value": {
                          "kind": "Variable",
                          "name": {
                            "kind": "Name",
                            "value": "reportingDate"
                          }
                        }
                      }]
                    }
                  }]
                }
              }],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "calculationDate"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "countryId"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "countryIsoThree"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "countryDescription"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "marketValueAccountCurrency"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "weight"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "reportingDate"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }]
          }
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "ibor_managedFundHoldings_aggregate"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "where"
            },
            "value": {
              "kind": "ObjectValue",
              "fields": [{
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "_and"
                },
                "value": {
                  "kind": "ListValue",
                  "values": [{
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "_not"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "company"
                          },
                          "value": {
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "latestCompanyImpactTotals"
                              },
                              "value": {
                                "kind": "ObjectValue",
                                "fields": []
                              }
                            }]
                          }
                        }]
                      }
                    }]
                  }, {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "managedFundId"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "_eq"
                          },
                          "value": {
                            "kind": "Variable",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }
                        }]
                      }
                    }]
                  }, {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "reportingDate"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "_eq"
                          },
                          "value": {
                            "kind": "Variable",
                            "name": {
                              "kind": "Name",
                              "value": "reportingDate"
                            }
                          }
                        }]
                      }
                    }]
                  }]
                }
              }]
            }
          }],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "aggregate"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "sum"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "marketValueAccountCurrency"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 1245
    }
  };
  var _default = doc;
  _exports.default = _default;
});