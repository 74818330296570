define("atcm-client/controllers/client/staticdata", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AdminStaticdataController = Ember.Controller.extend({
    session: Ember.inject.service(),
    expandedItem: null,
    actions: {
      toggleExpandedItem(value, ev) {
        let newValue;

        if (this.expandedItem === value) {
          newValue = null;
        } else {
          newValue = value;
        }

        this.set("expandedItem", newValue);
        ev.stopPropagation();
      }

    },
    clientStaticsExpanded: Ember.computed.equal("expandedItem", "clientStatics"),
    instrumentStaticsExpanded: Ember.computed.equal("expandedItem", "instrumentStatics"),
    thirdPartyStaticsExpanded: Ember.computed.equal("expandedItem", "thirdPartyStatics"),
    internalStaticsExpanded: Ember.computed.equal("expandedItem", "internalStatics"),
    basketFunctionalitiesExpanded: Ember.computed.equal("expandedItem", "basketFunctionalities"),
    valuationsExpanded: Ember.computed.equal("expandedItem", "valuations")
  });
  var _default = AdminStaticdataController;
  _exports.default = _default;
});