define("atcm-client/authenticators/auth0-base", ["exports", "ember-simple-auth-auth0/authenticators/auth0-lock"], function (_exports, _auth0Lock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _auth0Lock.default;
    }
  });
});