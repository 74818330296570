define("atcm-client/controllers/client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.needs = ["index"];
    },

    chats: null,
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    leftSideBarOpen: true,
    leftSideBarLockedOpen: true,
    unreadMessageCount: Ember.computed("chats.@each.unreadMessageCount", function () {
      let count = 0;

      if (this.chats) {
        this.chats.forEach(chat => {
          count += chat.get("unreadMessageCount");
        });
      }

      return count;
    }),
    actions: {
      show(menuItem) {
        this.transitionToRoute(menuItem);
      },

      logout() {
        this.session.invalidate();
      },

      switchTenant(tenant) {
        const currentData = this.get("session.data");
        Ember.set(currentData, "tenant", tenant);
        this.get("session.store").persist(currentData);
        window.location.reload(true);
      },

      showProfile() {
        this.transitionToRoute("client.profile");
      }

    }
  });

  _exports.default = _default;
});