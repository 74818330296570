define("atcm-client/routes/client/staticdata/securitystatics/index", ["exports", "atcm-client/routes/secure", "ember-apollo-client", "atcm-client/gql/queries/securities"], function (_exports, _secure, _emberApolloClient, _securities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const variables = {
    limit: 10,
    offset: 0
  };

  var _default = _secure.default.extend(_emberApolloClient.RouteQueryManager, {
    model: function () {
      return;
    },
    setupController: function (controller) {
      controller.set("dataVariables", variables);
      controller.set("query", _securities.default);
    }
  });

  _exports.default = _default;
});