define("atcm-client/templates/components/menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qNRnOU+B",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"paper-item\",[],[[\"@onClick\",\"@class\"],[[34,1],[30,[36,5],[\"menu-item\",[30,[36,3],[[35,4],\" active\"],null],[30,[36,3],[[35,2],\" expanded\"],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[6,[37,3],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"expand-more\"],[[\"class\"],[\"expand-icon\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"paper-icon\",\"onClick\",\"expanded\",\"if\",\"active\",\"concat\",\"canExpand\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/components/menu-item.hbs"
    }
  });

  _exports.default = _default;
});