define("atcm-client/templates/client/core/impactobjectivemains/impactobjectivesubs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XJxnP5d+",
    "block": "{\"symbols\":[],\"statements\":[[8,\"simple-table\",[],[[\"@content\",\"@title\",\"@type\",\"@fields\",\"@presetFilters\",\"@filters\",\"@formFields\",\"@back\"],[[34,0],[34,1],\"impactObjectiveSub\",[34,2],[34,3],[34,4],[34,5],\"client.core.impactobjectivemains.index\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"impactobjectivesubs\",\"title\",\"tableFields\",\"presetFilters\",\"tableFilters\",\"formFields\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/core/impactobjectivemains/impactobjectivesubs.hbs"
    }
  });

  _exports.default = _default;
});