define("atcm-client/models/security-transaction-fill", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceString: Ember.computed("securityTransaction.description", "fillNumber", function () {
      return this.get("securityTransaction.description") + " - " + this.fillNumber;
    })
  });

  _exports.default = _default;
});