define("atcm-client/models/exchange-rate", ["exports", "atcm-client/models/base-model", "moment"], function (_exports, _baseModel, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    calculatedSpotRateInverse: Ember.computed("spotRate", function () {
      if (!Ember.isNone(this.spotRate)) {
        return (1 / this.spotRate).toFixed(12);
      }

      return;
    }),
    // this function is only used in the background for data storage. The calculatedSpotRateInverse is used in the screen
    spotRateDidChange: Ember.observer("calculatedSpotRateInverse", function () {
      if (!Ember.isNone(this.calculatedSpotRateInverse)) {
        this.set("spotRateInverse", this.calculatedSpotRateInverse);
      }
    }),
    niceRateTimestamp: Ember.computed("rateTimestamp", function () {
      if (this.rateTimestamp) {
        return (0, _moment.default)(this.rateTimestamp).format("DD-MM-YYYY hh:mm:ss");
      }
    })
  });

  _exports.default = _default;
});