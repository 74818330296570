define("atcm-client/components/ibox-controls", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function () {
      // Collapse ibox function
      this.$(".collapse-link").click(function () {
        const ibox = (0, _jquery.default)(this).closest("div.ibox");
        const button = (0, _jquery.default)(this).find("i");
        const content = ibox.find("div.ibox-content");
        content.slideToggle(200);
        button.toggleClass("fa-chevron-up").toggleClass("fa-chevron-down");
        ibox.toggleClass("").toggleClass("border-bottom");
        setTimeout(() => {
          ibox.resize();
        }, 50);
      }); // Close ibox function

      (0, _jquery.default)(".close-link").click(function () {
        (0, _jquery.default)(this).closest("div.ibox").content.remove();
      });
    }
  });

  _exports.default = _default;
});