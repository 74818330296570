define("atcm-client/routes/index", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),

    beforeModel() {
      const {
        idToken
      } = this.session.data.authenticated;

      if (Ember.isPresent(idToken)) {
        this.transitionTo("client.marketanalyses");
      }
    }

  });

  _exports.default = _default;
});