define("atcm-client/components/competitor-analyses/fund-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    searchService: Ember.inject.service("search"),
    classNames: ["flex"],
    reportingDates: null,
    hasReportingDates: Ember.computed("reportingDates.length", async function () {
      var _this$reportingDates;

      await this.reportingDates;
      return ((_this$reportingDates = this.reportingDates) === null || _this$reportingDates === void 0 ? void 0 : _this$reportingDates.length) > 1;
    }),
    actions: {
      searchAssetManager(searchText) {
        return this.searchService.search("ibor_assetManagers", searchText);
      },

      searchFunds(searchText, selectedAssetManagerId) {
        return this.searchService.search("ibor_managedFunds", searchText, {
          assetManager: selectedAssetManagerId
        });
      },

      showNonSDGPositions() {
        this.showNonSDGDialog(this.fund.id);
      },

      clearFund() {
        this.clearFund();
      },

      async setFund(fund) {
        this.selectedFund = fund; // console.log('selectedFund', this.selectedFund);

        await this.setFund(fund);
        console.log("selectedFund", this.selectedFund);
      }

    }
  });

  _exports.default = _default;
});