define("atcm-client/controllers/client/models/entry", ["exports", "atcm-client/gql/mutations/investmentModel", "atcm-client/gql/search/indexStatics", "atcm-client/enums/focussus"], function (_exports, _investmentModel, _indexStatics, _focussus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    searchService: Ember.inject.service("search"),
    apollo: Ember.inject.service(),
    focussus: _focussus.default,
    actions: {
      save() {
        const self = this;

        if (self.model.indexStatic && self.model.indexStatic.id) {
          self.model.indexStaticId = self.model.indexStatic.id;
        }

        delete self.model.indexStatic; // TODO add indexConstituents as modelConsitutents and then save

        return this.apollo.mutate({
          mutation: _investmentModel.default,
          variables: {
            objects: [self.model]
          }
        }, "insert_investmentModels").then(result => {
          const newModel = result.returning[0];

          if (newModel.focus === "Index" && !Ember.isNone(newModel.indexStatic.id) || newModel.focus === "Manual") {
            self.transitionToRoute("client.models.edit", result.id);
          } else if (self.get("model.focus") === "All Fundamentals") {
            self.transitionToRoute("client.models.country-fundamental", newModel);
          } else if (self.get("model.focus") === "Country Fundamentals") {
            self.transitionToRoute("client.models.country-fundamental", newModel);
          } else if (self.get("model.focus") === "Issuer Fundamentals") {
            self.transitionToRoute("client.models.issuer-fundamental", newModel);
          } else if (self.get("model.focus") === "Security Fundamentals") {
            self.transitionToRoute("client.models.security-fundamental", newModel);
          } else {// noop (we stay here?)
          }
        }).catch(error => console.error(error));
      },

      cancel: function () {
        this.set("model", {
          active: true
        });
        this.transitionToRoute("client.models.index");
      },
      search: function (textQuery) {
        return this.apollo.query({
          query: _indexStatics.default,
          variables: {
            textQuery: `%${textQuery}%`
          }
        }).then(gqlResult => {
          return gqlResult["indexStatics"];
        });
      },

      selectIndexStatic(index) {
        this.model.indexStaticId = index.id;
      }

    }
  });

  _exports.default = _default;
});