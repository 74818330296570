define("atcm-client/controllers/client/impact/index", ["exports", "atcm-client/controllers/client/impact/alternativeIssuersQuery"], function (_exports, _alternativeIssuersQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),
    activePieDataSet: null,
    activeSecurityDataSet: null,
    selectedSDG: null,
    selectedIssuer: null,
    selectedIssuerIndex: 0,

    init() {
      this._super(...arguments);

      this.sdgImages = ["assets/images/sdg/small/1.png", "assets/images/sdg/small/2.png", "assets/images/sdg/small/3.png", "assets/images/sdg/small/4.png", "assets/images/sdg/small/5.png", "assets/images/sdg/small/6.png", "assets/images/sdg/small/7.png", "assets/images/sdg/small/8.png", "assets/images/sdg/small/9.png", "assets/images/sdg/small/10.png", "assets/images/sdg/small/11.png", "assets/images/sdg/small/12.png", "assets/images/sdg/small/13.png", "assets/images/sdg/small/14.png", "assets/images/sdg/small/15.png", "assets/images/sdg/small/16.png", "assets/images/sdg/small/17.png", "assets/images/sdg/small/18.png"];
    },

    pieChartOptions: Ember.computed("selectedIssuer", function () {
      const options = {
        legend: "none",
        sliceVisibilityThreshold: 0,
        pieHole: 0.2,
        slices: {}
      };
      options.slices[this.selectedIssuerIndex] = {
        offset: 0.3
      };
      return options;
    }),
    percent: Ember.computed("model.{totalPosition,totalSDG}", function () {
      if (this.model && this.model.totalSDG && this.model.totalPosition) return parseInt(this.model.totalSDG / this.model.totalPosition * 100);
      return null;
    }),
    totalOfActiveIssuers: Ember.computed("activeSecurityDataSet.length", function () {
      return this.activeSecurityDataSet.slice(1).reduce((sum, row) => sum + row[1], 0);
    }),
    alternativeIssuers: Ember.computed("selectedIssuer.{id,gicsIndustrySub.id}", function () {
      const variables = {
        gicsIndustryId: this.selectedIssuer.gicsIndustrySub.id,
        issuerIdToExclude: this.selectedIssuer.id
      };
      return this.apollo.query({
        query: _alternativeIssuersQuery.default,
        variables
      }).then(gqlResult => {
        for (const issuer of gqlResult.issuerStatics) {
          const impactObjectiveMains = new Map();

          for (const issuerCompact of issuer.issuerCompacts) {
            // console.warn("issuerCompact", issuerCompact);
            issuerCompact.unGlobalCompact.latestCompanyImpacts.forEach(companyImpact => {
              // console.warn("companyImpact", companyImpact);
              if (issuer.id !== this.selectedIssuer.id) companyImpact.impactObjectiveMain.img = this.sdgImages[companyImpact.impactObjectiveMain.id - 1];
              impactObjectiveMains.set(companyImpact.impactObjectiveMain.id, companyImpact.impactObjectiveMain);
            });
          }

          issuer.impactObjectives = Array.from(impactObjectiveMains.values());
        }

        return gqlResult;
      }).catch(e => {
        console.error(e);
      });
    }),
    actions: {
      selectSDG(sdgId) {
        const sdgDescription = this.model.sdgPieChart[sdgId - 1].name;
        this.set("activeSecurityDataSet", this.model.securityChartData[sdgId]);
        this.set("selectedSDG", {
          description: sdgDescription,
          id: sdgId,
          img: this.sdgImages[sdgId - 1]
        });

        if (this.model.securityChartData[sdgId] && this.model.securityChartData[sdgId].length > 0) {
          this.set("selectedIssuer", this.model.issuers.get(this.model.securityChartData[sdgId][1][2])); // console.warn("Setting selectedIssuer to: ", this.model.issuers.get(this.model.securityChartData[sdgId][1][2]));
        }
      },

      afterIssuerPieChartRender(chart) {
        const {
          visualization
        } = window.google;
        visualization.events.addListener(chart, "select", () => {
          if (chart.getSelection() && chart.getSelection()[0] && chart.getSelection()[0].hasOwnProperty("row")) {
            const selected = this.activeSecurityDataSet[chart.getSelection()[0].row + 1]; // console.warn(selected, this.model.issuers.get(selected[2]));

            this.set("selectedIssuer", this.model.issuers.get(selected[2])); // console.log(this.model.issuers);

            this.set("selectedIssuerIndex", chart.getSelection()[0].row);
          } // click on label is not registered

        });
      }

    }
  });

  _exports.default = _default;
});