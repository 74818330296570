define("atcm-client/routes/client/transactions/orders/entry", ["exports", "atcm-client/routes/secure"], function (_exports, _secure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    model: function () {
      // the controller can get an incomplete order from the orders.edit route
      const store = this.store;
      return store.findRecord("statusTransaction", 6).then(status => {
        return store.createRecord("investmentModelOrder", {
          active: true,
          statusTransaction: status
        });
      });
    }
  });

  _exports.default = _default;
});