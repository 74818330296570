define("atcm-client/controllers/client/staticdata/accountmasters/positions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true
      }, {
        displayName: "Account Master",
        displayField: "accountMaster",
        disabled: true
      }, {
        displayName: "Security Static",
        selectionField: "securityStatic",
        displayField: "description",
        model: "securityStatics",
        queryFields: ["description", "ricCode", "tickerCode"],
        disabled: true
      }, {
        displayName: "Post Transaction Quantity",
        displayField: "postTransactionQuantity",
        disabled: true
      }, {
        displayName: "Post Transaction Average Price Account Currencyy",
        displayField: "postTransactionAveragePriceAccountCurrency",
        disabled: true
      }, {
        displayName: "Post Transaction Average Price Security Currency",
        displayField: "postTransactionAveragePriceSecurityCurrency",
        disabled: true
      }, {
        displayName: "Security Position Account Currency",
        displayField: "securityPositionAccountCurrency",
        disabled: true
      }, {
        displayName: "Security Position Security Currency",
        displayField: "securityPositionSecurityCurrency",
        disabled: true
      }, {
        displayName: "Post Transaction Exchange Rate",
        displayField: "postTransactionExchangeRate",
        disabled: true
      }, {
        displayName: "Last transaction included in position",
        displayField: "lastSecurityTransaction",
        disabled: true
      }, {
        displayName: "Custodian",
        selectionField: "custodianStatic",
        displayField: "description",
        model: "custodianStatics",
        queryFields: ["description"],
        disabled: true
      }], this.tableFields = [{
        displayName: "Security",
        displayField: "securityStatic.description",
        alignLeft: true
      }, {
        displayName: "Security RIC",
        displayField: "securityStatic.ricCode"
      }, {
        displayName: "Quantity",
        displayField: "postTransactionQuantity"
      }, {
        displayName: "Average Price",
        displayField: "postTransactionAveragePriceAccountCurrency"
      }, {
        displayName: "Bookvalue",
        displayField: "securityPositionAccountCurrency"
      }, {
        displayName: "Custodian",
        displayField: "custodianStatic.descriptionShort"
      }];
    },

    title: Ember.computed("model", function () {
      return "Security Positions of " + this.get("model.descriptionShort");
    })
  });

  _exports.default = _default;
});