define("atcm-client/models/fund", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    fundamentalRatioSum: Ember.computed("securityFundamentals.@each.value", function () {
      let sum = 0;
      this.securityFundamentals.forEach(fundamental => {
        sum += fundamental.get("value");
      }); //2 decimals, also 0.00

      return parseFloat(Math.round(sum * 100) / 100).toFixed(3);
    }),
    niceString: Ember.computed("description", "ricCode", function () {
      return this.description + " - " + this.ricCode + " - " + this.tickerCode;
    })
  });

  _exports.default = _default;
});