define("atcm-client/templates/components/submenu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7FYMN+Qj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"paper-item\",[],[[\"@onClick\",\"@class\"],[[34,0],[30,[36,3],[\"submenu-item\",[30,[36,2],[[35,1],\" active\"],null]],null]]],[[\"default\"],[{\"statements\":[[18,1,null]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"onClick\",\"active\",\"if\",\"concat\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/components/submenu-item.hbs"
    }
  });

  _exports.default = _default;
});