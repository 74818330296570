define("atcm-client/models/security-static", ["exports", "atcm-client/models/base-model", "moment"], function (_exports, _baseModel, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    fundamentalRatioSum: Ember.computed("securityFundamentals.@each.value", function () {
      let sum = 0;
      this.securityFundamentals.forEach(fundamental => {
        sum += fundamental.get("value");
      }); //2 decimals, also 0.00

      return parseFloat(Math.round(sum * 100) / 100).toFixed(3);
    }),
    niceString: Ember.computed("description", "ricCode", function () {
      return this.description + " - " + this.ricCode + " - " + this.tickerCode;
    }),
    niceDateOfMaturityDate: Ember.computed("maturityDate", function () {
      if (this.maturityDate) {
        return (0, _moment.default)(this.maturityDate).format("DD-MM-YYYY");
      }
    }),
    niceDateOfIssueDate: Ember.computed("issueDate", function () {
      if (this.issueDate) {
        return (0, _moment.default)(this.issueDate).format("DD-MM-YYYY");
      }
    })
  });

  _exports.default = _default;
});