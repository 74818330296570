define("atcm-client/controllers/client/compliance/compliancebaskets/complianceaccountbaskets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.formFields = [{
        displayName: "Account Master",
        displayField: "accountMaster.description"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true
      }, {
        displayName: "Account Master",
        selectionField: "accountMaster",
        displayField: "description",
        model: "accountMasters",
        queryFields: ["description", "descriptionShort"],
        required: true
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Accounts related with compliance basket " + this.get("model.descriptionShort");
    })
  });

  _exports.default = _default;
});