define("atcm-client/controllers/client/susty/securityfundamentals/index", ["exports", "atcm-client/gql/queries/securityFundamentals"], function (_exports, _securityFundamentals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Asset Class",
        displayField: "securityStatic.instrumentType.assetClass.descriptionShort",
        alignLeft: true
      }, {
        displayName: "Security Static",
        displayField: "securityStatic.description"
      }, {
        displayName: "Ratio",
        displayField: "ratio.description"
      }, {
        displayName: "Value",
        displayField: "value"
      }, {
        displayName: "Datavendor",
        displayField: "ratio.ratioGroupSub.ratioGroupMain.descriptionShort"
      }, {
        displayName: "Source",
        displayField: "ratio.ratioGroupSub.ratioGroupMain.datavendorStatic.descriptionShort"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Security",
        displayField: "securityStatics",
        queryField: "securityStatic",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Ratio",
        displayField: "ratios",
        queryField: "ratio",
        displayType: "typeahead",
        isTypeahead: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Security static",
        selectionField: "securityStatic",
        displayField: "description",
        model: "securityStatics",
        queryFields: ["description", "ricCode", "tickerCode"],
        required: true
      }, {
        displayName: "Ratio",
        selectionField: "ratio",
        displayField: "description",
        model: "ratios",
        queryFields: ["description", "descriptionShort"],
        required: true
      }, {
        displayName: "Value",
        displayField: "value",
        required: true
      }];
    },

    editRoute: null
  });

  _exports.default = _default;
});