define("atcm-client/controllers/client/impact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AdminImpactController = Ember.Controller.extend({
    expandedItem: null,
    actions: {
      toggleExpandedItem(value, ev) {
        let newValue = value;

        if (this.expandedItem === value) {
          newValue = null;
        }

        this.set("expandedItem", newValue);
        ev.stopPropagation();
      }

    },
    impactsExpanded: Ember.computed.equal("expandedItem", "impacts"),
    staticsExpanded: Ember.computed.equal("expandedItem", "statics"),
    esgsExpanded: Ember.computed.equal("expandedItem", "esgs")
  });
  var _default = AdminImpactController;
  _exports.default = _default;
});