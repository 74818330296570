define("atcm-client/templates/client/core/ratiogroupsubs/ratios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VSej65J7",
    "block": "{\"symbols\":[],\"statements\":[[8,\"simple-table\",[],[[\"@content\",\"@title\",\"@type\",\"@fields\",\"@filters\",\"@presetFilters\",\"@filters\",\"@formFields\",\"@back\"],[[34,0],[34,1],\"ratio\",[34,2],[34,3],[34,4],[34,3],[34,5],\"client.core.ratiogroupsubs.index\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"ratios\",\"title\",\"tableFields\",\"tableFilters\",\"presetFilters\",\"formFields\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/core/ratiogroupsubs/ratios.hbs"
    }
  });

  _exports.default = _default;
});