define("atcm-client/templates/client/impact/unglobalcompacts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sFL6ex1k",
    "block": "{\"symbols\":[],\"statements\":[[8,\"gql-table\",[],[[\"@dataQuery\",\"@dataVariables\",\"@title\",\"@type\",\"@formFields\",\"@fields\",\"@filters\",\"@actionColumns\",\"@actionColumnAction\",\"@mapToType\"],[[34,0],[34,1],\"Companies\",\"impact_unGlobalCompacts\",[34,2],[34,3],[34,4],[34,5],\"processActionColumnAction\",[34,6]]],null]],\"hasEval\":false,\"upvars\":[\"query\",\"dataVariables\",\"formFields\",\"tableFields\",\"tableFilters\",\"actionColumns\",\"mapToType\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/impact/unglobalcompacts/index.hbs"
    }
  });

  _exports.default = _default;
});