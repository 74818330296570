define("atcm-client/controllers/client/transactions/block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.selectedBrokers = [];
      this.selectedCounterparties = [];
      this.selectedTradingVenues = [];
    },

    selectedBroker: null,
    selectedCounterparty: null,
    selectedTradingVenue: null,
    showBrokers: false,
    showCounterParties: false,
    showTradingVenues: false,
    _searchedBrokerDidChange: Ember.observer("selectedBroker", function () {
      const self = this;

      if (this.selectedBroker) {
        this.selectedBrokers.pushObject(this.selectedBroker);
        Ember.run.next(self, function () {
          this.set("selectedBroker", null);
        });
      }
    }),
    _searchedTradingVenueDidChange: Ember.observer("selectedTradingVenue", function () {
      const self = this;

      if (this.selectedTradingVenue) {
        this.selectedTradingVenues.pushObject(this.selectedTradingVenue);
        Ember.run.next(self, function () {
          this.set("selectedTradingVenue", null);
        });
      }
    }),
    _searchedCounterPartyDidChange: Ember.observer("selectedCounterparty", function () {
      const self = this;

      if (this.selectedCounterparty) {
        this.selectedCounterparties.pushObject(this.selectedCounterparty);
        Ember.run.next(self, function () {
          this.set("selectedCounterparty", null);
        });
      }
    })
  });

  _exports.default = _default;
});