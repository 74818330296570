define("atcm-client/controllers/client/staticdata/interestrates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Interest Static",
        displayField: "interestStatic.descriptionShort",
        alignLeft: true
      }, {
        displayName: "Bid (Asset)",
        displayField: "rateBid"
      }, {
        displayName: "Mid (Mid)",
        displayField: "rateMid"
      }, {
        displayName: "Ask (Liability)",
        displayField: "rateAsk"
      }, {
        displayName: "Value Date",
        displayField: "niceRateTimestamp"
      }, {
        displayName: "Datavendor",
        displayField: "datavendorStatic.descriptionShort"
      }, {
        displayName: "Pricing Option",
        displayField: "pricingOption.description"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Interest static",
        selectionField: "interestStatic",
        displayField: "description",
        model: "interestStatics",
        queryFields: ["description", "ricCode", "tickerCode"],
        required: true
      }, {
        displayName: "Datavendor",
        selectionField: "datavendorStatic",
        displayField: "description",
        model: "datavendorStatics",
        queryFields: ["description", "descriptionShort"],
        required: true
      }, {
        displayName: "Pricing option",
        selectionField: "pricingOption",
        displayField: "description",
        model: "pricingOptions",
        queryFields: ["description", "descriptionShort"],
        required: true
      }, {
        displayName: "Bid rate",
        displayField: "rateBid",
        required: true
      }, {
        displayName: "Ask rate",
        displayField: "rateAsk",
        required: true
      }, {
        displayName: "Mid rate",
        displayField: "rateMid"
      }, {
        displayName: "Timestamp Rate",
        displayField: "rateTimestamp",
        date: true
      }];
    },

    editRoute: null
  });

  _exports.default = _default;
});