define("atcm-client/templates/components/chat-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MkyT+7Pa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"chat-user existing\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,0,\"chat-avatar\"],[15,\"src\",[31,[[34,0,[\"otherUser\",\"profilePicture\"]]]]],[15,\"alt\",[31,[[34,0,[\"otherUser\",\"fullName\"]]]]],[14,\"width\",\"36\"],[14,\"height\",\"36\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"chat-user-name\"],[12],[2,\"\\n      \"],[6,[37,1],[[35,0,[\"otherUser\",\"online\"]]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"pull-right label label-primary\"],[12],[2,\"Online\"],[13]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\",\"@query\"],[\"client.chat\",[30,[36,2],null,[[\"chatId\"],[[35,0,[\"id\"]]]]]]],[[\"default\"],[{\"statements\":[[1,[35,0,[\"otherUser\",\"fullName\"]]]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"pull-right\"],[12],[2,\" \"],[13],[2,\"\\n\"],[6,[37,1],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"label label-warning pull-right\"],[12],[1,[35,0,[\"unreadMessages\",\"length\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"chat\",\"if\",\"hash\",\"hasUnreadMessages\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/components/chat-chat.hbs"
    }
  });

  _exports.default = _default;
});