define("atcm-client/controllers/client/analyses/index", ["exports", "atcm-client/controllers/client/analyses/getPositions"], function (_exports, _getPositions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    searchService: Ember.inject.service("search"),
    positionsQuery: _getPositions.default,
    positionsVariables: null,
    pieChartCardTitle: "Asset Class",
    gicsCardTitle: "Economic Sector",
    gicsSectorId: null,
    gicsIndustryGroupId: null,
    gicsIndustryId: null,
    gicsIndustrySubId: null,
    version: 0,

    init() {
      this._super(...arguments);

      this.positionsVariables = {
        orderBy: {
          ifrsBookValueAccountCurrency: "desc"
        }
      };
      this.weightMenu = [{
        id: "currency",
        name: "Currency",
        field: "currencyId"
      }, {
        id: "country",
        name: "Country",
        field: "countryId"
      }];
      this.activeFilters = [];
      this.tableFields = [{
        displayName: "Asset Cl.",
        displayField: "securityListing.securityStatic.assetClass.descriptionShort",
        alignLeft: true
      }, {
        displayName: "Inst. Type",
        displayField: "securityListing.securityStatic.instrumentType.descriptionShort"
      }, {
        displayName: "Account",
        displayField: "accountMaster.descriptionShort"
      }, {
        displayName: "Account Currency",
        displayField: "accountMaster.currency.isoCode"
      }, {
        displayName: "ISIN",
        displayField: "securityListing.securityStatic.isinCode"
      }, {
        displayName: "Security Currency",
        displayField: "securityListing.currency.isoCode"
      }, {
        displayName: "Value (acc curr)",
        displayField: "ifrsBookValueAccountCurrency"
      }, {
        displayName: "Value (sec curr)",
        displayField: "ifrsBookValueSecurityCurrency"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Account",
        displayField: "accountMasters",
        queryField: "accountMaster",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Security",
        displayField: "securityStatics",
        queryField: "securityStatic",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Custodian",
        displayField: "custodianStatics",
        queryField: "custodianStatic",
        displayType: "typeahead",
        isTypeahead: true
      }]; // PIE CHART

      this.pieChartOptions = {
        colors: ["#004c5f", "#00667f", "#0099bf", "#00ccff", "#80e6ff"],
        legend: {
          textStyle: {
            color: "black",
            fontSize: 12
          }
        },
        sliceVisibilityThreshold: 0
      }; // BAR CHART

      this.barChartOptions = {
        // title: 'Value grouped by Sector',
        bars: "horizontal",
        annotations: {// alwaysOutside: true
        },
        legend: {
          position: "none"
        },
        hAxis: {
          minValue: 0
        },
        vAxis: {
          title: "Economic sector"
        },
        animation: {
          startup: true,
          easing: "inAndOut"
        }
      }; // this.scatterChartOptions = {
      // title: 'Duration vs Yield',
      // hAxis: { title: "Duration (years)", minValue: -1, maxValue: 10 },
      // vAxis: { title: "Yield (%)", minValue: -1, maxValue: 8 },
      // legend: "none",
      // focusTarget: "category",
      // tooltip: {isHtml: true}
      // };
      // this.scatterChartData = [
      // 	[
      // 		"Duration",
      // 		"Yield",
      // 		{
      // 			type: "string",
      // 			role: "tooltip",
      // 		},
      // 	],
      // 	[8, 10, "test"],
      // 	[4, 5.5, "test1"],
      // 	[3, 8, "test2"],
      // 	[4, 5, "test3"],
      // 	[3, 3.5, "test4"],
      // 	[6.5, 7, "test5"],
      // ];
    },

    // _assetClassSecurityStaticGraphChanged: observer("assetClassSecurityStaticGraph", function() {
    // 	if (this.get("assetClassSecurityStaticGraph.id")) {
    // 		this.set("securityStaticQueryParams", {
    // 			"instrumentType.assetClass": this.get("assetClassSecurityStaticGraph.id"),
    // 		});
    // 	} else {
    // 		this.set("securityStaticQueryParams", {});
    // 	}
    // }),
    valueByTableGroupType: Ember.computed("weightMenu", function () {
      return this.weightMenu.objectAt(0);
    }),
    currentTableTitle: Ember.computed("valueByTableGroupType.id", function () {
      return this.valueByTableGroupType.name;
    }),

    updatePositionsTable() {
      // reset
      const positionsVariables = {
        orderBy: {
          ifrsBookValueAccountCurrency: "desc"
        }
      }; // rebuild

      this.activeFilters.forEach(filter => {
        positionsVariables[filter.field] = filter.id;
      }); // console.log("variables positions table: ", positionsVariables);

      this.set("positionsVariables", positionsVariables); // trigger query in gql-table component
      // this.set("version", this.get("version") + 1);
    },

    hasFilter(filterProperty) {
      for (const filter of this.activeFilters) {
        if (filter.field === filterProperty) {
          return true;
        }
      }

      return false;
    },

    removeFilterByField(field) {
      for (const filter of this.activeFilters) {
        if (filter.field === field) {
          this.activeFilters.removeObject(filter);
          break;
        }
      }
    },

    economicSector: null,
    industryGroup: null,
    assetClass: null,
    instrumentType: null,
    actions: {
      addFilter(rowData) {
        const type = this.valueByTableGroupType.name;
        const field = this.valueByTableGroupType.id;
        const name = rowData.description;
        const newFilter = {
          name,
          type,
          field,
          id: rowData.id
        };
        this.removeFilterByField(field);
        this.activeFilters.pushObject(newFilter);
        this.send("update");
        this.updatePositionsTable();
      },

      removeFilter(item) {
        // console.log("removing", item);
        this.activeFilters.removeObject(item);
        this.set(item.field, null);
        this.send("update");
        this.updatePositionsTable();
      },

      search(modelName, query) {
        return this.searchService.search(modelName, query);
      },

      setValueDisplayMode(mode) {
        this.set("valueByTableGroupType", mode); // TODO update data to selected mode

        this.send("updateValueByData", mode.id);
      },

      // clearAccountMasterWeights: function() {
      // 	this.set("accountMasterWeights", null);
      // },
      // clearAccountMasterValue: function() {
      // 	this.set("currentAssetBreakdown", null);
      // 	this.set("accountMasterValue", null);
      // },
      // updateChartData: function() {
      // 	this.setChartData();
      // },
      // hideSecurityStatic: function(secStatic) {
      // 	this.securityStaticsToGraph.removeObject(secStatic);
      // },
      afterPieChartRender(chart) {
        // TODO nothing but filter other charts based on instrumentType, only change data of graph when assetClass is selected
        const {
          visualization
        } = window.google;
        visualization.events.addListener(chart, "select", () => {
          const filterType = "Asset Class";
          const selected = this.get("model.pieData.raw")[chart.getSelection()[0].row];

          if (!this.getActiveFilterKeys().includes(filterType)) {
            const newFilter = {
              name: selected.description,
              type: filterType,
              field: "assetClass",
              id: selected.id
            };
            this.set("assetClass", selected.id);
            this.activeFilters.pushObject(newFilter);
            this.send("update");
            this.updatePositionsTable();
          } else {
            //select and filter instead of drilldown
            const filterField = "instrumentType";
            const newFilter = {
              name: selected.description,
              type: "Instrument Type",
              field: filterField,
              id: selected.id
            };
            this.set("instrumentType", selected.id);

            if (!this.hasFilter(filterField)) {
              this.activeFilters.pushObject(newFilter);
            }

            this.send("update");
            this.updatePositionsTable();
          }
        });
      },

      afterGicsChartRender(chart) {
        const {
          visualization
        } = window.google;
        let filterType, filterField;

        if (this.economicSector === null) {
          filterType = "Economic Sector";
          filterField = "economicSector";
        } else {
          filterType = "Industry Group";
          filterField = "industryGroup";
        }

        visualization.events.addListener(chart, "select", () => {
          const selectedRow = chart.getSelection()[0];

          if (selectedRow) {
            const selected = this.get("model.barData.raw").objectAt(selectedRow.row);
            this.set(filterField, selected.id); // console.log("setting", filterField, "to", selected);

            const newFilter = {
              name: selected.description,
              type: filterType,
              field: filterField,
              id: selected.id
            };
            this.removeFilterByField(filterField);
            this.activeFilters.pushObject(newFilter); // console.log(selected);

            this.set("currentGICSLevel", this.currentGICSLevel + 1);
            this.send("update");
            this.updatePositionsTable();
          } else {//TODO deselect
            // this.getActiveFilterKeys().removeObject(filterType);
          }
        });
      },

      afterBarChartCO2Render(chart) {
        const {
          visualization
        } = window.google;
        visualization.events.addListener(chart, "select", () => {// TODO drilldown connected with the value barchart (GICS drildown)
        });
      }

    },

    getActiveFilterKeys() {
      return this.activeFilters.map(item => {
        return item.type;
      });
    }

  });

  _exports.default = _default;
});