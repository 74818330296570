define("atcm-client/models/interest-rate", ["exports", "atcm-client/models/base-model", "moment"], function (_exports, _baseModel, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceString: Ember.computed("description", "descriptionShort", function () {
      return this.description + " - " + this.descriptionShort;
    }),
    calculatedRateMid: Ember.computed("rateBid", "rateAsk", function () {
      if (!Ember.isNone(this.rateBid) && !Ember.isNone(this.rateAsk)) {
        return ((parseFloat(this.rateBid) + parseFloat(this.rateAsk)) * "0.5").toFixed(12);
      }

      return;
    }),
    niceRateTimestamp: Ember.computed("rateTimestamp", function () {
      if (this.rateTimestamp) {
        return (0, _moment.default)(this.rateTimestamp).format("DD-MM-YYYY hh:mm:ss");
      }
    })
  });

  _exports.default = _default;
});