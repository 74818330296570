define("atcm-client/controllers/client/impact/companyimpacts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Company",
        displayField: "unGlobalCompact.description",
        alignLeft: true
      }, {
        displayName: "Impact Objective",
        displayField: "impactObjectiveMain.descriptionShort"
      }, {
        displayName: "Document",
        displayField: "document"
      }, {
        displayName: "Year",
        displayField: "year"
      }];
      this.formFields = [{
        displayName: "Company Registration",
        selectionField: "unGlobalCompact",
        displayField: "description",
        model: "unGlobalCompacts",
        queryFields: ["description"]
      }, {
        displayName: "Impact",
        selectionField: "impactObjectiveMain",
        displayField: "description",
        model: "impactObjectiveMains",
        queryFields: ["description"]
      }, {
        displayName: "Report Date",
        displayField: "niceReportDate"
      }, {
        displayName: "Source document",
        displayField: "document",
        required: true
      }, {
        displayName: "Source",
        selectionField: "assessmentSource",
        displayField: "description",
        model: "assessmentSources",
        queryFields: ["description"]
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Impact",
        displayField: "impactObjectiveMains",
        queryField: "impactObjectiveMain",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Company UN Registration",
        displayField: "unGlobalCompacts",
        queryField: "unGlobalCompact",
        displayType: "typeahead",
        isTypeahead: true
      }];
    },

    actions: {
      fetchMore() {
        if (this.get("model.companyImpacts.length") % 10 === 0) {
          const variables = {
            limit: 10,
            offset: this.get("model.companyImpacts.length")
          };
          this.apollo.query({
            query,
            variables
          }).then(result => {
            this.get("model.companyImpacts").pushObjects(result.companyImpacts);
          });
        }
      }

    }
  });

  _exports.default = _default;
});