define("atcm-client/controllers/client/staticdata/securitystatics/index", ["exports", "atcm-client/gql/queries/securities"], function (_exports, _securities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.include = ["currency", "country", "exchangeStatic"];
      this.tableFields = [{
        displayName: "Asset Class",
        displayField: "instrumentType.assetClass.descriptionShort",
        alignLeft: true
      }, {
        displayName: "Instrument Type",
        displayField: "instrumentType.descriptionShort"
      }, {
        displayName: "Description",
        displayField: "description",
        flex: 15
      }, {
        displayName: "ISIN",
        displayField: "isinCode"
      }, {
        displayName: "Sedol",
        displayField: "sedolCode"
      }, {
        displayName: "Figi",
        displayField: "figiCode"
      }, {
        displayName: "Cusip",
        displayField: "cusipCode"
      }, {
        displayName: "Primaire Exchange",
        displayField: "exchangeStatic.marketIdCode"
      }, {
        displayName: "Primairy Currency",
        displayField: "currency.isoCode"
      }, {
        displayName: "Primairy Country",
        displayField: "country.isoThree"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Instrument type",
        selectionField: "instrumentType",
        displayField: "description",
        model: "instrumentTypes",
        required: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "ISIN",
        displayField: "isinCode"
      }, {
        displayName: "Cusip",
        displayField: "cusipCode"
      }, {
        displayName: "FIGI",
        displayField: "figiCode"
      }, {
        displayName: "Sedol",
        displayField: "sedolCode"
      }, {
        displayName: "CIC Classification",
        displayField: "cicClassification"
      }, {
        displayName: "Primairy Currency",
        selectionField: "currency",
        displayField: "isoCode",
        model: "currencies",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Primairy Country",
        selectionField: "country",
        displayField: "description",
        model: "countries",
        queryFields: ["description", "isoThree", "isoTwo"],
        required: true
      }, {
        displayName: "Issuer",
        selectionField: "issuerStatic",
        displayField: "description",
        model: "issuerStatics",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Primairy Exchange",
        selectionField: "exchangeStatic",
        displayField: "description",
        model: "exchangeStatics",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Quotation Type",
        selectionField: "quotationType",
        displayField: "description",
        model: "quotationTypes",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Income Type",
        selectionField: "incomeType",
        displayField: "description",
        model: "incomeTypes",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Issue Date",
        displayField: "niceDateOfIssueDate",
        date: true
      }, {
        displayName: "Maturity Date",
        displayField: "niceDateOfMaturityDate",
        date: true
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Asset Class",
        displayField: "assetClasses",
        queryField: "assetClass",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }, {
        displayName: "Instrument Type",
        displayField: "instrumentTypes",
        queryField: "instrumentType",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }, {
        displayName: "Currency",
        displayField: "currencies",
        queryField: "currency",
        displayType: "typeahead",
        queryFields: ["description", "isoCode"],
        isTypeahead: true
      }, {
        displayName: "Country",
        displayField: "countries",
        queryField: "country",
        displayType: "typeahead",
        queryFields: ["description", "isoTwo", "isoThree"],
        isTypeahead: true
      }, {
        displayName: "Exchange",
        displayField: "exchangeStatics",
        queryField: "exchangeStatic",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort", "marketIdCode"],
        isTypeahead: true
      }];
      this.actionColumns = [{
        name: "Listings",
        action: "showSecurityListings"
      }, {
        name: "Ratings",
        action: "showSecurityRatings"
      }];
    },

    fetchMore() {
      if (this.get("model.secuirtyStatics.length") % 10 === 0) {
        const variables = {
          limit: 10,
          offset: this.get("model.securityStatics.length")
        };
        this.apollo.query({
          query: _securities.default,
          variables
        }).then(result => {
          this.get("model.securityStatics").pushObjects(result.securityStatics);
        });
      }
    },

    actions: {
      processActionColumnAction: function (row, action) {
        this.send(action, row);
      },
      showSecurityRatings: function (securityStatic) {
        this.transitionToRoute("client.staticdata.securitystatics.securityratings", securityStatic);
        return false;
      },
      showSecurityListings: function (securityStatic) {
        this.transitionToRoute("client.staticdata.securitystatics.securitylistings", securityStatic);
        return false;
      }
    }
  });

  _exports.default = _default;
});