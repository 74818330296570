define("atcm-client/components/paper-input-fusion", ["exports", "ember-paper/components/paper-input"], function (_exports, _paperInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperInput.default.extend({
    init() {
      this._super(...arguments);

      this.set("value", this.get("object." + this.displayField));

      if (this.get("requires.length") && this.object) {
        const cpStringForExpansion = "requires.@each,object," + "object.%@".fmt(this.requires.join(","));
        const self = this;
        Ember.defineProperty(this, "visible", Ember.computed(cpStringForExpansion, function () {
          // Here access to this.get('listOne'), this.get('listTwo'), etc.;
          let ret = true;
          this.requires.forEach(requiredProperty => {
            if (!self.get("object." + requiredProperty)) {
              ret = false;
            }
          });
          return ret;
        }));
      }
    },

    object: null,
    displayField: null,
    requires: null,
    visible: true,

    /* manual binding due to lack of binding knowledge ;-) */
    _propagateValueChange: Ember.observer("object", "value", "displayField", function () {
      if (!Ember.isEmpty(this.object) && !Ember.isEmpty(this.displayField)) {
        this.set("object." + this.displayField, this.value);
      }
    })
  });

  _exports.default = _default;
});