define("atcm-client/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NfwtodoW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"loading\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"lc\"],[12],[2,\"\\n\\t\\t\"],[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"Impactalytics\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"sticks\"],[12],[2,\"\\n\\t\\t  \"],[10,\"div\"],[14,0,\"stick\"],[12],[13],[2,\"\\n\\t\\t  \"],[10,\"div\"],[14,0,\"stick\"],[12],[13],[2,\"\\n\\t\\t  \"],[10,\"div\"],[14,0,\"stick\"],[12],[13],[2,\"\\n\\t\\t  \"],[10,\"div\"],[14,0,\"stick\"],[12],[13],[2,\"\\n\\t\\t  \"],[10,\"div\"],[14,0,\"stick\"],[12],[13],[2,\"\\n\\t\\t  \"],[10,\"div\"],[14,0,\"stick\"],[12],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t  \"],[10,\"h2\"],[14,0,\"text-center\"],[12],[2,\"CREATION\"],[10,\"br\"],[12],[13],[2,\"OF\"],[10,\"br\"],[12],[13],[2,\"VALUE\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "atcm-client/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});