define("atcm-client/controllers/client/models/investmentprofiles/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        flex: 15,
        alignLeft: true
      }, {
        displayName: "Sequence",
        displayField: "sequence"
      }, {
        displayName: "Min Points",
        displayField: "min"
      }, {
        displayName: "Max Points",
        displayField: "max"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Sequence",
        displayField: "sequence",
        required: true
      }, {
        displayName: "Min Points",
        displayField: "min",
        required: true
      }, {
        displayName: "Max points",
        displayField: "max",
        required: true
      }];
    },

    actions: {
      showInvestmentprofileratios: function (investmentProfile) {
        this.transitionToRoute("client.models.investmentprofiles.investmentprofileratios", investmentProfile);
        return false;
      }
    }
  });

  _exports.default = _default;
});