define("atcm-client/controllers/index", ["exports", "atcm-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    pageClass: "world-foto",
    config: _environment.default,
    session: Ember.inject.service(),
    // user: null,
    actions: {
      // success: function() {
      // 	this.transitionToRoute("client");
      // },
      login() {
        // Check out the docs for all the options:
        // https://auth0.com/docs/libraries/lock/customization
        const lockOptions = {
          auth: {
            audience: "https://api.impactalytics.io/",
            params: {
              scope: "openid profile email roles",
              responseType: "token id_token"
            }
          }
        };
        this.session.authenticate("authenticator:auth0-lock", lockOptions).then(() => {
          //check if user exists
          if (this.get("session.isAuthenticated")) {
            if (this.get("session.data.authenticated.profile.email_verified")) {
              if (this.get("session.data.roles").length > 0) {
                // get user by e-mail
                const profile = this.get("session.data.authenticated.profile");
                this.store.query("user", {
                  filter: {
                    email: profile.email
                  }
                }).then(users => {
                  if (users.get("length") === 1) {
                    const user = users.objectAt(0);

                    if (user.get("auth0") === profile.sub) {// TODOO all good, let's do an audit log
                    } else {
                      user.set("auth0", profile.sub);
                      user.save().then(() => {// console.log('Updated: user now has auth0 user_id');
                      });
                    }
                  } else if (users.get("length") > 1) {// TODO error a user should never have multiple references
                    // console.error("User has multiple users with this e-mail, this could never happen with proper DB constraints");
                  } else {
                    // create user - we verified the user all is good
                    this.store.createRecord("user", {
                      email: profile.email,
                      name: profile.name,
                      auth0: profile.sub,
                      details: "New user through auth0"
                    }).save();
                  }
                });
              } else {// TODO error user should at least have one role for one tenant
                // console.log('WARNING: user has no roles');
              }
            } else {// todo user was not verified
              // console.log('WARNING: user has not verified email');
            }
          } else {// todo error
            // console.log('ERROR: user is not succesfully authenticated');
          }
        });
      },

      logout() {
        this.session.invalidate();
      }

    }
  });

  _exports.default = _default;
});