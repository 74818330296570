define("atcm-client/templates/client/models/country-fundamental", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aBmBX5Io",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-button\",[],[[\"@onClick\",\"@disabled\",\"@primary\"],[[30,[36,2],[[32,0],\"next\"],null],[34,3],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[1,[34,4]],[2,\"  \"],[8,\"paper-icon\",[],[[\"@icon\",\"@size\"],[\"arrow-forward\",\"lg\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"paper-card-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"high-charts\",[],[[\"@content\",\"@chartOptions\"],[[34,0],[34,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"chartData\",\"chartOptions\",\"action\",\"disabled\",\"nextButtonText\",\"if\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/models/country-fundamental.hbs"
    }
  });

  _exports.default = _default;
});