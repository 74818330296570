define("atcm-client/controllers/client/transactions/orders/compliance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.ordersAvailable = [];
      this.ordersShows = [];
      this.securityOrderAllocFields = [{
        displayName: "Alloc. Id",
        displayField: "allocId"
      }, {
        displayName: "Account (master)",
        displayField: "accountMaster.niceString"
      }, {
        displayName: "Target Price",
        displayField: "price"
      }, {
        displayName: "Target Qty",
        displayField: "orderBaseQuantity"
      }, {
        displayName: "Target Amt",
        displayField: "orderBaseAmount"
      }, {
        displayName: "Currency",
        displayField: "accountMaster.currency.niceString"
      }];
      this.complianceErrorFields = [{
        displayName: "Reason",
        displayField: "complianceRule.reason"
      }, {
        displayName: "Severity",
        displayField: "complianceRule.severity"
      }, {
        displayName: "Rule",
        displayField: "complianceRule.fullDescription"
      }, {
        displayName: "Subrule",
        displayField: "complianceRuleValue.description"
      }];
      this.showing = 10;
    },

    canShowMore: Ember.computed("ordersAvailable.length", "showing", function () {
      return this.get("ordersAvailable.length") > this.showing;
    }),
    _reset: Ember.observer("model.id", function () {
      this.set("ordersAvailable", []);
      this.set("ordersShown", []);
      this.set("showing", 10);
    }),

    _getShowingSlice() {
      return this.get("model.incompliantSecurityOrderMains").slice(0, this.showing);
    },

    actions: {
      showMore: function () {
        this.set("showing", this.showing + 10);
        this.set("ordersShown", this.ordersAvailable ? this.ordersAvailable.slice(0, this.showing) : []);
      },
      sendToTrader: function () {
        //save orders and exit
        const self = this,
              model = this.model;
        this.store.findRecord("statusTransaction", 8).then(status => {
          //update status of 'modelOrder'
          model.set("statusTransaction", status);
          model.save().then(() => {
            self.set("model", null);
            self.transitionToRoute("ordermanagement.orders.index");
          });
        });
      },
      regenerateOrders: function () {// noop
      },
      showIncompliant: function () {
        this.set("showing", 10);
        this.set("ordersAvailable", this.get("model.incompliantSecurityOrderMains"));
        this.set("ordersShown", this.get("model.incompliantSecurityOrderMains") ? this.getShowingSlice() : []);
      },
      showCompliant: function () {
        this.set("showing", 10);
        this.set("ordersAvailable", this.get("model.compliantSecurityOrderMains"));
        this.set("ordersShown", this.get("model.compliantSecurityOrderMains") ? this._getShowingSlice() : []);
      },
      showPending: function () {
        this.set("showing", 10);
        this.set("ordersAvailable", this.get("model.pendingSecurityOrderMains"));
        this.set("ordersShown", this.get("model.pendingSecurityOrderMains") ? this._getShowingSlice() : []);
      }
    }
  });

  _exports.default = _default;
});