define("atcm-client/controllers/client/staticdata/brokerstatics/entry", ["exports", "atcm-client/enums/broker-types"], function (_exports, _brokerTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    brokerTypes: _brokerTypes.default,

    init() {
      this._super(...arguments);

      this.countryQueryFields = ["description", "isoThree", "isoTwo"];
      this.cityQueryFields = ["description"];
      this.fieldsToClearBrokerType = ["executionSwiftCode", "clearingSwiftCode", "executionBicCode", "clearingBicCode", "executionFixCode", "clearingFixCode", "executionFinsCode", "clearingFinsCode", "executionOasysCode", "clearingOasysCode", "executionOasysCreditCode", "clearingOasysCreditCode"];
    },

    _countrySelected: Ember.observer("model.country.id", function () {
      if (!Ember.isNone(this.get("model.country.id"))) {
        this.set("cityQueryParams", {
          country: this.get("model.country.id"),
          limit: -1
        });
      }
    }),
    isExecutionOrBoth: Ember.computed("model.brokerType", function () {
      return this.get("model.brokerType") === "Execution" || this.get("model.brokerType") === "Clearing & Execution";
    }),
    isClearingOrBoth: Ember.computed("model.brokerType", function () {
      return this.get("model.brokerType") === "Clearing" || this.get("model.brokerType") === "Clearing & Execution";
    }),
    actions: {
      save: function () {
        const self = this;
        this.content.save().then(brokerStatic => {
          self.transitionToRoute("staticdata.brokerstatics.edit", brokerStatic);
        });
      },
      rollback: function () {
        const self = this;
        this.content.rollbackAttributes();
        self.transitionToRoute("staticdata.brokerstatics");
      }
    }
  });

  _exports.default = _default;
});