define("atcm-client/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GM5OmqRt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"layout-row layout-align-center-center world-foto\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"paper-button\",[],[[\"@raised\",\"@primary\",\"@onClick\"],[true,true,[30,[36,0],[[32,0],\"login\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Login\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/index.hbs"
    }
  });

  _exports.default = _default;
});