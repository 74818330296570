define("atcm-client/controllers/client/models/security-fundamental", ["exports", "atcm-client/gql/mutations/insertInvestmentModelConstituent", "atcm-client/gql/mutations/deleteInvestmentModelConstituents"], function (_exports, _insertInvestmentModelConstituent, _deleteInvestmentModelConstituents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    securityStatics: null,
    securityStaticsProxy: Ember.computed("securityStatics", function () {
      const investmentModel = this.model;
      return this.securityStatics.map(securityStatic => {
        let checked = false;

        for (const constituent of investmentModel.investmentModelConstituents) {
          if (constituent.securityStatic.id === securityStatic.id) {
            checked = true;
            break;
          }
        }

        return Ember.ObjectProxy.create({
          content: securityStatic,
          checked
        });
      });
    }),
    proxiedCheckedItems: Ember.computed.filterBy("sortedList", "checked", true),
    checkedItems: Ember.computed.mapBy("proxiedCheckedItems", "content"),
    sortedList: Ember.computed.sort("securityStaticsProxy", (a, b) => {
      if (a.checked && !b.checked) {
        return -1;
      } else if (b.checked && !a.checked) {
        return 1;
      } else if (b.checked && a.checked) {
        return (a.content.description > b.content.description) - (a.content.description < b.content.description);
      } else if (parseFloat(a.content.securityFundamentals_aggregate.aggregate.sum.value) < parseFloat(b.content.securityFundamentals_aggregate.aggregate.sum.value)) {
        return 1;
      } else if (parseFloat(a.content.securityFundamentals_aggregate.aggregate.sum.value) > parseFloat(b.content.securityFundamentals_aggregate.aggregate.sum.value)) {
        return -1;
      }

      return 0;
    }),
    actions: {
      next: function () {
        const originalSecurities = this.model.investmentModelConstituents.map(investmentModelConstituent => {
          return parseInt(investmentModelConstituent.securityStatic.id);
        });
        const modelId = this.model.id;
        const newInvestmentModelConstituents = [];
        const investmentModelConstituentsToDelete = []; //make constituents of checked items

        this.checkedItems.forEach(securityStatic => {
          if (!originalSecurities.includes(parseInt(securityStatic.id))) {
            const modelConsituent = {
              targetMinTreshold: 10,
              targetWeight: 100 / this.checkedItems.length,
              targetMaxTreshold: 10,
              investmentModelId: modelId,
              securityStaticId: securityStatic.id
            };
            newInvestmentModelConstituents.pushObject(modelConsituent);
          }
        });
        originalSecurities.forEach(securityId => {
          let removed = true;
          this.checkedItems.forEach(securityStatic => {
            if (securityStatic.id === parseInt(securityId)) {
              removed = false;
            }
          });

          if (removed) {
            investmentModelConstituentsToDelete.push(securityId);
          }
        });
        return this.apollo.mutate({
          mutation: _insertInvestmentModelConstituent.default,
          variables: {
            objects: newInvestmentModelConstituents
          }
        }, "insert_investmentModelConstituents").then(gqlResult => {
          // TODO ensure full reload in next screen
          if (newInvestmentModelConstituents.length > 0) {
            this.paperToaster.show("Constituents added.");
          }

          return this.apollo.mutate({
            mutation: _deleteInvestmentModelConstituents.default,
            variables: {
              investmentModelId: modelId,
              securityStaticIds: investmentModelConstituentsToDelete
            }
          }, "delete_investmentModelConstituents").then(() => {
            if (investmentModelConstituentsToDelete.length > 0) {
              this.paperToaster.show("Constituents removed.");
            }

            this.transitionToRoute("client.models.edit", modelId);
          }).catch(error => {
            console.error(error);
          });
        }).catch(error => {
          console.error(error);
        });
      }
    }
  });

  _exports.default = _default;
});