define("atcm-client/controllers/client/core/ratiogroupsubs/ratios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Ratio Group Sub",
        displayField: "ratioGroupSub.description",
        alignLeft: true
      }, {
        displayName: "Description",
        displayField: "description"
      }, {
        displayName: "Description Short",
        displayField: "descriptionShort"
      }, {
        displayName: "Weight",
        displayField: "weight"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true
      }, {
        displayName: "Description",
        displayField: "description"
      }, {
        displayName: "Description Short",
        displayField: "descriptionShort"
      }, {
        displayName: "Weight",
        displayField: "weight"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Ratios for ratio sub groep level of " + this.get("model.descriptionShort");
    })
  });

  _exports.default = _default;
});