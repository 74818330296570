define("atcm-client/templates/client/staticdata/fixeds/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XHkDpZ+A",
    "block": "{\"symbols\":[],\"statements\":[[8,\"simple-table\",[],[[\"@content\",\"@title\",\"@type\",\"@fields\",\"@filters\",\"@formFields\",\"@actionColumns\",\"@actionColumnAction\"],[[34,0],\"Fixed income\",\"fixed\",[34,1],[34,2],[34,3],[34,4],\"processActionColumnAction\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"tableFields\",\"tableFilters\",\"formFields\",\"actionColumns\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/staticdata/fixeds/index.hbs"
    }
  });

  _exports.default = _default;
});