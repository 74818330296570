define("atcm-client/models/base-model", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    creationTimestamp: Ember.computed("createdAt", function () {
      if (this.createdAt) {
        return (0, _moment.default)(this.createdAt).format("YYYY-MM-DDThh:mm:ssZ");
      }
    }),
    niceCreationDate: Ember.computed("createdAt", function () {
      if (this.createdAt) {
        return (0, _moment.default)(this.createdAt).format("DD-MM-YYYY");
      }
    }),
    niceCreationTimestamp: Ember.computed("createdAt", function () {
      if (this.createdAt) {
        return (0, _moment.default)(this.createdAt).format("DD-MM-YYYY hh:mm:ss");
      }
    }),
    typeAsString: function () {
      Ember.String.dasherize(this.get("constructor.modelName")).replace("-", " ");
    } // didUpdate: function() {
    //     if (toastr !== undefined) {
    //         toastr.remove();
    //         toastr.success(Ember.String.dasherize(this.get('constructor.modelName')).replace('-', ' ') + ' updated');
    //     }
    // },
    // didCreate: function() {
    //     if (toastr !== undefined) {
    //         toastr.remove();
    //         toastr.success(Ember.String.dasherize(this.get('constructor.modelName')).replace('-', ' ') + ' saved');
    //     }
    // },
    // didDelete: function() {
    //     if (toastr !== undefined) {
    //         toastr.remove();
    //         toastr.success('Deleted ' + Ember.String.dasherize(this.get('constructor.modelName')).replace('-', ' '));
    //     }
    // },
    // rolledBack: function() {
    //     if (toastr !== undefined) {
    //         toastr.remove();
    //         toastr.success('Changes discarded');
    //     }
    // }

  });

  _exports.default = _default;
});