define("atcm-client/controllers/client/reports/securitypositions/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Asset Cl.",
        displayField: "securityStatic.instrumentType.assetClass.descriptionShort",
        alignLeft: true
      }, {
        displayName: "Inst. Type",
        displayField: "securityStatic.instrumentType.descriptionShort"
      }, {
        displayName: "Account",
        displayField: "accountMaster.descriptionShort"
      }, {
        displayName: "Account Currency",
        displayField: "accountMaster.currency.isoCode"
      }, {
        displayName: "Security Ticker",
        displayField: "securityStatic.tickerCode"
      }, {
        displayName: "Security ISIN",
        displayField: "securityStatic.isinCode"
      }, {
        displayName: "Security Currency",
        displayField: "securityStatic.currency.isoCode"
      }, {
        displayName: "Quantity",
        displayField: "postTransactionQuantity"
      }, {
        displayName: "Avrg. Cost (acc curr)",
        displayField: "securityPositionAverageCostsPrice"
      }, {
        displayName: "Value (acc curr)",
        displayField: "securityPositionAccountCurrency"
      }, {
        displayName: "Value (sec curr)",
        displayField: "securityPositionSecurityCurrency"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Currency",
        displayField: "accountMasterCurrencies",
        queryField: "accountMasterCurrency",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Account",
        displayField: "accountMasters",
        queryField: "accountMaster",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Security",
        displayField: "securityStatics",
        queryField: "securityStatic",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Custodian",
        displayField: "custodianStatics",
        queryField: "custodianStatic",
        displayType: "typeahead",
        isTypeahead: true
      }];
    },

    editRoute: null
  });

  _exports.default = _default;
});