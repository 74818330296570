define("atcm-client/components/delayed-textfield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    delayedValue: null,
    delay: function () {
      let timer = 0;
      return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
      };
    }(),
    valueDidChange: Ember.observer("value", function () {
      const that = this;
      this.delay(() => {
        if (that.get("value") !== that.get("delayedValue")) {
          that.set("delayedValue", that.get("value"));
        }
      }, 1000);
    })
  });

  _exports.default = _default;
});