define("atcm-client/controllers/client/transactions/orders/edit", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.securityOrderAllocFields = [{
        displayName: "Alloc. Id",
        displayField: "allocId"
      }, {
        displayName: "Account (master)",
        displayField: "accountMaster.niceString"
      }, {
        displayName: "Target Qty",
        displayField: "orderBaseQuantity"
      }, {
        displayName: "Target Amt",
        displayField: "orderBaseAmount"
      }, {
        displayName: "Currency",
        displayField: "accountMaster.currency.niceString"
      }];
    },

    newAllocation: null,
    accountMasterPlaceholder: Ember.computed("newAllocation.accountMaster", function () {
      return Ember.isNone(this.get("newAllocation.accountMaster.id")) ? "Search for an account..." : this.get("newAllocation.accountMaster.niceString");
    }),
    actions: {
      save: function () {
        //TODO implement then to handle compliance rejections
        this.model.save();
      },
      saveNewAllocation: function () {
        const self = this;

        if (this.newAllocation) {
          this.newAllocation.save().then(() => {
            self.set("newAllocation", null);
            Ember.run(() => {
              (0, _jquery.default)(".modal").modal("hide");
            });
          }, () => {//TODO handle rejection
            // console.log(error);
          });
        }
      },
      cancel: function () {
        this.newAllocation.rollbackAttributes();
      },
      addAllocation: function () {
        this.set("newAllocation", this.store.createRecord("securityOrderAlloc", {
          securityOrderMain: this.model
        }));
        Ember.run(() => {
          (0, _jquery.default)("div#add-allocation-modal").modal({
            backdrop: false,
            show: true
          });
        });
      },
      edit: function (allocation) {
        this.set("newAllocation", allocation);
        Ember.run(() => {
          (0, _jquery.default)("div#add-allocation-modal").modal({
            backdrop: false,
            show: true
          });
        });
      },

      close() {
        Ember.run(() => {
          (0, _jquery.default)(".modal").modal("hide");
          (0, _jquery.default)(".modal-backdrop").remove();
        });
      },

      closeOrder: function () {
        const self = this;
        this.store.findRecord("statusTransaction", 2).then(status => {
          self.set("model.statusTransaction", status);
          self.get("model").save();
        });
      },
      sendToTrading: function () {
        const self = this;
        this.store.findRecord("statusTransaction", 8).then(status => {
          self.set("model.statusTransaction", status);
          self.get("model").save();
        });
      }
    },
    _accountMasterSelected: Ember.observer("model.accountMaster.id", "model.securityStatic.id", function () {
      if (this.get("model.accountMaster.id") && this.get("model.securityStatic.id")) {
        this.getSecurityStaticPosition(this.get("model.accountMaster.id"), this.get("model.securityStatic.id")).then(securityPositions => {
          if (securityPositions.get("length") === 1) {
            this.set("model.lastSecurityPosition", securityPositions.objectAt(0).get("postTransactionQuantity"));
          }
        });
      } else {
        //clear the retrieved rating field
        this.set("lastSecurityPosition", null);
      }
    }),
    getSecurityStaticPosition: function (accountMasterId, securityStaticId) {
      return this.store.find("securityPosition", {
        accountMaster: accountMasterId,
        securityStatic: securityStaticId,
        orderBy: "createdAt",
        order: "desc",
        limit: 1
      });
    }
  });

  _exports.default = _default;
});