define("atcm-client/routes/application", ["exports", "ember-simple-auth-auth0/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    // currentUser: service(),
    // session: service("session"),
    // routeAfterAuthentication: computed(function() {
    //   return "/client/analyses";
    // }),
    // beforeModel() {
    //   return this._loadCurrentUser();
    // },
    // sessionAuthenticated() {
    //   this._super(...arguments);
    //   this._loadCurrentUser();
    // },
    // _loadCurrentUser() {
    //   return this.get("currentUser")
    //     .load()
    //     .catch(() => this.get("session").invalidate());
    // },
    beforeSessionExpired() {
      // Do async logic
      // Notify the user that they are about to be logged out.
      return Ember.RSVP.resolve();
    } // actions: {
    //   logout: function() {
    //     this.get("session").invalidate();
    //   }
    // }


  });

  _exports.default = _default;
});