define("atcm-client/templates/components/modal-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hqhkzXpa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"modal inmodal\"],[15,1,[34,0]],[14,\"tabindex\",\"-1\"],[14,\"role\",\"dialog\"],[14,\"aria-hidden\",\"true\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-dialog modal-lg\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-content animated bounceInRight\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"modal-header\"],[12],[2,\"\\n        \"],[10,\"button\"],[14,0,\"close\"],[14,\"data-dismiss\",\"modal\"],[14,4,\"button\"],[12],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[12],[2,\"×\"],[13],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"Close\"],[13],[13],[2,\"\\n        \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[1,[34,1]],[13],[2,\"\\n        \"],[10,\"small\"],[14,0,\"font-bold\"],[12],[1,[34,2]],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-white\"],[24,\"data-dismiss\",\"modal\"],[24,4,\"button\"],[4,[38,6],[[32,0],[35,5]],null],[12],[2,\"Close\"],[13],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,6],[[32,0],[35,7]],null],[12],[2,\"Save\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"id\",\"title\",\"subtitle\",\"appendModal\",\"if\",\"close\",\"action\",\"save\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/components/modal-window.hbs"
    }
  });

  _exports.default = _default;
});