define("atcm-client/models/security-order-alloc", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    //TODO this needs the accountMaster, should happen on server
    allocId: Ember.computed("id", function () {
      return "FUSIONALLOC" + this.id;
    }),
    price: Ember.computed("orderBaseAmount", "orderBaseQuantity", function () {
      return (this.orderBaseAmount / this.orderBaseQuantity).toFixed(2);
    }),
    niceString: Ember.computed("securityOrderMain.order", "allocId", function () {
      return this.get("securityOrderMain.order") + " - " + this.allocId;
    }),
    lastMarketSecurityPrice: Ember.computed("securityOrderMain.securityLastMarketPrice", function () {
      return this.get("securityOrderMain.securityLastMarketPrice");
    }),
    calculatedOrderBaseQuantity: Ember.computed("orderBaseAmount", "lastMarketSecurityPrice", function () {
      // if one of the values is equal to empty then return "undifined" and else calculate
      if (!Ember.isNone(this.orderBaseAmount) && !Ember.isNone(this.lastMarketSecurityPrice)) {
        return (this.orderBaseAmount / this.lastMarketSecurityPrice).toFixed(0);
      }

      return;
    }),
    calculatedOrderBaseAmount: Ember.computed("orderBaseQuantity", "lastMarketSecurityPrice", function () {
      if (!Ember.isNone(this.orderBaseQuantity) && !Ember.isNone(this.lastMarketSecurityPrice)) {
        return (this.orderBaseQuantity * this.lastMarketSecurityPrice).toFixed(2);
      }

      return;
    })
  });

  _exports.default = _default;
});