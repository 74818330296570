define("atcm-client/controllers/client/staticdata/referencerates/entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      save: function () {
        const self = this;
        this.model.save().then(referenceRate => {
          self.transitionToRoute("staticdata.referencerates.edit", referenceRate);
        });
      },
      rollback: function () {
        const self = this;
        this.model.rollbackAttributes();
        self.transitionToRoute("staticdata.referencerates");
      }
    }
  });

  _exports.default = _default;
});