define("atcm-client/controllers/client/staticdata/equities/entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.currencyQueryFields = ["description", "isoCode"];
      this.countryQueryFields = ["description", "isoThree", "isoTwo"];
      this.issuerStaticQueryFields = ["description", "reutersCode"];
    },

    actions: {
      save: function () {
        const self = this;
        this.model.save().then(equity => {
          self.transitionToRoute("staticdata.equities.edit", equity);
        });
      },
      rollback: function () {
        const self = this;
        this.model.rollbackAttributes();
        self.transitionToRoute("staticdata.equities");
      }
    }
  });

  _exports.default = _default;
});