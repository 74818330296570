define("atcm-client/controllers/client/compliance/complianceaccountbaskets/entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      save: function () {
        const self = this;
        this.content.save().then(() => {
          self.transitionToRoute("compliance.compliancebaskets");
        });
      },
      rollback: function () {
        const self = this;
        this.content.rollbackAttributes();
        self.transitionToRoute("compliance.compliancebaskets");
      }
    }
  });

  _exports.default = _default;
});