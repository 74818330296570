define("atcm-client/controllers/client/core/currencies/index", ["exports", "atcm-client/gql/queries/currencies"], function (_exports, _currencies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true
      }, {
        displayName: "ISO Code",
        displayField: "isoCode"
      }, {
        displayName: "Type",
        displayField: "currencyType.descriptionShort"
      }, {
        displayName: "Sorting",
        displayField: "sorting"
      }, {
        displayName: "Decimals",
        displayField: "decimals"
      }, {
        displayName: "Symbol",
        displayField: "symbol"
      }, {
        displayName: "Unicode decimal",
        displayField: "unicodeDecimal"
      }, {
        displayName: "Unicode hex",
        displayField: "unicodeHex"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Currency Type",
        displayField: "currencyTypes",
        queryField: "currencyType",
        displayType: "typeahead",
        isTypeahead: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Currency type",
        selectionField: "currencyType",
        displayField: "description",
        model: "currencyTypes",
        required: true
      }, {
        displayName: "ISO Code",
        displayField: "isoCode",
        required: true
      }, {
        displayName: "Sorting",
        displayField: "sorting"
      }, {
        displayName: "Decimals",
        displayField: "decimals"
      }, {
        displayName: "Symbol",
        displayField: "symbol"
      }, {
        displayName: "Unicode Decimal",
        displayField: "unicodeDecimal"
      }, {
        displayName: "Unicode Hex",
        displayField: "unicodeHex"
      }];
    },

    fetchMore() {
      if (this.get("model.currencies.length") % 10 === 0) {
        const variables = {
          limit: 10,
          offset: this.get("model.currencies.length")
        };
        this.apollo.query({
          query: _currencies.default,
          variables
        }).then(result => {
          this.get("model.currencies").pushObjects(result.currencies);
        });
      }
    },

    editRoute: null
  });

  _exports.default = _default;
});