define("atcm-client/controllers/client/staticdata/counterpartystatics/index", ["exports", "atcm-client/gql/queries/counterparties"], function (_exports, _counterparties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true
      }, {
        displayName: "Short",
        displayField: "descriptionShort"
      }, {
        displayName: "Country",
        displayField: "country.description"
      }, {
        displayName: "City",
        displayField: "city.description"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Country",
        displayField: "countries",
        queryField: "country",
        displayType: "typeahead",
        queryFields: ["description", "isoTwo", "isoThree"],
        isTypeahead: true
      }, {
        displayName: "City",
        displayField: "cities",
        queryField: "city",
        displayType: "typeahead",
        queryFields: ["description"],
        isTypeahead: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short",
        displayField: "descriptionShort",
        required: true
      }, {
        displayName: "Country",
        selectionField: "country",
        displayField: "description",
        model: "countries",
        queryFields: ["description", "isoThree", "isoTwo"],
        required: true,
        fieldsToClear: ["city"]
      }, {
        displayName: "City",
        selectionField: "city",
        displayField: "description",
        model: "cities",
        queryFields: ["description"],
        queryParams: {
          calculate: true,
          country: "country.id",
          limit: -1
        },
        required: true,
        requires: ["country"]
      }];
      this.actionColumns = [{
        name: "Counterparty Ratings",
        action: "showCounterpartyStaticRatings"
      }];
    },

    fetchMore() {
      if (this.get("model.counterpartyStatics.length") % 10 === 0) {
        const variables = {
          limit: 10,
          offset: this.get("model.counterpartyStatics.length")
        };
        this.apollo.query({
          query: _counterparties.default,
          variables
        }).then(result => {
          this.get("model.counterpartyStatics").pushObjects(result.counterpartyStatics);
        });
      }
    },

    actions: {
      processActionColumnAction: function (row, action) {
        this.send(action, row);
      },
      showCounterpartyStaticRatings: function (counterpartyStatic) {
        this.transitionToRoute("client.staticdata.counterpartystatics.ratings", counterpartyStatic);
        return false;
      }
    }
  });

  _exports.default = _default;
});