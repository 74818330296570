define("atcm-client/controllers/client/marketanalyses/managedfunds/index", ["exports", "atcm-client/gql/search/managedFunds"], function (_exports, _managedFunds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Currency",
        displayField: "currencies",
        queryField: "currency",
        displayType: "typeahead",
        queryFields: ["description", "isoCode"],
        isTypeahead: true
      }, {
        displayName: "Country",
        displayField: "countries",
        queryField: "listedCountry",
        displayType: "typeahead",
        queryFields: ["description", "isoTwo", "isoThree"],
        isTypeahead: true
      }];
      this.tableFields = [{
        name: "Asset Manager",
        valuePath: "assetManager.descriptionShort"
      }, {
        name: "Description",
        valuePath: "description"
      }, {
        name: "Legal Enitity Identifier",
        valuePath: "legalEntityIdentifier"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short description",
        displayField: "descriptionShort",
        required: true
      }, {
        displayName: "Investment objective",
        selectionField: "investmentObjective",
        displayField: "descriptionShort",
        model: "investmentObjectives",
        required: true
      }, {
        displayName: "Currency listed...",
        selectionField: "currency",
        displayField: "isoCode",
        model: "currencies",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Legal Entity Identifier",
        displayField: "legalEntityIdentifier",
        required: true
      }];
      this.actionColumns = [{
        name: "Holdings",
        action: "showHoldings"
      }];
    },

    actions: {
      processActionColumnAction: function (row, action) {
        this.send(action, row);
      },
      showHoldings: function (managedFunds) {
        this.transitionToRoute("client.marketanalyses.managedfunds.holdings", managedFunds);
        return false;
      },

      fetchMore() {
        if (this.get("model.managedFunds.length") % 10 === 0) {
          const variables = {
            limit: 10,
            offset: this.get("model.managedFunds.length")
          };
          this.apollo.query({
            query: _managedFunds.default,
            variables
          }).then(result => {
            this.get("model.managedFunds").pushObjects(result.managedFunds);
          });
        }
      }

    }
  });

  _exports.default = _default;
});