define("atcm-client/models/holiday-calendar-sub", ["exports", "atcm-client/models/base-model", "moment"], function (_exports, _baseModel, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceString: Ember.computed("nonBusinessDay", "description", function () {
      return this.nonBusinessDay + " - " + this.description;
    }),
    niceNonBusinessDay: Ember.computed("nonBusinessDay", function () {
      if (this.nonBusinessDay) {
        return (0, _moment.default)(this.nonBusinessDay).format("DD-MM-YYYY");
      }
    })
  });

  _exports.default = _default;
});