define("atcm-client/controllers/client/staticdata/ratingstatics/index", ["exports", "atcm-client/gql/queries/ratingStatics"], function (_exports, _ratingStatics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true
      }, {
        displayName: "Short description",
        displayField: "descriptionShort"
      }, {
        displayName: "Data Vendor",
        displayField: "datavendorStatic.description"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Data Vendor",
        displayField: "datavendorStatics",
        queryField: "datavendorStatic",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short description",
        displayField: "descriptionShort",
        required: true
      }];
    },

    fetchMore() {
      if (this.get("model.ratingStatics.length") % 10 === 0) {
        const variables = {
          limit: 10,
          offset: this.get("model.ratingStatics.length")
        };
        this.apollo.query({
          query: _ratingStatics.default,
          variables
        }).then(result => {
          this.get("model.ratingStatics").pushObjects(result.ratingStatics);
        });
      }
    },

    editRoute: null
  });

  _exports.default = _default;
});