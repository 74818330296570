define("atcm-client/controllers/client/models/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true
      }, {
        displayName: "Focus",
        displayField: "focus"
      }, {
        displayName: "Index",
        displayField: "indexStatic.descriptionShort"
      }];
    },

    actions: {
      editModel(index, row) {
        this.transitionToRoute("client.models.edit", row.id);
      }

    }
  });

  _exports.default = _default;
});