define("atcm-client/templates/client/models", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EU7SAVp6",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-sidenav-container\",[],[[\"@class\"],[\"inner-sidenav\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-sidenav\",[],[[\"@class\",\"@lockedOpen\",\"@open\",\"@onToggle\"],[\"site-sidenav\",\"gt-sm\",[34,0],[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"paper-list\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"menu-item\",[],[[\"@active\",\"@onClick\"],[[30,[36,4],[\"client.models\",[35,3]],null],[30,[36,5],[\"client.models\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Investment models\"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex layout-column bgwhite\"],[14,\"tabindex\",\"-1\"],[14,\"role\",\"main\"],[12],[2,\"\\n    \"],[1,[30,[36,7],[[30,[36,6],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isSidenavOpen\",\"mut\",\"action\",\"currentRouteName\",\"is-active\",\"transition-to\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/models.hbs"
    }
  });

  _exports.default = _default;
});