define("atcm-client/controllers/client/analyses/countryratings/entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.countryQueryFields = ["description", "isoThree", "isoTwo"];
    },

    actions: {
      save: function () {
        const self = this;
        this.content.save().then(countryRating => {
          self.transitionToRoute("ordermanagement.countryratings.edit", countryRating);
        });
      },
      rollback: function () {
        const self = this;
        this.content.rollbackAttributes();
        self.transitionToRoute("ordermanagement.countryratings");
      }
    }
  });

  _exports.default = _default;
});