define("atcm-client/templates/client/transactions/investmentmodelorders/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vh4TzsYK",
    "block": "{\"symbols\":[],\"statements\":[[8,\"simple-table\",[],[[\"@content\",\"@editRoute\",\"@title\",\"@type\",\"@fields\",\"@filters\",\"@formFields\"],[[34,0],\"client.transactions.orders.compliance\",\"Investment model orders\",\"investmentModelOrder\",[34,1],[34,2],[34,3]]],null]],\"hasEval\":false,\"upvars\":[\"model\",\"tableFields\",\"tableFilters\",\"formFields\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/transactions/investmentmodelorders/index.hbs"
    }
  });

  _exports.default = _default;
});