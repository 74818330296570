define("atcm-client/utils/notifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Ember from 'ember';
  var _default = {// notifyFor: {
    // 	'chatMessage': {
    // 		'when': ['new'],
    // 		'route': 'client.chat',
    // 		'queryParams': {
    // 			'chatId': 'id'
    // 		},
    //   'what': 'message'
    // 	}
    // },
    // processNewObject: function (action, type, payload) {
    // toastr.options = {
    // 		"closeButton": true,
    // 		"debug": false,
    // 		"progressBar": true,
    // 		"positionClass": "toast-top-right",
    // 		"onclick": null,
    // 		"showDuration": "200",
    // 		"hideDuration": "1000",
    // 		"timeOut": "3000",
    // 		"extendedTimeOut": "1000",
    // 		"showEasing": "swing",
    // 		"hideEasing": "linear",
    // 		"showMethod": "fadeIn",
    // 		"hideMethod": "fadeOut"
    // 	};
    // if (this.notifyFor.hasOwnProperty(type) && this.notifyFor[type].when.contains(action)) {
    //   Ember.Logger.info('Notifying for ', action, type, payload, payload.createdBy.get('fullName'));
    // 		toastr.remove();
    //   toastr.info(payload[this.notifyFor[type].what], payload.createdBy.get('fullName'));
    // } else {
    //   Ember.Logger.info('Not notifying for ', action, type);
    // }
    // }
  };
  _exports.default = _default;
});