define("atcm-client/services/google-charts", ["exports", "ember-google-charts/services/google-charts"], function (_exports, _googleCharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _googleCharts.default.extend({
    language: "en",
    didLoadPackages: false,

    init() {
      this._super(...arguments);

      this.googlePackages = this.googlePackages || ["corechart"];
      this.defaultOptions = this.defaultOptions || {
        animation: {
          duration: 500,
          startup: false
        }
      };
    },

    loadPackages() {
      const {
        google: {
          charts
        }
      } = window;
      return new Ember.RSVP.Promise((resolve, reject) => {
        // const packagesAreLoaded = charts.loader;
        if (this.didLoadPackages) {
          resolve();
        } else {
          charts.load("current", {
            language: this.language,
            packages: this.googlePackages
          });
          charts.setOnLoadCallback(ex => {
            if (ex) {
              reject(ex);
            }

            this.set("didLoadPackages", true);
            resolve();
          });
        }
      });
    }

  });

  _exports.default = _default;
});