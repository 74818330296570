define("atcm-client/routes/client/core/ratiogroupsubs/ratios", ["exports", "atcm-client/routes/secure"], function (_exports, _secure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    setupController: function (controller) {
      this._super(...arguments);

      const params = this.paramsFor("client.core.ratiogroupsubs.ratios");
      controller.set("presetFilters", {
        ratioGroupSubId: params.ratio_group_sub_id
      });
      return;
    }
  });

  _exports.default = _default;
});