define("atcm-client/models/city", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceString: Ember.computed("description", "timezone.description", function () {
      return this.description + " - " + this.get("timezone.description");
    })
  });

  _exports.default = _default;
});