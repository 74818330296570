define("atcm-client/templates/client/transactions/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "69TqX4WG",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-toolbar\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"md-toolbar-tools\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"md-breadcrumb-page\"],[12],[2,\"Trading dashboard\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"paper-card-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"simple-table\",[],[[\"@content\",\"@editRoute\",\"@title\",\"@type\",\"@fields\"],[[34,0],\"trading.block\",\"Block trades available for trade\",\"blockTrade\",[34,1]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"tableFields\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/transactions/index.hbs"
    }
  });

  _exports.default = _default;
});