define("atcm-client/models/account-master-investment", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    invested: Ember.computed("investmentModelOrder.securityOrderMains.@each.investedPerAccountMaster", function () {
      let total = 0;
      const self = this,
            proms = [];
      proms.pushObject(self.get("accountMaster").then(myAcm => {
        self.get("investmentModelOrder.securityOrderMains").forEach(order => {
          proms.pushObject(order.get("investedPerAccountMaster").forEach(alloc => {
            if (alloc.accountMasterId === myAcm.get("id")) {
              total += parseFloat(alloc.orderBaseAmount);
            }
          }));
        });
      }));
      const a = Ember.RSVP.all(proms).then(() => {
        return total.toFixed(2);
      });
      const ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
      const proxy = ObjectPromiseProxy.create({
        promise: a
      });
      return proxy;
    }),
    percentage: Ember.computed("invested.isFulfilled", function () {
      if (this.get("invested.isFulfilled")) {
        return (this.get("invested.content") / this.amount * 100).toFixed(2);
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});