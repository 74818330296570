define("atcm-client/controllers/client/staticdata/issuerstatics/index", ["exports", "atcm-client/gql/queries/issuers"], function (_exports, _issuers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "HQ Country",
        displayField: "countries",
        queryField: "hqCountry",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Gics Industry Sub",
        displayField: "gicsIndustrySubs",
        queryField: "gicsIndustrySub",
        displayType: "typeahead",
        isTypeahead: true
      }];
      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true
      }, {
        displayName: "GICS Industry Sub",
        displayField: "gicsIndustrySub.description"
      }, {
        displayName: "HQ Country",
        displayField: "hqCountry.isoThree"
      }, {
        displayName: "Legal Entity Identifier",
        displayField: "leiCode"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "created_at",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Bloomberg Identifier Description",
        displayField: "bloomberg_code",
        required: true
      }, {
        displayName: "Thomson Reuters Identifier",
        displayField: "reuters_code",
        required: true
      }, {
        displayName: "FIGI Identifier",
        displayField: "figi_code",
        required: true
      }, // TODO should be relation!? {
      //   displayName: "Parent issuer applicable",
      //   displayField: "parent_issuer",
      //   checkbox: true
      // },
      {
        displayName: "Ultimate beneficial owner",
        displayField: "ultimateBeneficialOwner"
      }, {
        displayName: "GICS Sector code",
        selectionField: "gicsIndustrySub",
        displayField: "description",
        model: "gicsIndustrySubs",
        required: true
      }, {
        displayName: "Risk country",
        selectionField: "riskCountry",
        displayField: "description",
        model: "countries",
        required: true
      }, {
        displayName: "HQ country",
        selectionField: "hqCountry",
        displayField: "description",
        model: "countries",
        required: true,
        fieldsToClear: ["city"]
      }, {
        displayName: "City",
        selectionField: "city",
        displayField: "description",
        model: "cities",
        queryParams: {
          calculate: true,
          country: "hqCountry.id",
          limit: -1
        },
        required: true,
        requires: ["hqCountry"]
      }];
      this.actionColumns = [{
        name: "Securities",
        action: "showSecurityStatics"
      }, {
        name: "Issuer Ratings",
        action: "showIssuerRatings"
      }, {
        name: "Impact Objectives",
        action: "showIssuerImpacts"
      }];
    },

    showMoreTrigger: Ember.computed("model.length", function () {
      return this.get("model.length") % 50 === 0;
    }),
    actions: {
      processActionColumnAction: function (row, action) {
        this.send(action, row);
      },
      showSecurityStatics: function (issuerStatic) {
        this.transitionToRoute("client.staticdata.issuerstatics.securities", issuerStatic);
        return false;
      },
      showIssuerImpacts: function (issuerStatic) {
        this.transitionToRoute("client.staticdata.issuerstatics.impacts", issuerStatic);
        return false;
      },
      showIssuerRatings: function (issuerStatic) {
        this.transitionToRoute("client.staticdata.issuerstatics.ratings", issuerStatic);
        return false;
      },

      fetchMore() {
        if (this.get("model.issuerStatics.length") % 10 === 0) {
          const variables = {
            limit: 10,
            offset: this.get("model.issuerStatics.length")
          };
          this.apollo.query({
            query: _issuers.default,
            variables
          }).then(result => {
            this.get("model.issuerStatics").pushObjects(result.issuerStatics);
          });
        }
      }

    }
  });

  _exports.default = _default;
});