define("atcm-client/controllers/client/marketanalyses/index", ["exports", "atcm-client/gql/queries/managedFundHoldings", "atcm-client/gql/queries/managedFund", "atcm-client/models/managed-fund-holding", "atcm-client/models/sdg-exposure", "atcm-client/models/company-impact-performance", "atcm-client/gql/queries/topFundsBySDG", "atcm-client/gql/queries/nonSDGManagedFundHoldings", "atcm-client/gql/queries/reportingDatesForManagedFund", "atcm-client/gql/queries/companyImpactPerformances"], function (_exports, _managedFundHoldings, _managedFund, _managedFundHolding, _sdgExposure, _companyImpactPerformance, _topFundsBySDG, _nonSDGManagedFundHoldings, _reportingDatesForManagedFund, _companyImpactPerformances) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["f1", "f2", "f1ReportingDate", "f2ReportingDate"],
    apollo: Ember.inject.service(),
    ManagedFundHolding: _managedFundHolding.default,
    SdgExposure: _sdgExposure.default,
    fundHoldingsQuery: _managedFundHoldings.default,
    topFundsBySDGQuery: _topFundsBySDG.default,
    nonSDGManagedFundHoldingsQuery: _nonSDGManagedFundHoldings.default,
    companyImpactPerformances: _companyImpactPerformances.default,
    companyImpactPerformance: _companyImpactPerformance.default,
    selectedAssetManagerLeft: null,
    selectedFundLeft: null,
    assetManagerSearchTextLeft: "Search asset manager...",
    fundSearchTextLeft: "Search funds...",
    fundSearchTextRight: "Search funds...",
    selectedAssetManagerRight: null,
    selectedFundRight: null,
    assetManagerSearchTextRight: "Search asset manager...",
    showSDGDialog: false,
    showNonSDGPositionDialog: false,
    dialogFundId: null,
    selectedBasicTab: "sources",
    leftFundSdgExposure: Ember.computed("leftFund.id", "rightFund.id", function () {
      return Promise.all([this.leftFund, this.rightFund]).then(([leftFund, rightFund]) => {
        if (leftFund && rightFund) {
          const leftSdgExposures = leftFund.sdgExposures.sort((a, b) => {
            return a.sdgId - b.sdgId;
          });
          const rightSdgExposures = rightFund.sdgExposures.sort((a, b) => {
            return a.sdgId - b.sdgId;
          });
          leftSdgExposures.map(sdg => {
            Ember.set(sdg, "loser", sdg.weight < this.getSdgWeightBySdgId(rightSdgExposures, sdg.sdgId));
          });
          return leftSdgExposures;
        } else {
          if (leftFund && leftFund.id) {
            leftFund.sdgExposures.map(sdg => {
              Ember.set(sdg, "loser", false);
            });
            return leftFund.sdgExposures.sort((a, b) => {
              return a.sdgId - b.sdgId;
            });
          }
        }
      }).catch(e => {
        console.log(e);
      });
    }),
    rightFundSdgExposure: Ember.computed("leftFund.id", "rightFund.id", function () {
      return Promise.all([this.leftFund, this.rightFund]).then(([leftFund, rightFund]) => {
        if (leftFund && rightFund) {
          const leftSdgExposures = leftFund.sdgExposures.sort((a, b) => {
            return a.sdgId - b.sdgId;
          });
          const rightSdgExposures = rightFund.sdgExposures.sort((a, b) => {
            return a.sdgId - b.sdgId;
          });
          rightSdgExposures.map(sdg => {
            Ember.set(sdg, "loser", sdg.weight < this.getSdgWeightBySdgId(leftSdgExposures, sdg.sdgId));
          });
          return rightSdgExposures;
        } else {
          if (rightFund && rightFund.id) {
            rightFund.sdgExposures.map(sdg => {
              Ember.set(sdg, "loser", false);
            });
            return rightFund.sdgExposures.sort((a, b) => {
              return a.sdgId - b.sdgId;
            });
          }
        }
      }).catch(e => {
        console.log(e);
      });
    }),

    getSdgWeightBySdgId(sdgExposures, sdgId) {
      for (const exposure of sdgExposures) {
        if (exposure.sdgId === sdgId) {
          return exposure.weight;
        }
      }

      return 0;
    },

    f1ReportingDates: Ember.computed("f1", async function () {
      return await this.getReportingDates(this.f1);
    }),
    f2ReportingDates: Ember.computed("f2", async function () {
      return await this.getReportingDates(this.f2);
    }),
    leftFund: Ember.computed("f1", "f1ReportingDate", function () {
      if (this.f1 && this.f1ReportingDate) {
        return this.getFund(this.f1, this.f1ReportingDate);
      }

      return null;
    }),
    rightFund: Ember.computed("f2", "f2ReportingDate", function () {
      if (this.f2 && this.f2ReportingDate) {
        return this.getFund(this.f2, this.f2ReportingDate);
      }

      return null;
    }),
    assetManagers: null,
    fundsLeft: Ember.computed("selectedAssetManagerLeft.id", function () {
      if (this.selectedAssetManagerLeft && this.selectedAssetManagerLeft.id) {
        return this.getFunds(null, this.selectedAssetManagerLeft.id);
      } else {
        return;
      }
    }),
    fundsRight: Ember.computed("selectedAssetManagerRight.id", function () {
      if (this.selectedAssetManagerRight && this.selectedAssetManagerRight.id) {
        return this.getFunds(null, this.selectedAssetManagerRight.id);
      } else {
        return;
      }
    }),

    getReportingDates(managedFundId) {
      if (managedFundId) {
        return this.apollo.query({
          query: _reportingDatesForManagedFund.default,
          variables: {
            managedFundId
          }
        }).then(gqlresult => {
          const res = gqlresult["exposure_sdgExposures"];
          return res.map(managedFund => managedFund.reportingDate);
        }).catch(error => {
          throw error;
        });
      } else return;
    },

    async getFund(id, reportingDate) {
      if (id && reportingDate) {
        return this.apollo.query({
          query: _managedFund.default,
          variables: {
            id,
            reportingDate
          }
        }).then(async gqlresult => {
          const res = gqlresult["ibor_managedFunds"] && gqlresult["ibor_managedFunds"].length === 1 ? gqlresult["ibor_managedFunds"][0] : null;

          if (res) {
            for (const e of res["sdgExposures"]) {
              e["sdgId"] = parseInt(e.sdgDescription.match(/(\d+)()/)[0]);
              e["percentage"] = parseInt(e.weight * 100);
            }
          }

          if (gqlresult["ibor_managedFundHoldings_aggregate"] && gqlresult["ibor_managedFundHoldings_aggregate"].aggregate) {
            res.positionWithoutSDG = gqlresult.ibor_managedFundHoldings_aggregate.aggregate.sum.marketValueAccountCurrency;
            console.log(res.positionWithoutSDG);
            res.positionWithSDG = res.managedFundHoldings_aggregate && res.managedFundHoldings_aggregate.aggregate ? res.managedFundHoldings_aggregate.aggregate.sum.marketValueAccountCurrency - gqlresult.ibor_managedFundHoldings_aggregate.aggregate.sum.marketValueAccountCurrency : 0;
          }

          return res;
        }).catch(error => {
          throw error;
        });
      } else return;
    },

    queryVariablesTopSDG: Ember.computed("currentSDGInDialog.{sdgId,weight}", function () {
      if (this.currentSDGInDialog && this.currentSDGInDialog.sdgId) {
        const sdgId = this.currentSDGInDialog.sdgId;
        return {
          sdgId: sdgId + ". %",
          limit: 5,
          minWeight: this.currentSDGInDialog.weight,
          offset: 0
        };
      } else {
        return {};
      }
    }),
    fundHoldingVariablesLeft: Ember.computed("f1", function () {
      if (this.f1) {
        return {
          managedFund: this.f1
        };
      }

      return;
    }),
    fundHoldingVariablesRight: Ember.computed("f2", function () {
      if (this.f2) {
        return {
          managedFund: this.f2
        };
      }

      return;
    }),
    countryPieChartDataLeft: Ember.computed("leftFund.id", function () {
      if (this.leftFund) {
        const chartData = [["Country", "Percentage"]];
        return Promise.resolve(this.leftFund).then(leftFund => {
          if (leftFund) {
            leftFund.countryExposures.forEach(countryExposure => {
              console.log("countryExposure", countryExposure);
              chartData.push([countryExposure.countryIsoThree, countryExposure.marketValueAccountCurrency]);
            });
            console.log(leftFund);
            console.log(chartData);
            return chartData;
          } else {
            return;
          }
        }).catch(e => {
          console.error(e);
        });
      }

      return;
    }),
    countryExposureBarChartData: Ember.computed("leftFund.id", "rightFund.id", function () {
      if (this.leftFund && this.rightFund) {
        return Promise.all([this.leftFund, this.rightFund]).then(([leftFund, rightFund]) => {
          if (leftFund.countryExposures && rightFund.countryExposures) {
            const chartData = [["Countries", leftFund.description, {
              type: "string",
              role: "annotation"
            }, rightFund.description, {
              type: "string",
              role: "annotation"
            }]]; // TODO pick top 15

            leftFund.countryExposures.sort((a, b) => a.weight > b.weight ? 1 : -1).slice(0, 15).forEach(row => {
              const val = row.weight;
              const dataRow = [row.countryDescription, parseFloat(val), "" + val];
              const dataRow2 = rightFund.countryExposures.find(rightRow => rightRow.countryId === row.countryId);
              dataRow.push(parseFloat((dataRow2 === null || dataRow2 === void 0 ? void 0 : dataRow2.weight) || 0));
              dataRow.push("" + val);

              if (val) {
                chartData.push(dataRow);
              }
            });
            return chartData;
          } else {
            return null;
          }
        }).catch(e => {
          console.error(e);
        });
      }

      return;
    }),
    sdgExposureBarChartData: Ember.computed("leftFund.id", "leftFundSdgExposure", "rightFund.id", "rightFundSdgExposure", function () {
      return Promise.all([this.leftFundSdgExposure, this.rightFundSdgExposure, this.leftFund, this.rightFund]).then(([leftFundSdgExposure, rightFundSdgExposure, leftFund, rightFund]) => {
        if (leftFundSdgExposure && rightFundSdgExposure) {
          const chartData = [["SDG", leftFund.description, {
            type: "string",
            role: "annotation"
          }, rightFund.description, {
            type: "string",
            role: "annotation"
          }]];
          leftFundSdgExposure.forEach((row, idx) => {
            const val = row.weight;
            const dataRow = [row.sdgDescription, parseFloat(val), "" + val];
            const val2 = rightFundSdgExposure[idx].weight;
            dataRow.push(parseFloat(val2));
            dataRow.push("" + val);

            if (val) {
              chartData.push(dataRow);
            }
          });
          return chartData;
        } else {
          return null;
        }
      }).catch(e => {
        console.error(e);
      });
    }),

    init() {
      this._super(...arguments);

      this.pieChartOptions = {
        colors: ["#004c5f", "#00667f", "#0099bf", "#00ccff", "#80e6ff"],
        legend: {
          textStyle: {
            color: "black",
            fontSize: 12
          }
        },
        sliceVisibilityThreshold: 0
      };
      this.barChartOptions = {
        // title: 'Value grouped by Sector',
        bars: "vertical",
        annotations: {// alwaysOutside: true
        },
        legend: {
          position: "top"
        },
        hAxis: {
          minValue: 0
        },
        vAxis: {
          title: "SDG"
        },
        animation: {
          startup: true,
          easing: "inAndOut"
        }
      };
      this.fundHoldingTableFields = [{
        name: "Description",
        valuePath: "niceString"
      }, {
        name: "Country",
        valuePath: "countryDescription"
      }, {
        name: "Industry sector",
        valuePath: "sectorDescription"
      }, {
        name: "Market value",
        valuePath: "marketValueAccountCurrency"
      }, {
        name: "Security currency",
        valuePath: "currency.isoCode"
      }];
      this.companyImpactPerformancesFields = [{
        name: "Description",
        valuePath: "description"
      }, // {
      // 	name: "LEI",
      // 	valuePath: "company.legalEntityIdentifier",
      // },
      // {
      // 	name: "Company",
      // 	valuePath: "company.description",
      // },
      {
        name: "Document",
        valuePath: "document"
      }, {
        name: "Year",
        valuePath: "year"
      }, // {
      // 	name: "Diversity",
      // 	valuePath: "diversity",
      // },
      {
        name: "Page",
        valuePath: "pageId"
      }, {
        name: "Score",
        valuePath: "score"
      } // {
      // 	name: "Paragraph",
      // 	valuePath: "paragraphId",
      // },
      ];
      this.fundHoldingTableFilters = [{
        displayName: "Search...",
        isText: true
      }];
      this.topFundsTableFilters = [];
      this.companyImpactPerformancesFilters = [];
      this.topFundsTableFields = [{
        name: "Fund",
        valuePath: "managedFund.description"
      }, {
        name: "Asset manager",
        valuePath: "managedFund.assetManager.description"
      }, {
        name: "Percentage",
        valuePath: "percentage"
      }];
      this.managedFundHoldingsTableFilters = [];
      this.managedFundHoldingsTableFields = [{
        name: "Description",
        valuePath: "company.description"
      }, {
        name: "Activity",
        valuePath: "company.sectorActivity.description"
      }, {
        name: "Market value",
        valuePath: "marketValueAccountCurrency"
      }];

      if (this.f1) {
        this.selectedFundLeft = this.getFund(this.f1);
      }
    },

    currentSDGInDialog: null,
    nonSDGManagedFundHoldingsVariables: null,
    actions: {
      showSdgDialog(sdgProperty, fundId) {
        this.toggleProperty("showSDGDialog");
        this.set("companyImpactPerformancesQueryVariables", {
          impactObjectiveMainId: sdgProperty.sdgId,
          managedFundId: fundId
        });
        Ember.set(this, "currentSDGInDialog", sdgProperty);
      },

      closeDialog() {
        this.toggleProperty("showSDGDialog");
      },

      showNonSDGDialog(fundId) {
        this.set("nonSDGManagedFundHoldingsVariables", {
          id: fundId
        });
        this.toggleProperty("showNonSDGPositionDialog");
      },

      closeNonSDGDialog() {
        this.set("nonSDGManagedFundHoldingsVariables", null);
        this.toggleProperty("showNonSDGPositionDialog");
      },

      clearLeftFund() {
        this.set("selectedFundLeft", null);
        this.set("f1", null);
        this.set("f1ReportingDate", null);
      },

      setFundLeft(fund) {
        this.set("f1", fund.id);
      },

      setFundRight(fund) {
        this.set("f2", fund.id);
      },

      clearRightFund() {
        this.set("selectedFundRight", null);
        this.set("f2", null);
        this.set("f2ReportingDate", null);
      },

      afterSdgBarChartRender(chart) {
        const {
          visualization
        } = window.google;
        visualization.events.addListener(chart, "select", () => {// TODO drilldown connected with the value barchart (GICS drildown)
        });
      },

      afterCountryBarChartRender(chart) {
        const {
          visualization
        } = window.google;
        visualization.events.addListener(chart, "select", () => {// TODO drilldown connected with the value barchart (GICS drildown)
        });
      },

      afterPieChartRender(chart) {
        // TODO nothing but filter other charts based on instrumentType, only change data of graph when assetClass is selected
        const {
          visualization
        } = window.google;
        visualization.events.addListener(chart, "select", () => {// const filterType = "Asset Class";
          // const selected = this.model.pieData.raw[chart.getSelection()[0].row];
          // if (!this.getActiveFilterKeys().includes(filterType)) {
          // 	const newFilter = {
          // 		name: selected.description,
          // 		type: filterType,
          // 		field: "assetClass",
          // 		id: selected.id,
          // 	};
          // 	this.set("assetClass", selected.id);
          // 	this.activeFilters.pushObject(newFilter);
          // 	this.send("update");
          // 	this.updatePositionsTable();
          // } else {
          // 	//select and filter instead of drilldown
          // 	const filterField = "instrumentType";
          // 	const newFilter = {
          // 		name: selected.description,
          // 		type: "Instrument Type",
          // 		field: filterField,
          // 		id: selected.id,
          // 	};
          // 	this.set("instrumentType", selected.id);
          // 	if (!this.hasFilter(filterField)) {
          // 		this.activeFilters.pushObject(newFilter);
          // 	}
          // 	this.send("update");
          // 	this.updatePositionsTable();
          // }
        });
      }

    }
  });

  _exports.default = _default;
});