define("atcm-client/controllers/client/staticdata/accountmasters/index", ["exports", "atcm-client/gql/queries/accountMasters"], function (_exports, _accountMasters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Currency",
        displayField: "currencies",
        queryField: "currency",
        displayType: "typeahead",
        queryFields: ["description", "isoCode"],
        isTypeahead: true
      }, {
        displayName: "Country",
        displayField: "countries",
        queryField: "listedCountry",
        displayType: "typeahead",
        queryFields: ["description", "isoTwo", "isoThree"],
        isTypeahead: true
      }];
      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true
      }, {
        displayName: "Investment Objective",
        displayField: "investmentObjective.descriptionShort"
      }, {
        displayName: "Listed Currency",
        displayField: "currency.isoCode"
      }, {
        displayName: "Listed Cntry",
        displayField: "listedCountry.isoThree"
      }, {
        displayName: "Fiscal Cntry",
        displayField: "fiscalCountry.isoThree"
      }, {
        displayName: "Last Market Value",
        displayField: "marketValueLast"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short description",
        displayField: "descriptionShort",
        required: true
      }, {
        displayName: "Listed country",
        selectionField: "listedCountry",
        displayField: "description",
        model: "countries",
        queryFields: ["description", "isoThree", "isoTwo"],
        required: true
      }, {
        displayName: "Fiscal country",
        selectionField: "fiscalCountry",
        displayField: "description",
        model: "countries",
        queryFields: ["description", "isoThree", "isoTwo"],
        required: true
      }, {
        displayName: "Investment objective",
        selectionField: "investmentObjective",
        displayField: "descriptionShort",
        model: "investmentObjectives",
        required: true
      }, {
        displayName: "Currency listed...",
        selectionField: "currency",
        displayField: "isoCode",
        model: "currencies",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Account Custodian",
        selectionField: "custodianStatic",
        displayField: "descriptionShort",
        model: "custodianStatics",
        required: true
      }, {
        displayName: "Quantity rounding",
        displayField: "roundingQuantity"
      }, {
        displayName: "Price rounding",
        displayField: "roundingPrice"
      }, {
        displayName: "Last Market Value",
        displayField: "marketvalueLast"
      }, {
        displayName: "Short Cash Allowed",
        displayField: "shortCashPosition",
        checkbox: true
      }, {
        displayName: "Short Cash Allowance",
        displayField: "shortCashAllowance"
      }];
      this.actionColumns = [{
        name: "Security Positions",
        action: "showSecurityPositions"
      }];
    },

    actions: {
      processActionColumnAction: function (row, action) {
        this.send(action, row);
      },
      showSecurityPositions: function (accountMaster) {
        this.transitionToRoute("client.staticdata.accountmasters.positions", accountMaster);
        return false;
      }
    }
  });

  _exports.default = _default;
});