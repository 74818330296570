define("atcm-client/controllers/client/marketanalyses/companyimpacts/index", ["exports", "atcm-client/gql/search/companyImpacts"], function (_exports, _companyImpacts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
      this.tableFields = [{
        name: "Company",
        valuePath: "unGlobalCompact.description"
      }, {
        name: "SDG Goal",
        valuePath: "impactObjectiveMain.descriptionShort"
      }, {
        name: "Document",
        valuePath: "document"
      }, {
        name: "Source",
        valuePath: "updateUrl"
      }, {
        name: "Date",
        valuePath: "reportDate"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short description",
        displayField: "descriptionShort",
        required: true
      }, {
        displayName: "Investment objective",
        selectionField: "investmentObjective",
        displayField: "descriptionShort",
        model: "investmentObjectives",
        required: true
      }, {
        displayName: "Currency listed...",
        selectionField: "currency",
        displayField: "isoCode",
        model: "currencies",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Legal Entity Identifier",
        displayField: "legalEntityIdentifier",
        required: true
      }];
    },

    actions: {
      fetchMore() {
        if (this.get("model.companyImpacts.length") % 10 === 0) {
          const variables = {
            limit: 10,
            offset: this.get("model.companyImpacts.length")
          };
          this.apollo.query({
            query: _companyImpacts.default,
            variables
          }).then(result => {
            this.get("model.companyImpacts").pushObjects(result.companyImpacts);
          });
        }
      }

    }
  });

  _exports.default = _default;
});