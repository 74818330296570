define("atcm-client/routes/client/analyses/index", ["exports", "atcm-client/routes/secure", "atcm-client/routes/client/analyses/getPositionPerAssetClass", "atcm-client/routes/client/analyses/getPositionPerInstrumentType", "atcm-client/routes/client/analyses/getGicsSectors", "atcm-client/routes/client/analyses/getPositionsPerCurrency", "atcm-client/routes/client/analyses/getPositionsPerCountry", "atcm-client/routes/client/analyses/getCo2"], function (_exports, _secure, _getPositionPerAssetClass, _getPositionPerInstrumentType, _getGicsSectors, _getPositionsPerCurrency, _getPositionsPerCountry, _getCo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // GraphQL queries
  var _default = _secure.default.extend({
    apollo: Ember.inject.service(),
    originalGicsData: null,
    model: function () {
      const pieData = this.apollo.query({
        query: _getPositionPerAssetClass.default
      }).then(gqlResult => {
        return this._createPieChartData(gqlResult.assetClasses);
      });
      const barData = this.apollo.query({
        query: _getGicsSectors.default
      }).then(gqlResult => {
        return this._createGicsData(gqlResult.gicsSectors, "Economic sectors");
      });
      this.originalGicsData = barData;
      const barDataCO2 = this.apollo.query({
        query: _getCo.default
      }).then(gqlResult => {
        return this._createCo2Data(gqlResult.gicsSectors);
      });
      const currencyData = this.apollo.query({
        query: _getPositionsPerCurrency.default
      }).then(gqlResult => {
        return this._calculatePercentages(gqlResult.currencies);
      });
      return Ember.RSVP.hash({
        pieData,
        barData,
        barDataCO2,
        currencyData
      });
    },

    _createPieChartData(data) {
      const chartData = [["Asset Class", "Percentage"]];
      data.forEach(assetClass => {
        const value = assetClass.positions_aggregate ? assetClass.positions_aggregate.aggregate.sum.sumOfIfrsMarketValueAccountCurrency : 0;
        chartData.push([assetClass.description, value]);
      });
      return {
        chart: chartData,
        raw: data
      };
    },

    _calculatePercentages(data) {
      // data contains currency and countries
      const total = data.reduce((total, currency) => {
        let subTotal = 0;

        for (const position of currency.positions) {
          subTotal += position.sumOfIfrsMarketValueAccountCurrency ? position.sumOfIfrsMarketValueAccountCurrency : 0;
        }

        return total + subTotal;
      }, 0);

      for (let j = 0; j < data.length; j++) {
        const currency = data[j];
        const amount = currency.positions.reduce((total, position) => {
          const amount = position.sumOfIfrsMarketValueAccountCurrency ? position.sumOfIfrsMarketValueAccountCurrency : 0;
          return total + amount;
        }, 0);
        currency.part = (amount / total).toFixed(5);
        currency.percentage = (amount / total * 100).toFixed(3);
        currency.percentageRounded = (amount / total * 100).toFixed(0);
        currency.total = amount;
      }

      return data;
    },

    _createCo2Data(data) {
      const chartData = [["Economic Sector", "Value (kilo ton)", {
        type: "string",
        role: "annotation"
      }]];
      let show = false;
      data.forEach(row => {
        const val = row.issuerFundamentalsRatioSum_aggregate.aggregate.sum.value;

        if (val) {
          chartData.push([row.description, parseFloat(val), "" + val]);
          show = true;
        }
      });
      if (show) return chartData;else return [];
    },

    _createGicsData(data, title) {
      const chartData = [[title, "Value (eur)", {
        type: "string",
        role: "annotation"
      }]];
      data.forEach(row => {
        const sum = row.positions_aggregate.aggregate.sum.sumOfIfrsMarketValueAccountCurrency;
        chartData.push([row.description, sum, "" + sum]);
      });
      return {
        chart: chartData,
        raw: data
      };
    },

    getVariables() {
      const controller = this.controllerFor("client.analyses.index");
      const variables = {};
      controller.activeFilters.forEach(filter => {
        variables[filter.field] = filter.id;
      });
      return variables;
    },

    mode: "currency",
    actions: {
      updateGics(economicSector, industryGroup) {
        const controller = this.controllerFor("client.analyses.index");
        const variables = this.getVariables();
        this.apollo.query({
          query: _getGicsSectors.default,
          variables
        }).then(gqlResult => {
          const barData = this._createGicsData(gqlResult.gicsSectors, "Economic sectors");

          if (economicSector !== null && Ember.isEmpty(industryGroup)) {
            let gicsIndustryGroups;

            for (const sector of barData.raw) {
              if (sector.id === economicSector) {
                gicsIndustryGroups = sector.gicsIndustryGroups;
              }
            }

            controller.set("gicsCardTitle", "Industry Group");
            controller.set("model.barData", this._createGicsData(gicsIndustryGroups, "Industry Groups"));
          } else if (Ember.isEmpty(industryGroup)) {
            // removed filter, reset to base
            controller.set("gicsCardTitle", "Economic Sector");
            controller.set("model.barData", barData);
          }
        });
      },

      // view switcher
      updateValueByData(mode) {
        this.set("mode", mode);
        let query = _getPositionsPerCountry.default;
        let resultField = "countries";

        if (mode !== "country") {
          resultField = "currencies";
          query = _getPositionsPerCurrency.default;
        }

        const variables = this.getVariables();
        this.apollo.query({
          query,
          variables
        }).then(gqlResult => {
          this.controllerFor("client.analyses.index").set("model.currencyData", this._calculatePercentages(gqlResult[resultField]));
        });
      },

      update() {
        // console.log("update");
        // update currency/country table
        // use all active filters to filter all calls below
        const controller = this.controllerFor("client.analyses.index");
        const variables = this.getVariables(); // console.log("variables", variables);

        this.send("updateValueByData", this.mode);
        this.send("updateGics", controller.economicSector, controller.industryGroup); // Data for first pieChart

        if (variables.hasOwnProperty("assetClass")) {
          controller.set("assetClassfilterName", "Instrument Type");
          this.apollo.query({
            query: _getPositionPerInstrumentType.default,
            variables
          }).then(gqlResult => {
            controller.set("model.pieData", this._createPieChartData(gqlResult.instrumentTypes));
          });
        } else if (!variables.hasOwnProperty("instrumentType")) {
          // do not reload after click on instrumentType
          controller.set("assetClassfilterName", "Asset Class");
          this.apollo.query({
            query: _getPositionPerAssetClass.default,
            variables
          }).then(gqlResult => {
            // console.log("gqlResult", gqlResult);
            controller.set("model.pieData", this._createPieChartData(gqlResult.assetClasses));
          });
        } else {// no-op
        } // TODO add filter param to all below
        // TODO positions per country with assetClass filter
        // this.get("ajax")
        // 	._getCustomEndpoint("/positions-per-" + this.get("mode"), queryParams)
        // 	.then(data => {
        // 		controller.set("model.currencyData", this._calculatePercentages(data));
        // 	});
        // this.get("ajax")
        // 	._getCustomEndpoint("/positions-per-economic-sector", queryParams)
        // 	.then(data => {
        // 		controller.set("model.barData", this._createPositionsData(data));
        // 	});
        // this.get("ajax")
        // 	._getCustomEndpoint("/co2-per-economic-sector", queryParams)
        // 	.then(data => {
        // 		controller.set("model.barDataCO2", this._createCo2Data(data));
        // 	});
        // this.get("ajax")
        // 	._getCustomEndpoint("/positions-per-currency", queryParams)
        // 	.then(data => {
        // 		controller.set("model.currencyData", this._calculatePercentages(data));
        // 	});
        // todo filter the current list of posistions

      }

    }
  });

  _exports.default = _default;
});