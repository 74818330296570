define("atcm-client/controllers/client/reports/contractpositions/entry", ["exports", "atcm-client/enums/leg-types", "atcm-client/enums/deal-purposes"], function (_exports, _legTypes, _dealPurposes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    legTypes: _legTypes.default,
    dealPurposes: _dealPurposes.default,
    instrumentTypeQueryParams: null,

    init() {
      this._super(...arguments);

      this.currencyQueryFields = ["description", "isoCode"];
      this.tenorQueryFields = ["description", "tenorCode"];
    },

    _assetClassSelected: Ember.observer("model.assetClass.id", function () {
      if (!Ember.isNone(this.get("model.assetClass.id"))) {
        this.set("instrumentTypeQueryParams", {
          assetClass: this.get("model.assetClass.id"),
          csType: "Contract",
          limit: -1
        });
      } else {
        //clear other fields
        this.set("selectedInstrumentType", null);
        this.set("errors", []);
      }
    }),
    _instrumentTypeSelected: Ember.observer("model.instrumentType.id", function () {
      if (!Ember.isNone(this.get("model.instrumentType.id"))) {
        this.set("securityStaticQueryParams", {
          instrumentType: this.get("model.instrumentType.id"),
          limit: -1
        });
      } else {
        //clear other fields
        //  this.set('selectedSecurityStatic', null);
        this.set("errors", []);
      }
    }),
    isReceiveLegFixed: Ember.computed("model.legTypeReceive", function () {
      return this.get("model.legTypeReceive") === "Fixed";
    }),
    isReceiveLegFloating: Ember.computed("model.legTypeReceive", function () {
      return this.get("model.legTypeReceive") === "Floating";
    }),
    isPayLegFixed: Ember.computed("model.legTypePay", function () {
      return this.get("model.legTypePay") === "Fixed";
    }),
    isPayLegFloating: Ember.computed("model.legTypePay", function () {
      return this.get("model.legTypePay") === "Floating";
    }),
    actions: {
      save: function () {
        const self = this;
        this.content.save().then(contractPosition => {
          self.transitionToRoute("trading.contractpositions.edit", contractPosition);
        });
      },
      rollback: function () {
        const self = this;
        this.content.rollbackAttributes();
        self.transitionToRoute("trading.contractpositions");
      }
    }
  });

  _exports.default = _default;
});