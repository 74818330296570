define("atcm-client/controllers/client/core/gicsindustries/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.formFields = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Ratio Group Main",
        displayField: "ratioGroupMains",
        queryField: "ratioGroupMain",
        displayType: "typeahead",
        isTypeahead: true
      }];
      this.tableFields = [{
        displayName: "Industry Code",
        displayField: "industryCode",
        alignLeft: true
      }, {
        displayName: "Description",
        displayField: "description"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Industry Code",
        displayField: "industryCode",
        required: true
      }];
    },

    editRoute: null
  });

  _exports.default = _default;
});