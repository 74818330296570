define("atcm-client/templates/client/chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KhzQaE9n",
    "block": "{\"symbols\":[],\"statements\":[[8,\"chat-panel\",[],[[\"@store\",\"@chats\",\"@user\",\"@users\",\"@chat\"],[[34,0],[34,1],[34,2,[\"index\",\"user\"]],[34,3],[34,4]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"store\",\"model\",\"controllers\",\"users\",\"chat\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/chat.hbs"
    }
  });

  _exports.default = _default;
});