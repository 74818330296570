define("atcm-client/controllers/client/staticdata/securityprices/entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      save: function () {
        const self = this;
        this.model.save().then(securityPrice => {
          self.transitionToRoute("valuation.securityprices.edit", securityPrice);
        });
      },
      isClosing: Ember.computed("model.pricingOption.descriptionShort", function () {
        if (this.get("model.pricingOption.descriptionShort") === "C") {
          return true;
        } else {
          this.set("model.tradingVolume", null);
          return false;
        }
      }),
      rollback: function () {
        const self = this;
        this.content.rollbackAttributes();
        self.transitionToRoute("valuation.securityprices");
      }
    }
  });

  _exports.default = _default;
});