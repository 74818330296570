define("atcm-client/controllers/client/staticdata/counterpartystatics/ratings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        alignLeft: true
      }, {
        displayName: "Rating Long",
        displayField: "longRatingStatic.description"
      }, {
        displayName: "Rating Short",
        displayField: "shortRatingStatic.description"
      }, {
        displayName: "Rating Date",
        displayField: "ratingDate",
        disabled: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true
      }, {
        displayName: "Rating Long",
        selectionField: "longRatingStatic",
        displayField: "description",
        model: "ratingStatics",
        queryFields: ["description", "descriptionShort"],
        required: true
      }, {
        displayName: "Rating Short",
        selectionField: "shortRatingStatic",
        displayField: "description",
        model: "ratingStatics",
        queryFields: ["description", "descriptionShort"],
        required: true
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Counterparty Ratings of " + this.get("model.descriptionShort");
    })
  });

  _exports.default = _default;
});