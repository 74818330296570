define("atcm-client/templates/components/type-ahead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F+w3OPmu",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"label\"],[14,0,\"type-ahead\"],[12],[1,[34,3]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[\"input-group \",[30,[36,5],[[35,4],\"twitter-typeahead\"],null]]]],[12],[2,\"\\n    \"],[8,\"input\",[[16,0,[34,0]]],[[\"@disabled\",\"@value\"],[true,[34,6]]],null],[2,\"\\n    \"],[10,\"span\"],[14,0,\"input-group-addon\"],[12],[2,\"\\n      \"],[11,\"i\"],[24,0,\"fa fa-close text-warning\"],[4,[38,7],[[32,0],\"clear\"],null],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n    \"],[8,\"input\",[[16,0,[34,0]],[16,\"placeholder\",[34,1]]],[[\"@disabled\"],[[34,2]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"class\",\"placeholderComputed\",\"disabled\",\"placeholder\",\"isNotTypeAhead\",\"if\",\"selectedDisplayValue\",\"action\",\"selection\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/components/type-ahead.hbs"
    }
  });

  _exports.default = _default;
});