define("atcm-client/controllers/tenants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service("session"),
    selectedTenant: null,
    actions: {
      selectTenant(tenant) {
        const currentData = this.get("session.data");
        currentData.tenant = tenant;
        this.get("session.store").persist(currentData);
        this.transitionToRoute("client.marketanalyses");
      }

    }
  });

  _exports.default = _default;
});