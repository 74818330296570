define("atcm-client/controllers/client/staticdata/indexconstituents/entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFilters = [{
        displayName: "Search",
        displayField: ["description", "descriptionShort", "internalCode", "tickerCode", "ricCode", "isinCode"],
        displayType: "text",
        isText: true
      }, {
        displayName: "Currency",
        displayField: "currencies",
        queryField: "securityStatic.currency",
        displayType: "typeahead",
        queryFields: ["description", "isoCode"],
        isTypeahead: true
      }, {
        displayName: "Country",
        displayField: "countries",
        queryField: "securityStatic.country",
        displayType: "typeahead",
        queryFields: ["description", "isoTwo", "isoThree"],
        isTypeahead: true
      }, {
        displayName: "Exchange",
        displayField: "exchangeStatics",
        queryField: "securityStatic.exchangeStatic",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort", "marketIdCode"],
        isTypeahead: true
      }, {
        displayName: "Sector",
        displayField: "trbcActivities",
        queryField: "securityStatic.issuerStatic.trbcActivity",
        displayType: "typeahead",
        queryFields: ["description"],
        isTypeahead: true
      }];
    },

    indexStaticPlaceholder: Ember.computed("indexStatic", function () {
      return Ember.isNone(this.get("indexStatic.id")) ? "Search for an index static..." : this.get("indexStatic.niceString");
    }),
    securityStaticPlaceholder: Ember.computed("securityStatic", function () {
      return Ember.isNone(this.get("securityStatic.id")) ? "Search for an security static..." : this.get("securityStatic.niceString");
    }),
    actions: {
      save: function () {
        const self = this;
        this.content.save().then(() => {
          self.transitionToRoute("staticdata.indexstatics");
        });
      },
      rollback: function () {
        const self = this;
        this.content.rollbackAttributes();
        self.transitionToRoute("staticdata.indexstatics");
      }
    }
  });

  _exports.default = _default;
});