define("atcm-client/controllers/client/susty/countryfundamentals/index", ["exports", "atcm-client/gql/queries/countryFundamentals"], function (_exports, _countryFundamentals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Country",
        displayField: "country.description",
        alignLeft: true
      }, {
        displayName: "Ratio",
        displayField: "ratio.description"
      }, {
        displayName: "Value",
        displayField: "value"
      }, {
        displayName: "Year",
        displayField: "year"
      }, {
        displayName: "Data source",
        displayField: "ratio.ratioGroupSub.ratioGroupMain.descriptionShort"
      }, {
        displayName: "Datavendor",
        displayField: "ratio.ratioGroupSub.ratioGroupMain.datavendorStatic.descriptionShort"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Country",
        displayField: "countries",
        queryField: "country",
        displayType: "typeahead",
        queryFields: ["description", "isoTwo", "isoThree"],
        isTypeahead: true
      }, {
        displayName: "Ratio",
        displayField: "ratios",
        queryField: "ratio",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description"
      }, {
        displayName: "Country",
        selectionField: "country",
        displayField: "description",
        model: "countries",
        queryFields: ["description", "isoThree", "isoTwo"],
        required: true
      }, {
        displayName: "Ratio",
        selectionField: "ratio",
        displayField: "description",
        model: "ratios",
        queryFields: ["description", "descriptionShort"],
        required: true
      }, {
        displayName: "Value",
        displayField: "value",
        required: true
      }, {
        displayName: "Year",
        displayField: "year",
        required: true
      }];
    },

    fetchMore() {
      if (this.get("model.countryFundamentals.length") % 10 === 0) {
        const variables = {
          limit: 10,
          offset: this.get("model.countryFundamentals.length")
        };
        this.apollo.query({
          query: _countryFundamentals.default,
          variables
        }).then(result => {
          this.get("model.countryFundamentals").pushObjects(result.countryFundamentals);
        });
      }
    },

    editRoute: null
  });

  _exports.default = _default;
});