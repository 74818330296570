define("atcm-client/routes/client/transactions/orders/compliance", ["exports", "atcm-client/routes/secure"], function (_exports, _secure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    afterModel: function (model) {
      // fetch all needed data
      if (model.get("securityOrderMains").length === 0) {
        //reload model
        const controller = this.controllerFor("ordermanagement.orders.compliance");
        return model.reload().then(newModel => {
          controller.set("model", newModel);
        });
      }
    }
  });

  _exports.default = _default;
});