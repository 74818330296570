define("atcm-client/controllers/client/staticdata/fixeds/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Instrument Type",
        displayField: "instrumentType.descriptionShort",
        alignLeft: true
      }, {
        displayName: "Description",
        displayField: "description",
        flex: 15,
        alignLeft: true
      }, {
        displayName: "Primaire MIC",
        displayField: "exchangeStatic.marketIdCode",
        alignLeft: true
      }, {
        displayName: "ISIN Code",
        displayField: "isinCode",
        alignLeft: true
      }, {
        displayName: "Currency Listed",
        displayField: "currency.isoCode"
      }, {
        displayName: "Country Listed",
        displayField: "country.isoThree"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Instrument type",
        selectionField: "instrumentType",
        displayField: "description",
        model: "instrumentTypes",
        required: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "ISIN Code",
        displayField: "isinCode"
      }, {
        displayName: "Reuters Code",
        displayField: "ricCode"
      }, {
        displayName: "FIGI Code",
        displayField: "figiCode"
      }, {
        displayName: "Nominal",
        displayField: "nominal"
      }, {
        displayName: "Face Value",
        displayField: "faceValue"
      }, {
        displayName: "Internal Code",
        displayField: "internalCode"
      }, {
        displayName: "Currency",
        selectionField: "currency",
        displayField: "isoCode",
        model: "currencies",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Country",
        selectionField: "country",
        displayField: "description",
        model: "countries",
        required: true
      }, {
        displayName: "Issuer",
        selectionField: "issuerStatic",
        displayField: "description",
        model: "issuerStatics",
        required: true
      }, {
        displayName: "Exchange",
        selectionField: "exchangeStatic",
        displayField: "description",
        model: "exchangeStatics",
        required: true
      }, {
        displayName: "Quotation Type",
        selectionField: "quotationType",
        displayField: "description",
        model: "quotationTypes",
        required: true
      }, {
        displayName: "Income Type",
        selectionField: "incomeType",
        displayField: "description",
        model: "incomeTypes",
        required: true
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Currency",
        displayField: "currencies",
        queryField: "currency",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Country",
        displayField: "countries",
        queryField: "country",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Exchange",
        displayField: "exchangeStatics",
        queryField: "exchangeStatic",
        displayType: "typeahead",
        isTypeahead: true
      }];
      this.actionColumns = [{
        name: "Secruity Ratings",
        action: "showSecurityRatings"
      }];
    },

    actions: {
      processActionColumnAction: function (row, action) {
        this.send(action, row);
      },
      showSecurityRatings: function (securityStatic) {
        this.transitionToRoute("client.staticdata.securitystatics.securityratings", securityStatic);
        return false;
      }
    }
  });

  _exports.default = _default;
});