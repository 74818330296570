define("atcm-client/controllers/client/impact/companyimpacts/performances", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    query: null,
    dataVariables: null,

    init() {
      this._super(...arguments);

      this.include = ["assessmentSource", "meassurementUnit"];
      this.tableFields = [{
        displayName: "Document",
        displayField: "document"
      }, {
        displayName: "Description",
        displayField: "description"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true
      }, {
        displayName: "Source",
        selectionField: "assessmentSource",
        displayField: "description",
        model: "assessmentSources",
        queryFields: ["description"]
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Source",
        displayField: "assessmentSources",
        queryField: "assessmentSource",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Unit",
        displayField: "meassurementUnits",
        queryField: "meassurementUnit",
        displayType: "typeahead",
        isTypeahead: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Impact measurements for " + this.model.unGlobalCompact.description;
    })
  });

  _exports.default = _default;
});