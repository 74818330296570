define("atcm-client/controllers/client/staticdata/indexstatics/constituents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Security Static",
        displayField: "securityStatic.descriptionShort",
        alignLeft: true
      }, {
        displayName: "Weight (%)",
        displayField: "weightPercentage"
      }, {
        displayName: "Market Capitalization",
        displayField: "marketCapitalization"
      }, {
        displayName: "Meassurement Date",
        displayField: "meassurementDate"
      }, {
        displayName: "Sector Activity Code",
        displayField: "securityStatic.issuerStatic.trbcActivity.description"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true
      }, {
        displayName: "Security",
        selectionField: "securityStatic",
        displayField: "ricCode",
        model: "securityStatic",
        queryFields: ["description"]
      }, {
        displayName: "Weight (in %)",
        displayField: "weightPercentage"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Index Constituents of " + this.get("model.niceString");
    })
  });

  _exports.default = _default;
});