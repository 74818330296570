define("atcm-client/models/managed-fund-holding", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    countryDescription: Ember.computed("company.hqCountry.isoThree", "country.isoThree", function () {
      return this.company && this.company.hqCountry ? this.company.hqCountry.isoThree : this.country.isoThree;
    }),
    sectorDescription: Ember.computed("company.sectorActivity", "country.sectorActivity", function () {
      return this.company && this.company.sectorActivity ? this.company.sectorActivity.description : this.country && this.country.sectorActivity ? this.country.sectorActivity.description : "<no country or company>";
    }),
    niceString: Ember.computed("company.description", "country.description", function () {
      return this.company && this.company.description ? this.company.description : this.country.description;
    })
  });

  _exports.default = _default;
});