define("atcm-client/controllers/client/transactions/investmentmodelorders/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Status Order",
        displayField: "statusTransaction.description"
      }, {
        displayName: "Investment Objective",
        displayField: "investmentObjective.description"
      }, {
        displayName: "Accountmasters",
        displayField: "accountMasterInvestments.length"
      }];
    }

  });

  _exports.default = _default;
});