define("atcm-client/models/index-constituent", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceString: Ember.computed("indexstatic.description", "securitystatic.description", function () {
      return this.get("indexStatic.description") + " - " + this.get("securitystatic.description");
    })
  });

  _exports.default = _default;
});