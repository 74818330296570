define("atcm-client/templates/client/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+N4C9ZSg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[12],[2,\"Welcome valued client!\"],[13],[2,\"\\n\"],[10,\"p\"],[12],[2,\"We wish you a profitable day (and a nice starting dashboard)!\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/index.hbs"
    }
  });

  _exports.default = _default;
});