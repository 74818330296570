define("atcm-client/routes/client/models/security-fundamental", ["exports", "atcm-client/routes/secure", "atcm-client/routes/client/models/getSecuritiesForModel", "atcm-client/routes/client/models/getInvestmentModel"], function (_exports, _secure, _getSecuritiesForModel, _getInvestmentModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    apollo: Ember.inject.service(),
    model: function (params) {
      return this.apollo.query({
        query: _getInvestmentModel.default,
        variables: {
          id: params.investment_model_id
        }
      }).then(gqlResult => {
        return gqlResult["investmentModels"][0];
      });
    },
    afterModel: function (model) {
      const variables = {};
      variables.securityStaticIds = model.investmentModelConstituents ? model.investmentModelConstituents.map(constituent => constituent.securityStatic.id) : [];

      if (model.focus === "Issuer Fundamentals" || model.focus === "All Fundamentals") {
        variables.issuerStaticIds = model.investmentModelIssuers ? model.investmentModelIssuers.map(issuer => issuer.issuerStaticId) : [];
      }

      if (model.focus === "Country Fundamentals") {
        variables.countryIds = model.investmentModelCountries ? model.investmentModelCountries.map(country => country.countryId) : [];
      } // TODO fetch all securities that are already in the model!


      return this.apollo.query({
        query: _getSecuritiesForModel.default,
        variables
      }).then(gqlResult => {
        this.controllerFor("client.models.security-fundamental").set("securityStatics", gqlResult["securityStatics"]);
      });
    }
  });

  _exports.default = _default;
});