define("atcm-client/templates/client/transactions/securitytransactions/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bIxaFEer",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"simple-table\",[],[[\"@content\",\"@editRoute\",\"@title\",\"@type\",\"@fields\",\"@actionColumnName\",\"@actionColumnAction\",\"@filters\"],[[34,5],\"client.transactions.securitytransactions.edit\",\"Security Transactions\",\"securityTransaction\",[34,6],\"Transaction Fills\",\"showSecurityTransactionFills\",[34,4]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"row wrapper page-heading\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"col-lg-10\"],[12],[2,\"\\n            \"],[10,\"ol\"],[14,0,\"breadcrumb gray-bg\"],[12],[2,\"\\n                \"],[10,\"li\"],[12],[11,\"a\"],[4,[38,0],[[32,0],\"clearActiveSecurityTransaction\"],null],[12],[2,\"Transaction fills\"],[13],[13],[2,\"\\n                \"],[10,\"li\"],[14,0,\"active\"],[12],[10,\"strong\"],[12],[1,[34,1]],[13],[13],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"col-lg-2\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[8,\"simple-table\",[],[[\"@content\",\"@editRoute\",\"@title\",\"@type\",\"@fields\",\"@filters\"],[[34,2,[\"securityTransactionFills\"]],\"client.transactions.securitytransactionfills.edit\",[34,1],\"securityTransactionFill\",[34,3],[34,4]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"securityTransactionFillsForSecurityTransactionLabel\",\"activeSecurityTransaction\",\"tableFieldsSecurityTransactionFills\",\"tableFilters\",\"model\",\"tableFields\",\"unless\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/transactions/securitytransactions/index.hbs"
    }
  });

  _exports.default = _default;
});