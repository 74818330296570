define("atcm-client/controllers/client/core/impactobjectiveindicators/entry", ["exports", "atcm-client/enums/indicator-types", "atcm-client/enums/reference-points", "atcm-client/enums/metric-types", "atcm-client/enums/impact-value-chains", "atcm-client/enums/maturity-metrics", "atcm-client/enums/meassurement-units"], function (_exports, _indicatorTypes, _referencePoints, _metricTypes, _impactValueChains, _maturityMetrics, _meassurementUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    indicatorTypes: _indicatorTypes.default,
    referencePoints: _referencePoints.default,
    metricTypes: _metricTypes.default,
    impactValueChains: _impactValueChains.default,
    maturityMetrics: _maturityMetrics.default,
    meassurementUnits: _meassurementUnits.default,
    actions: {
      save: function () {
        const self = this;
        this.content.save().then(() => {
          self.transitionToRoute("client.core.impactobjectiveindicators");
        });
      },
      rollback: function () {
        const self = this;
        this.content.rollbackAttributes();
        self.transitionToRoute("client.core.impactobjectivesubs");
      }
    }
  });

  _exports.default = _default;
});