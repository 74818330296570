define("atcm-client/controllers/client/models/country-fundamental", ["exports", "jquery", "atcm-client/enums/focussus", "atcm-client/gql/mutations/insertInvestmentModelCountries", "atcm-client/gql/mutations/deleteInvestmentModelCountries"], function (_exports, _jquery, _focussus, _insertInvestmentModelCountries, _deleteInvestmentModelCountries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    esgRatios: null,
    focussus: _focussus.default,
    selectedCountries: null,
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.selectedCountries = [];
    },

    nextButtonText: Ember.computed("model.focus", "selectedCountries.lenght", function () {
      const nextString = this.get("model.focus") === "All Sustainability" ? "issuers" : "securities";
      return "Choose " + nextString + " based on " + this.get("selectedCountries.length") + " countries";
    }),
    disabled: Ember.computed("selectedCountries.length", function () {
      return this.get("selectedCountries.length") === 0;
    }),
    chartOptions: Ember.computed("esgRatios", function () {
      return {
        chart: {
          type: "bar",
          height: 1000
        },
        title: {
          text: "Country Sustainability Ranking"
        },
        xAxis: {
          categories: this.esgRatios.categories,
          labels: {
            minTickInterval: 1,
            useHTML: true,
            formatter: function () {
              return "<label>" + this.value.name + '</label>&nbsp;<input type="checkbox" class="country" id="' + this.value.id + '"' + this.value.selected + ">";
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: "Total Sustainability"
          }
        },
        legend: {
          reversed: true
        },
        plotOptions: {
          series: {
            stacking: "normal"
          }
        }
      };
    }),
    chartData: Ember.computed("esgRatios", function () {
      return this.esgRatios.chartData;
    }),
    _checkboxObserver: Ember.on("init", function () {
      this.set("selectedCountries", (0, _jquery.default)(".country[id]").map(function () {
        if (this.checked) {
          return this.id;
        }
      }).get());
      Ember.run.later(this, this._checkboxObserver, 500);
    }),
    actions: {
      next() {
        const originalCountryIds = this.model.investmentModelCountries ? this.model.investmentModelCountries.map(investmentModelCountry => investmentModelCountry.countryId) : [];
        const modelId = this.model.id;
        const investmentModelCountriesToDelete = [];
        const newInvestmentModelCountries = [];
        (0, _jquery.default)(".country[id]").map(function () {
          if (this.checked) {
            if (!originalCountryIds.includes(parseInt(this.id))) {
              newInvestmentModelCountries.push({
                investmentModelId: modelId,
                countryId: this.id
              });
            }
          } else {
            if (originalCountryIds.includes(parseInt(this.id))) {
              investmentModelCountriesToDelete.push(this.id);
            }
          }
        });
        this.apollo.mutate({
          mutation: _insertInvestmentModelCountries.default,
          variables: {
            objects: newInvestmentModelCountries
          }
        }, "insert_investmentModelCountries").then(() => {
          this.apollo.mutate({
            mutation: _deleteInvestmentModelCountries.default,
            variables: {
              investmentModelId: modelId,
              countryIds: investmentModelCountriesToDelete
            }
          }, "delete_investmentModelCountries").then(() => {
            let nextRoute = "";

            if (this.model.focus === "Country Fundamentals") {
              nextRoute = "security-fundamental";
            } else if (this.model.focus === "All Fundamentals") {
              nextRoute = "issuer-fundamental";
            }

            this.transitionToRoute("client.models." + nextRoute, modelId);
          }).catch(error => console.error(error));
        }).catch(error => console.error(error));
      }

    }
  });

  _exports.default = _default;
});