define("atcm-client/controllers/client/impact/impactobservations/index", ["exports", "atcm-client/gql/queries/impactObservations", "moment", "atcm-client/models/impact-observation"], function (_exports, _impactObservations, _moment, _impactObservation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),
    mapToType: _impactObservation.default,

    init() {
      this._super(...arguments);

      this.newModels = [];
      this.tableFields = [{
        displayName: "Impact Objective",
        displayField: "impactObjectiveMain.descriptionShort",
        alignLeft: true,
        flex: 10
      }, {
        displayName: "Issuer",
        displayField: "issuerStatic.description",
        alignLeft: true,
        flex: 20
      }, {
        displayName: "Instrument Type",
        displayField: "securityStatic.instrumentType.descriptionShort",
        alignLeft: true,
        flex: 10
      }, {
        displayName: "Security",
        displayField: "securityStatic.description",
        alignLeft: true,
        flex: 15
      }, {
        displayName: "Impact Type",
        displayField: "impactObservationType.descriptionShort",
        alignLeft: true,
        flex: 10
      }, {
        displayName: "Assessment date",
        displayField: "niceObservationTimestamp",
        alignLeft: true,
        flex: 10
      }, {
        displayName: "Solution Investment",
        displayField: "bio",
        alignLeft: true,
        flex: 10
      }, {
        displayName: "Approved",
        displayField: "acceptanceStatus",
        alignLeft: true,
        flex: 10
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Impact Types",
        displayField: "impactObservationTypes",
        queryField: "impactObservationType",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }, {
        displayName: "Impact Objectives",
        displayField: "impactObjectiveMains",
        queryField: "impactObjectiveMain",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }, {
        displayName: "Issuer",
        displayField: "issuerStatics",
        queryField: "issuerStatic",
        displayType: "typeahead",
        queryFields: ["description"],
        isTypeahead: true
      }, {
        displayName: "Security",
        displayField: "securityStatics",
        queryField: "securityStatic",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }];
    },

    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    selectedTab: 0,
    searchService: Ember.inject.service("search"),
    countrySearchText: null,
    gicsIndustrySubSearchText: null,
    currencySearchText: null,
    ratingStaticSearchText: null,
    showAddNewEntry: false,
    currentObservation: null,
    showAddAllocationDialog: false,
    showAddMessageDialog: false,
    twoThreeOrFour: Ember.computed("currentObservation.{questionTwo,questionThree,questionFour}", function () {
      return this.get("currentObservation.questionTwo") || this.get("currentObservation.questionThree") || this.get("currentObservation.questionFour");
    }),
    sixOrSeven: Ember.computed("currentObservation.{questionSix,questionSeven}", function () {
      return this.get("currentObservation.questionSix") || this.get("currentObservation.questionSeven");
    }),
    isBio: Ember.computed("sixOrSeven", function () {
      return this.sixOrSeven;
    }),
    noMatchesMessageimpactObjectiveMain: Ember.computed("ImpactObjectiveMainSearchText", function () {
      if (this.get("ImpactObjectiveMainSearchText.length") <= 2) {
        return "Type at least three characters to search...";
      } else {
        return "No matches found";
      }
    }),
    noMatchesMessageSecurityStatic: Ember.computed("securityStaticSearchText", function () {
      if (this.get("SecurityStaticSearchText.length") <= 2) {
        return "Type at least three characters to search...";
      } else {
        return "No matches found";
      }
    }),
    cannotAddAllocation: Ember.computed("currentObservation.{isNew,hasDirtyAttributes}", function () {
      return this.get("currentObservation.isNew") || this.get("currentObservation.hasDirtyAttributes");
    }),

    closeAddNewEntry() {
      this.set("selectedTab", 0);
      this.toggleProperty("showAddNewEntry");
    },

    showMoreTrigger: Ember.computed("model.length", function () {
      return this.get("model.length") % 50 === 0;
    }),
    actions: {
      addNewObservation() {
        // TODO set proper base properties
        // Show modal with add/edit component form
        const newObservation = {};
        this.set("currentObservation", newObservation);
        this.closeAddNewEntry();
      },

      editObservation(observation) {
        this.set("currentObservation", observation);
        this.closeAddNewEntry();
      },

      closeDialog() {
        this.closeAddNewEntry();
      },

      saveObservation() {
        const push = this.get("currentObservation.isNew");
        this.currentObservation.save().then(currentObservation => {
          if (push) this.newModels.pushObject(currentObservation);
        });
      },

      discardChanges() {
        if (this.get("currentObservation.isNew")) {
          this.currentObservation.destroyRecord();
          this.closeAddNewEntry();
        } else {
          this.currentObservation.rollbackAttributes();
        }
      },

      setReadyForApproval() {
        this.set("currentObservation.readyForApproval", true);
        this.currentObservation.save();
      },

      createAllocation(accountMaster) {
        const observation = this.currentObservation; //create order and allocation

        let order = Promise.resolve(observation.get("securityOrderMain"));

        if (Ember.isNone(observation.get("securityOrderMain.id"))) {
          // create and link order
          order = this.store.createRecord("securityOrderMain", {}).save(); // TODO set all other properties
        } //add allocation


        order.then(existingOrNewOrder => {
          // set on observation
          observation.set("securityOrderMain", existingOrNewOrder); // TODO

          const allocation = this.store.createRecord("securityOrderAlloc", {});
          allocation.set("securityOrderMain", existingOrNewOrder);
          allocation.set("accountMaster", accountMaster);
          return observation.save().then(() => {
            return allocation.save().then(() => {
              this.toggleProperty("showAddAllocationDialog");
            });
          });
        });
      },

      approveRequest() {
        this.set("currentObservation.approved", (0, _moment.default)());
        this.set("currentObservation.approvedBy", this.get("currentUser.user"));
        this.closeAddNewEntry();
        this.toggleProperty("showAddMessageDialog");
      },

      declineRequest() {
        this.set("currentObservation.declined", (0, _moment.default)());
        this.set("currentObservation.approvedBy", this.currentUser);
        this.closeAddNewEntry();
        this.toggleProperty("showAddMessageDialog");
      },

      saveAllocations() {
        this.get("currentObservation.securityOrderMain.securityOrderAllocs").forEach(item => item.save());
      },

      toggleAddAllocationDialog() {
        this.toggleProperty("showAddAllocationDialog");
      },

      toggleAddMessageDialog() {
        this.toggleProperty("showAddMessageDialog");
      },

      discardMessage() {
        this.currentObservation.rollbackAttributes();
        this.toggleProperty("showAddMessageDialog");
      },

      saveMessage() {
        this.currentObservation.save().then(() => {
          this.toggleProperty("showAddMessageDialog");
        });
      },

      search(modelName, query) {
        if (query.length > 2 || modelName === "issuerStatic") // TODO make search fields customizable
          // TODO make custom backend routes which only return the models and not all relations
          return this.searchService.search(modelName, query);else return;
      },

      fetchMore() {
        if (this.get("model.impactObservations.length") % 10 === 0) {
          const variables = {
            limit: 10,
            offset: this.get("model.impactObservations.length")
          };
          this.apollo.query({
            query: _impactObservations.default,
            variables
          }).then(result => {
            this.get("model.impactObservations").pushObjects(result.impactObservations);
          });
        }
      }

    }
  });

  _exports.default = _default;
});