define("atcm-client/controllers/client/marketanalyses/managedfunds/holdings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true
      }, {
        displayName: "Managed Fund",
        displayField: "managedFund",
        disabled: true
      }, {
        displayName: "Security",
        displayField: "description",
        disabled: true
      }, {
        displayName: "Book value Security Currencyy",
        displayField: "bookValueSecurityCurrency",
        disabled: true
      }, {
        displayName: "Book value Account Currency",
        displayField: "bookValueAccountCurrency",
        disabled: true
      }, {
        displayName: "Market Value Security Currency",
        displayField: "marketValueSecurityCurrency",
        disabled: true
      }, {
        displayName: "Market Value Account Currency",
        displayField: "marketValueAccountCurrency",
        disabled: true
      }], this.tableFields = [{
        name: "Security",
        valuePath: "description"
      }, {
        name: "Currency",
        valuePath: "currency.isoCode"
      }, {
        name: "Book Value Security Currency",
        valuePath: "bookValueSecurityCurrency"
      }, {
        name: "Market Value Security Currency",
        valuePath: "marketValueSecurityCurrency"
      }, {
        name: "Reporting date",
        valuePath: "reportingDate"
      }, {
        name: "Document",
        valuePath: "document"
      }];
    },

    title: Ember.computed("model.descriptionShort", function () {
      return "Fund holdings of " + this.model.descriptionShort;
    })
  });

  _exports.default = _default;
});