define("atcm-client/services/current-user", ["exports", "graphql-tag"], function (_exports, _graphqlTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const user = (0, _graphqlTag.default)`
	query getUserById($userId: Int) {
		users(where: { id: { _eq: $userId } }) {
			id
			email
			gender
			name
			organisation {
				id
				description
				descriptionShort
			}
		}
	}
`;

  var _default = Ember.Service.extend({
    session: Ember.inject.service("session"),
    apollo: Ember.inject.service(),

    load() {
      const userId = this.get("session.data.authenticated.user-id");

      if (!Ember.isEmpty(userId)) {
        return this.apollo.query({
          query: user,
          variables: {
            userId
          }
        }).then(user => {
          this.set("user", user);
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }

  });

  _exports.default = _default;
});