define("atcm-client/templates/client/models/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SbJVY+8z",
    "block": "{\"symbols\":[],\"statements\":[[8,\"gql-table\",[],[[\"@dataQuery\",\"@title\",\"@type\",\"@editModel\",\"@addLink\",\"@addLabel\",\"@fields\",\"@dataVariables\"],[[34,0],\"Investment models\",\"investmentModels\",[30,[36,1],[[32,0],\"editModel\"],null],\"client.models.entry\",\"Investmentmodel entry\",[34,2],[34,3]]],null]],\"hasEval\":false,\"upvars\":[\"investmentmodelsQuery\",\"action\",\"tableFields\",\"dataVariables\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/models/index.hbs"
    }
  });

  _exports.default = _default;
});