define("atcm-client/controllers/client/core/ratiogroupsubs/index", ["exports", "atcm-client/gql/queries/ratioGroupSubs"], function (_exports, _ratioGroupSubs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Ratio Group Main",
        displayField: "ratioGroupMains",
        queryField: "ratioGroupMain",
        displayType: "typeahead",
        isTypeahead: true
      }];
      this.tableFields = [{
        displayName: "ESG Domain",
        displayField: "ratioGroupMain.ratioGroup.description",
        alignLeft: true
      }, {
        displayName: "ESG vendor",
        displayField: "ratioGroupMain.description"
      }, {
        displayName: "Datavendor",
        displayField: "ratioGroupMain.datavendorStatic.descriptionShort"
      }, {
        displayName: "Ratio focus",
        displayField: "description",
        flex: 15
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Ratio Group Main",
        selectionField: "ratioGroupMain",
        displayField: "description",
        model: "ratioGroupMains",
        required: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short description",
        displayField: "descriptionShort",
        required: true
      }];
    },

    fetchMore() {
      if (this.get("model.ratioGroupSubs.length") % 10 === 0) {
        const variables = {
          limit: 10,
          offset: this.get("model.ratioGroupSubs.length")
        };
        this.apollo.query({
          query: _ratioGroupSubs.default,
          variables
        }).then(result => {
          this.get("model.ratioGroupSubs").pushObjects(result.ratioGroupSubs);
        });
      }
    },

    actions: {
      showRatios: function (ratioGroupSub) {
        this.transitionToRoute("client.core.ratiogroupsubs.ratios", ratioGroupSub);
        return false;
      }
    }
  });

  _exports.default = _default;
});