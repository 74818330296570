define("atcm-client/routes/client/staticdata/issuerstatics/securities", ["exports", "atcm-client/routes/secure", "atcm-client/gql/queries/securities", "atcm-client/gql/queries/issuers"], function (_exports, _secure, _securities, _issuers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const variables = {
    orderBy: {
      description: "asc"
    }
  };

  var _default = _secure.default.extend({
    apollo: Ember.inject.service(),

    model() {
      const params = this.paramsFor("client.staticdata.issuerstatics.securities");
      const variables = {
        id: params.issuer_static_id
      };
      return this.apollo.query({
        query: _issuers.default,
        variables
      }).then(gqlresult => {
        return gqlresult["issuerStatics"] && gqlresult["issuerStatics"].length ? gqlresult["issuerStatics"][0] : null;
      }).catch(error => {
        throw error;
      });
    },

    setupController: function (controller) {
      this._super(...arguments);

      const params = this.paramsFor("client.staticdata.issuerstatics.securities");
      controller.set("presetFilters", {
        issuerStatic: params.issuer_static_id
      });
      controller.set("dataVariables", variables);
      controller.set("query", _securities.default);
      return;
    }
  });

  _exports.default = _default;
});