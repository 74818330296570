define("atcm-client/routes/client/models/edit", ["exports", "atcm-client/routes/secure", "atcm-client/routes/client/models/getInvestmentModel"], function (_exports, _secure, _getInvestmentModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    apollo: Ember.inject.service(),
    model: function (params) {
      return this.apollo.query({
        query: _getInvestmentModel.default,
        variables: {
          id: params.investment_model_id
        }
      }).then(gqlResult => {
        return gqlResult["investmentModels"][0];
      });
    }
  });

  _exports.default = _default;
});