define("atcm-client/components/paper-data-table-head", ["exports", "paper-data-table/components/paper-data-table-head"], function (_exports, _paperDataTableHead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _paperDataTableHead.default;
    }
  });
});