define("atcm-client/controllers/client/staticdata/issuerstatics/securities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.include = ["currency", "country", "exchangeStatic"];
      this.tableFields = [{
        displayName: "Asset Class",
        displayField: "instrumentType.assetClass.descriptionShort",
        flex: 15,
        alignLeft: true
      }, {
        displayName: "Instrument Type",
        displayField: "instrumentType.descriptionShort"
      }, {
        displayName: "Description",
        displayField: "description"
      }, {
        displayName: "ISIN Code",
        displayField: "isinCode"
      }, {
        displayName: "Cusip",
        displayField: "cusipCode"
      }, {
        displayName: "Bloomberg Code",
        displayField: "tickerCode"
      }, {
        displayName: "Currency Listed",
        displayField: "currency.isoCode"
      }, {
        displayName: "Country Listed",
        displayField: "country.isoThree"
      }, {
        displayName: "Exchange Listed",
        displayField: "exchangeStatic.descriptionShort"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true
      }, {
        displayName: "Instrument type",
        selectionField: "instrumentType",
        displayField: "description",
        model: "instrumentTypes",
        required: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "ISIN Code",
        displayField: "isinCode"
      }, {
        displayName: "Reuters Code",
        displayField: "ricCode"
      }, {
        displayName: "FIGI Code",
        displayField: "figiCode"
      }, {
        displayName: "Internal Code",
        displayField: "internalCode"
      }, {
        displayName: "Face Value",
        displayField: "faceValue"
      }, {
        displayName: "Currency",
        selectionField: "currency",
        displayField: "isoCode",
        model: "currencies",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Country",
        selectionField: "country",
        displayField: "description",
        model: "countries",
        queryFields: ["description", "isoThree", "isoTwo"],
        required: true
      }, {
        displayName: "Issuer",
        selectionField: "issuerStatic",
        displayField: "description",
        model: "issuerStatics",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Exchange",
        selectionField: "exchangeStatic",
        displayField: "description",
        model: "exchangeStatics",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Quotation Type",
        selectionField: "quotationType",
        displayField: "description",
        model: "quotationTypes",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Income Type",
        selectionField: "incomeType",
        displayField: "description",
        model: "incomeTypes",
        queryFields: ["description"],
        required: true
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Currency",
        displayField: "currencies",
        queryField: "currency",
        displayType: "typeahead",
        queryFields: ["description", "isoCode"],
        isTypeahead: true
      }, {
        displayName: "Country",
        displayField: "countries",
        queryField: "country",
        displayType: "typeahead",
        queryFields: ["description", "isoTwo", "isoThree"],
        isTypeahead: true
      }, {
        displayName: "Exchange",
        displayField: "exchangeStatics",
        queryField: "exchangeStatic",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort", "marketIdCode"],
        isTypeahead: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Securities issued by issuer " + this.model.description;
    })
  });

  _exports.default = _default;
});