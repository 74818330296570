define("atcm-client/models/assessment-sub-group", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceString: Ember.computed("description", "descriptionShort", function () {
      return this.description + " - (" + this.descriptionShort + ")";
    })
  });

  _exports.default = _default;
});