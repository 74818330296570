define("atcm-client/controllers/client/staticdata/intereststatics/entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.formFields = [{
        displayName: "Search",
        displayField: ["description", "descriptionShort", "internalCode", "tickerCode", "ricCode", "isinCode"],
        displayType: "text",
        isText: true
      }, {
        displayName: "Currency",
        displayField: "currencies",
        queryField: "interestStatic.currency",
        displayType: "typeahead",
        queryFields: ["description", "isoCode"],
        isTypeahead: true
      }, {
        displayName: "Tenor",
        displayField: "tenors",
        queryField: "interestStatic.tenor",
        displayType: "typeahead",
        queryFields: ["description", "tenorCode"],
        isTypeahead: true
      }];
    },

    referenceRatePlaceholder: Ember.computed("referenceRate", function () {
      return Ember.isNone(this.get("referenceRate.id")) ? "Search for an reference rate (curve)..." : this.get("referenceRate.niceString");
    }),
    actions: {
      save: function () {
        const self = this;
        this.content.save().then(() => {
          self.transitionToRoute("staticdata.referencerates");
        });
      },
      rollback: function () {
        const self = this;
        this.content.rollbackAttributes();
        self.transitionToRoute("staticdata.referencerates");
      }
    }
  });

  _exports.default = _default;
});