define("atcm-client/templates/client/staticdata/issuerstatics/impacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kv0bHhL+",
    "block": "{\"symbols\":[],\"statements\":[[8,\"gql-table\",[],[[\"@dataQuery\",\"@dataVariables\",\"@title\",\"@type\",\"@fields\",\"@filters\",\"@presetFilters\",\"@formFields\",\"@back\"],[[34,0],[34,1],[34,2],\"companyImpacts\",[34,3],[34,4],[34,5],[34,6],\"client.staticdata.issuerstatics.index\"]],null]],\"hasEval\":false,\"upvars\":[\"query\",\"dataVariables\",\"title\",\"tableFields\",\"tableFilters\",\"presetFilters\",\"formFields\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/staticdata/issuerstatics/impacts.hbs"
    }
  });

  _exports.default = _default;
});