define("atcm-client/routes/client/profile", ["exports", "atcm-client/routes/secure"], function (_exports, _secure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    session: Ember.inject.service("session"),
    model: function () {
      const profile = this.get("session.data.authenticated.profile");
      return this.store.query("user", {
        filter: {
          email: profile.email
        }
      }).then(users => {
        if (users.get("length") === 1) {
          return users.objectAt(0);
        }
      });
    },
    afterModel: function () {
      const controller = this.controllerFor("client.profile");
      controller.set("profile", this.get("session.data.authenticated.profile"));
      controller.set("roles", this.get("session.data.roles"));
      controller.set("tenants", this.get("session.data.tenants"));
      controller.set("languages", this.store.findAll("language"));
    }
  });

  _exports.default = _default;
});