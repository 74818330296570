define("atcm-client/controllers/client/compliance/compliancerules/edit", ["exports", "atcm-client/enums/severities", "atcm-client/enums/reasons", "ember-inflector"], function (_exports, _severities, _reasons, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    searchService: Ember.inject.service("search"),
    severities: _severities.default,
    reasons: _reasons.default,
    newRuleValueObject: null,
    newRuleFactObject: null,
    appendModal: false,
    newRuleValue: null,
    complianceValueQuery: null,

    init() {
      this._super(...arguments);

      this.complianceFactQueryFields = ["description"];
      this.complianceValueQueryFields = ["description"];
      this.operands = ["EQUAL", "NOTEQUAL", "GREATERTHEN", "SMALLERTHEN", "GREATEREQUAL", "SMALLEREQUAL"];
      this.data = [];
      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true,
        flex: 30
      }, {
        displayName: "Fact",
        displayField: "complianceFact.niceString",
        alignLeft: true,
        flex: 20
      }, {
        displayName: "Operand",
        displayField: "operand",
        alignLeft: true,
        flex: 15
      }, {
        displayName: "Value",
        displayField: "computedValue.niceString",
        alignLeft: true,
        flex: 20
      }, {
        displayName: "Priority",
        displayField: "priority",
        alignLeft: true,
        flex: 15
      }];
    },

    _setNewRuleFact: Ember.observer("newRuleFactObject.content", function () {
      if (!Ember.isNone(this.newRuleValue)) {
        if (this.get("newRuleValue.complianceFact.id") !== this.get("newRuleFactObject.id")) {
          this.set("newRuleValue.complianceFact", this.newRuleFactObject);
        }
      }
    }),
    _setNewRuleValue: Ember.observer("newRuleValueObject.id", function () {
      if (!Ember.isNone(this.newRuleValue)) {
        this.set("newRuleValue.value", this.get("newRuleValueObject.id"));
      }
    }),
    _setData: Ember.observer("newRuleValue.complianceFact.query", function () {
      if (!Ember.isNone(this.get("newRuleValue.complianceFact.query"))) {
        this.set("newRuleFactObject", this.get("newRuleValue.complianceFact"));
        const self = this;

        if (!Ember.isNone(this.get("newRuleValue.value"))) {
          this.store.findRecord(this.get("newRuleValue.complianceFact.query"), this.get("newRuleValue.value")).then(obj => {
            self.set("newRuleValueObject", obj);
          });
        }

        this.set("complianceValueQuery", (0, _emberInflector.pluralize)(this.get("newRuleValue.complianceFact.query")));
      } else {
        return null;
      }
    }),
    _enricheRuleValues: Ember.observer("model.complianceRuleValues.@each.value", "model.complianceRuleValues.@each.complianceFact", function () {
      const self = this;
      this.get("model.complianceRuleValues").forEach(item => {
        if (item.get("value")) {
          item.get("complianceFact").then(fact => {
            if (!Ember.isEmpty(fact) && !Ember.isEmpty(fact.get("query"))) {
              self.get("store").findRecord(fact.get("query"), item.get("value")).then(value => {
                item.set("computedValue", value);
              });
            }
          });
        }
      });
    }),
    actions: {
      save() {
        this.model.save().then(() => {
          this.transitionToRoute("client.compliance.compliancerules");
        });
      },

      delete() {
        this.model.deleteRecord();
        this.model.save().then(() => {
          this.transitionToRoute("client.compliance.compliancerules");
        });
      },

      saveNewRuleValue() {
        const self = this;

        if (this.newRuleValue) {
          this.newRuleValue.save().then(() => {
            self.toggleProperty("appendModal");
            self.get("model").reload();
          }, error => {
            //rejection
            throw error;
          });
        }
      },

      cancelNewRuleValue() {
        this.newRuleValue.rollbackAttributes();
        this.toggleProperty("appendModal");
      },

      addRuleValue() {
        this.toggleProperty("appendModal");
        this.set("newRuleValueObject", null);
        this.set("newRuleValue", this.store.createRecord("complianceRuleValue", {
          complianceRule: this.model
        }));
      },

      editRuleValue(ruleValue) {
        this.toggleProperty("appendModal");
        this.set("newRuleValue", ruleValue);
      },

      rollback() {
        this.model.rollbackAttributes();
        this.transitionToRoute("client.compliance.compliancerules");
      },

      search(modelName, query) {
        // TODO make search fields customizable
        // TODO make custom backend routes which only return the models and not all relations
        return this.searchService.search(modelName, query);
      }

    }
  });

  _exports.default = _default;
});