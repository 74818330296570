define("atcm-client/components/simple-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["simple-table"],

    /* set this when fetching more */
    nextPage: null,
    lastPage: null,

    init() {
      this._super(...arguments);

      if (Ember.isNone(this.dataContent)) {
        this.dataContent = [];
      }

      if (Ember.isNone(this.newModels)) {
        this.newModels = [];
      }

      this.self = this;

      if (Ember.isNone(this.columns)) {
        this.columns = [0, 1, 2];
      }

      if (Ember.isNone(this.sortProperties)) {
        this.sortProperties = ["id"];
      }

      this.fetch(1);
    },

    router: Ember.inject.service(),
    // store: service(),
    searchService: Ember.inject.service("search"),
    addLabel: null,
    addLink: null,
    title: null,
    tableTitle: Ember.computed.alias("title"),
    fields: null,
    editRoute: null,
    type: null,
    formFields: null,
    originalContent: null,
    editContent: null,
    pagedContent: null,
    firstContent: null,
    include: null,
    splitIndex: 10000,
    showAutocompleteFilters: false,
    filterClass: null,
    _addFormFields: null,
    showHeader: Ember.computed("title", "filters.length", function () {
      return this.title || this.get("filters.length");
    }),
    //multiple actioncolumns
    actionColumns: Ember.computed("actionColumnName", "actionColumnAction", function () {
      const self = this;
      return [{
        name: self.get("actionColumnName"),
        action: self.get("actionColumnAction")
      }];
    }),
    actionColumnName: null,
    actionColumnAction: null,
    editAction: null,
    addAction: null,
    query: null,
    searching: false,
    sortAscending: true,
    canAdd: true,
    dataContentTop: Ember.computed("splitIndex", "dataContent.length", function () {
      if (Ember.isEmpty(this.splitIndex)) {
        return this.dataContent;
      } else {
        return this.dataContent.slice(0, this.splitIndex);
      }
    }),
    dataContentBottom: Ember.computed("splitIndex", "dataContent.length", function () {
      if (Ember.isEmpty(this.splitIndex)) {
        return [];
      } else {
        return this.dataContent.slice(this.splitIndex + 1);
      }
    }),
    showMoreTrigger: Ember.computed("lastPage", "nextPage", function () {
      return !Ember.isNone(this.nextPage) && parseInt(this.nextPage) > 1 && parseInt(this.nextPage) <= parseInt(this.lastPage);
    }),
    hasAutocompleteFilter: Ember.computed("filters.@each.isTypeahead", function () {
      let hasFilter = false;
      this.filters.forEach(filter => {
        if (filter.isTypeahead) {
          hasFilter = true;
        }
      });
      return hasFilter;
    }),

    fetch(page) {
      const searchQuery = this.searchValue;
      const query = {};

      if (this.sortAscending) {
        query.sort = this.sortProperties.objectAt(0);
      } else {
        query.sort = "-" + this.sortProperties.objectAt(0);
      }

      if (this.include) {
        query.include = this.include;
      }

      if (!Ember.isNone(searchQuery)) {
        query.search = {
          description: searchQuery
        };
      }

      query.filter = {};

      if (!Ember.isNone(this.filters) && this.get("filters.length") > 0) {
        let hasActiveFilter = false;
        this.filters.forEach(filter => {
          if ((filter.isTypeahead || filter.displayType === "none") && !Ember.isNone(filter.selected)) {
            query.filter[filter.queryField + "Id"] = filter.selected.get("id");
            hasActiveFilter = true;
          }
        });

        if (hasActiveFilter) {
          this.set("filterClass", "active-filter");
        } else {
          this.set("filterClass", null);
        }
      }

      if (!Ember.isNone(this.presetFilters)) {
        Object.assign(query.filter, this.presetFilters);
      }

      if (!Ember.isNone(page)) {
        query.page = {
          number: page
        };
      }

      this.store.query(this.type, query).then(rows => {
        this.set("nextPage", rows.get("meta.links.next") && rows.get("meta.links.next").match(/\d+$/) && rows.get("meta.links.next").match(/\d+$/).length > 0 ? rows.get("meta.links.next").match(/\d+$/)[0] : 1);
        this.set("lastPage", rows.get("meta.links.last") && rows.get("meta.links.last").match(/\d+$/) && rows.get("meta.links.last").match(/\d+$/).length > 0 ? rows.get("meta.links.last").match(/\d+$/)[0] : 1);

        if (!Ember.isNone(page)) {
          this.dataContent.pushObjects(rows.toArray());
        } else {
          this.set("dataContent", rows.toArray());
        }
      });
    },

    fixAutoCompleters() {
      this.formFields.forEach(formField => {
        if (!Ember.isNone(formField.model) || !Ember.isNone(formField.data)) {
          Ember.set(formField, "selected", this.get("editContent." + formField.selectionField));
        }
      });
    },

    actions: {
      closeDialog() {
        this.editContent.deleteRecord();
        this.set("editContent", null);
      },

      fetchMore() {
        const nextPage = this.nextPage;

        if (nextPage) {
          this.fetch(nextPage);
        }
      },

      textSearch(event) {
        // search on enter
        if (event.keyCode === 13) {
          this.fetch();
        }
      },

      search(modelName, query) {
        // TODO make search fields customizable
        // TODO make custom backend routes which only return the models and not all relations
        return this.searchService.search(modelName, query);
      },

      add() {
        if (this.addAction) {
          this.sendAction("addAction");
        } else {
          this.set("splitIndex", 10000); //set editContent for new model

          this.set("editContent", this.store.createRecord(this.type));
          this.fixAutoCompleters();
          this.set("canAdd", false);
        }
      },

      editModel(index, row) {
        if (this.editAction) {
          this.sendAction("editAction", row);
        } else {
          this.sendAction("editModel", index, row);
        }
      },

      back() {
        this.router.transitionTo(this.back);
      },

      actionColumnAction(row, action) {
        this.sendAction("actionColumnAction", row, action);
      },

      sort(property) {
        //once we sort, clear the newmodels, we go the server anyhow
        this.set("newModels", []);
        const currentProperties = this.sortProperties;

        if (currentProperties.length > 0 && Ember.isEqual(currentProperties.objectAt(0), property)) {
          //reverse sort order
          this.toggleProperty("sortAscending");
        } else {
          //change sort property, set sortAscending: true as default
          this.set("sortProperties", [property]);
          this.set("sortAscending", true);
        }

        this.fetch();
        return false;
      },

      splitAndEdit(index, row) {
        //show index in card between two tables
        this.set("splitIndex", index);

        if (this.editContent) {
          Ember.run(this, function () {
            this.editContent.rollbackAttributes();
            this.set("editContent", null);
          });
        }

        this.set("editContent", row);
        this.set("canAdd", false);
        this.fixAutoCompleters();
        return false;
      },

      saveRow() {
        //set the new selected fields back to the ember object
        this.formFields.forEach(formField => {
          this.set("editContent." + formField.selectionField, formField.selected);
        });
        const isNew = this.get("editContent.isNew");
        return this.editContent.save().then(savedModel => {
          this.set("editContent", null);
          this.set("splitIndex", 10000);

          if (isNew) {
            this.newModels.pushObject(savedModel);
          }

          this.set("canAdd", true);
          return false;
        });
      },

      discardRow() {
        this.editContent.rollbackAttributes();
        this.set("editContent", null);
        this.set("splitIndex", 10000);
        this.set("canAdd", true);
        return false;
      },

      filter() {
        this.fetch();
      }

    }
  });

  _exports.default = _default;
});