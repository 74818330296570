define("atcm-client/controllers/client/staticdata/securitystatics/securityratings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.formFields = [{
        displayName: "Date",
        displayField: "createdAt",
        alignLeft: true
      }, {
        displayName: "Rating",
        displayField: "ratingStatic.descriptionShort"
      }];
      this.tableFilters = [{
        displayName: "Security Static",
        displayField: "securityStatic",
        queryField: "securityStatic",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort", "marketIdCode", "figiCode"],
        isTypeahead: true
      }, {
        displayName: "Rating",
        displayField: "ratingStatic",
        queryField: "ratingStatic",
        displayType: "typeahead",
        queryFields: ["description"],
        isTypeahead: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Security Ratings of " + this.get("model.descriptionShort");
    })
  });

  _exports.default = _default;
});