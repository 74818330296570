define("atcm-client/services/session", ["exports", "ember-simple-auth/services/session"], function (_exports, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _session.default.extend({
    hasRole(role) {
      return this.getCurrentTenantRoles().includes(role);
    },

    getCurrentTenantRoles() {
      return this.get("data.roles").filter(role => {
        return role.indexOf(this.get("data.tenant")) > -1;
      }).map(role => {
        return role.split(":")[1];
      });
    },

    isAdministrator: Ember.computed("data.roles", function () {
      return this.hasRole("Administrator");
    }),
    isPM: Ember.computed("data.roles", function () {
      return this.hasRole("PM");
    }),
    isRI: Ember.computed("data.roles", function () {
      return this.hasRole("RI");
    })
  });

  _exports.default = _default;
});