define("atcm-client/components/paper-switch-fusion", ["exports", "ember-paper/components/paper-switch"], function (_exports, _paperSwitch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperSwitch.default.extend({
    object: null,
    displayField: null,

    /* manual binding due to lack of binding knowledge ;-) */
    _propagateValueChange: Ember.observer("object", "checked", "displayField", function () {
      if (!Ember.isEmpty(this.object) && !Ember.isEmpty(this.displayField)) {
        this.set("object." + this.displayField, this.checked);
      }
    }),

    init() {
      this._super(...arguments);

      this.set("checked", this.get("object." + this.displayField));
    }

  });

  _exports.default = _default;
});