define("atcm-client/models/issuer-impact-performance", ["exports", "atcm-client/models/base-model", "moment"], function (_exports, _baseModel, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceCreationDate: Ember.computed("createdAt", function () {
      if (this.createdAt) {
        return (0, _moment.default)(this.createdAt).format("DD-MM-YYYY");
      }
    })
  });

  _exports.default = _default;
});