define("atcm-client/services/search", ["exports", "atcm-client/gql/search/currencies", "atcm-client/gql/search/gicsSectors", "atcm-client/gql/search/gicsIndustrySubs", "atcm-client/gql/search/countries", "atcm-client/gql/search/indexStatics", "atcm-client/gql/search/custodianStatics", "atcm-client/gql/search/securityStatics", "atcm-client/gql/search/accountMasters", "atcm-client/gql/search/exchangeStatics", "atcm-client/gql/search/quotationTypes", "atcm-client/gql/search/incomeTypes", "atcm-client/gql/search/issuerStatics", "atcm-client/gql/search/instrumentTypes", "atcm-client/gql/search/assetManagers", "atcm-client/gql/search/managedFunds"], function (_exports, _currencies, _gicsSectors, _gicsIndustrySubs, _countries, _indexStatics, _custodianStatics, _securityStatics, _accountMasters, _exchangeStatics, _quotationTypes, _incomeTypes, _issuerStatics, _instrumentTypes, _assetManagers, _managedFunds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const queries = {
    ibor_assetManagers: _assetManagers.default,
    currencies: _currencies.default,
    gicsSectors: _gicsSectors.default,
    countries: _countries.default,
    indexStatics: _indexStatics.default,
    gicsIndustrySubs: _gicsIndustrySubs.default,
    custodianStatics: _custodianStatics.default,
    securityStatics: _securityStatics.default,
    accountMasters: _accountMasters.default,
    exchangeStatics: _exchangeStatics.default,
    quotationTypes: _quotationTypes.default,
    incomeTypes: _incomeTypes.default,
    issuerStatics: _issuerStatics.default,
    instrumentTypes: _instrumentTypes.default,
    ibor_managedFunds: _managedFunds.default
  };

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    apollo: Ember.inject.service(),

    search(modelName, searchText, additionalSearchParameters = {}) {
      const query = queries[modelName];

      if (!query) {
        throw new Error("There was no gql search query for " + modelName);
      }

      const variables = additionalSearchParameters;
      additionalSearchParameters.search = `%${searchText}%`;
      return this.apollo.query({
        query,
        variables
      }, modelName).then(gqlresult => {
        return gqlresult;
      }).catch(error => {
        throw error;
      });
    }

  });

  _exports.default = _default;
});