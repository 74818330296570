define("atcm-client/routes/tenants", ["exports", "atcm-client/routes/secure"], function (_exports, _secure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    session: Ember.inject.service("session"),

    model() {
      const profile = this.get("session.data.authenticated.profile");
      const roles = profile["https://api.impactalytics.io/roles"];
      const tenants = roles.map(role => {
        return role.split(":")[0];
      }).uniq();
      const currentData = this.get("session.data");
      currentData.tenants = tenants;
      currentData.roles = roles;
      this.get("session.store").persist(currentData);
      return tenants;
    },

    afterModel(model) {
      const tenant = this.get("session.data.tenant");

      if (model.get("length") === 1) {
        // redirect
        if (tenant === model.objectAt(0)) {
          this.transitionTo("client.analyses");
        } else {// TODO error
        }
      } else if (model.get("length") > 1) {
        if (model.includes(tenant)) {
          this.transitionTo("client.analyses");
        } // else choose tenant in interface

      }
    }

  });

  _exports.default = _default;
});