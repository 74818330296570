define("atcm-client/routes/client", ["exports", "atcm-client/routes/secure"], function (_exports, _secure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    afterModel: function () {
      // var self = this;
      // return this.store.query('chat').then(function (chats) {
      // 	var map = Ember.RSVP.all(chats.map(function(chat) {
      // 		return chat.get('createdBy').then(function(by) {
      // 			var chats = chat.get('with').then(function(w) {
      // 				var who;
      // 				if (by.id === self.controllerFor('index').get('user').id) {
      // 					who = w;
      // 					chat.set('mine', false);
      // 				} else {
      // 					who = by;
      // 					chat.set('mine', true);
      // 				}
      // 				chat.set('otherUser', who);
      // 				return chat;
      // 			});
      // 			return chats;
      // 		});
      // 	}));
      //   map.then(function(chats) {
      //     self.controllerFor('client').set('chats', chats);
      //   });
      // 	return map;
      // });
      return;
    }
  });

  _exports.default = _default;
});