define("atcm-client/controllers/client/staticdata/securityratings/entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFilters = [{
        displayName: "Security Static",
        displayField: "securityStatic",
        queryField: "securityStatic",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort", "marketIdCode", "figiCode"],
        isTypeahead: true
      }, {
        displayName: "Rating",
        displayField: "ratingStatic",
        queryField: "ratingStatic",
        displayType: "typeahead",
        queryFields: ["description"],
        isTypeahead: true
      }];
    },

    securityStaticPlaceholder: Ember.computed("securityStatic", function () {
      return Ember.isNone(this.get("securityStatic.id")) ? "Search for an security static..." : this.get("securityStatic.niceString");
    }),
    ratingStaticPlaceholder: Ember.computed("ratingStatic", function () {
      return Ember.isNone(this.get("ratingStatic.id")) ? "Search for a rating static..." : this.get("ratingStatic.niceString");
    }),
    actions: {
      save: function () {
        const self = this;
        this.model.save().then(() => {
          self.transitionToRoute("staticdata.securitystatics");
        });
      },
      rollback: function () {
        const self = this;
        this.model.rollbackAttributes();
        self.transitionToRoute("staticdata.securitystatics");
      }
    }
  });

  _exports.default = _default;
});