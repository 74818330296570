define("atcm-client/models/investment-model-order", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    hasSecurityOrderMains: Ember.computed("securityOrderMains.length", function () {
      return this.get("securityOrderMains.isLoaded") && this.get("securityOrderMains.length") > 0;
    }),
    isTraded: Ember.computed("statusTransaction.id", function () {
      return this.get("statusTransaction.id") === 8;
    }),
    canBeTraded: Ember.computed("isTraded", "compliantSecurityOrderMains.length", "pendingSecurityOrderMains.length", function () {
      return !this.isTraded && this.get("compliantSecurityOrderMains.length") > 0 && this.get("pendingSecurityOrderMains.length") === 0;
    }),
    compliantSecurityOrderMains: Ember.computed.filterBy("securityOrderMains", "isCompliant", true),
    incompliantSecurityOrderMains: Ember.computed.filterBy("securityOrderMains", "isCompliant", false),
    pendingSecurityOrderMains: Ember.computed.filterBy("securityOrderMains", "isCompliant", null),
    allCompliant: Ember.computed("pendingSecurityOrderMains", "incompliantSecurityOrderMains", function () {
      return this.get("pendingSecurityOrderMains.length") === 0 && this.get("incompliantSecurityOrderMains.length") === 0;
    })
  });

  _exports.default = _default;
});