define("atcm-client/controllers/client/staticdata/holidaycalendarsubs/entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFilters = [{
        displayName: "Search",
        displayField: ["description", "descriptionShort"],
        displayType: "text",
        isText: true
      }];
    },

    holidayCalendarMainPlaceholder: Ember.computed("holidayCalendarMain", function () {
      return Ember.isNone(this.get("holidayCalendarMain.id")) ? "Search for a calendar..." : this.get("holidayCalendarMain.niceString");
    }),
    actions: {
      save: function () {
        const self = this;
        this.content.save().then(() => {
          self.transitionToRoute("staticdata.holidaycalendarmains");
        });
      },
      rollback: function () {
        const self = this;
        this.content.rollbackAttributes();
        self.transitionToRoute("staticdata.holidaycalendarmains");
      }
    }
  });

  _exports.default = _default;
});