define("atcm-client/controllers/client/core/impactobjectivesubs/indicators", ["exports", "atcm-client/enums/indicator-types", "atcm-client/enums/reference-points", "atcm-client/enums/metric-types", "atcm-client/enums/impact-value-chains", "atcm-client/enums/maturity-metrics", "atcm-client/enums/meassurement-units"], function (_exports, _indicatorTypes, _referencePoints, _metricTypes, _impactValueChains, _maturityMetrics, _meassurementUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true
      }, {
        displayName: "Short description",
        displayField: "descriptionShort"
      }, {
        displayName: "Indicator Type",
        displayField: "indicatorType"
      }, {
        displayName: "Reference Point",
        displayField: "referencePoint"
      }, {
        displayName: "Metric Type",
        displayField: "metricType"
      }, {
        displayName: "Impact Value Chain",
        displayField: "impactValueChain"
      }, {
        displayName: "Maturity Metric",
        displayField: "maturityMetric"
      }, {
        displayName: "Meassurement Unit",
        displayField: "meassurementUnit"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short description",
        displayField: "descriptionShort",
        required: true
      }, {
        displayName: "Target",
        selectionField: "impactObjectiveSub",
        displayField: "description",
        model: "impactObjectiveSubs",
        queryFields: ["description", "descriptionShort"],
        required: true
      }, {
        displayName: "Maturity Metrics",
        selectionField: "maturityMetric",
        data: _maturityMetrics.default
      }, {
        displayName: "Impact Value Chain",
        selectionField: "impactValueChain",
        data: _impactValueChains.default
      }, {
        displayName: "Metric Types",
        selectionField: "metricType",
        data: _metricTypes.default
      }, {
        displayName: "Indicator Type",
        selectionField: "indicatorType",
        data: _indicatorTypes.default
      }, {
        displayName: "Reference Points",
        selectionField: "referencePoint",
        data: _referencePoints.default
      }, {
        displayName: "Meassurement Unit",
        selectionField: "meassurementUnit",
        data: _meassurementUnits.default
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Indicators of " + this.get("model.descriptionShort");
    })
  });

  _exports.default = _default;
});