define("atcm-client/routes/client/staticdata/equities/index", ["exports", "atcm-client/routes/secure"], function (_exports, _secure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    model: function () {
      return this.store.query("securityStatic", {
        filter: {
          assetClassId: 1
        }
      });
    }
  });

  _exports.default = _default;
});