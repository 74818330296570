define("atcm-client/controllers/client/models/edit", ["exports", "atcm-client/enums/focussus", "atcm-client/gql/mutations/insertInvestmentModelConstituent", "atcm-client/gql/mutations/updateInvestmentModelConstituent"], function (_exports, _focussus, _insertInvestmentModelConstituent, _updateInvestmentModelConstituent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    focussus: _focussus.default,
    total: 0,
    sunburstData: null,
    largestInvestmentInPercentage: 0,
    isNewConstituent: null,

    init() {
      this._super(...arguments);

      this.queryFields = ["securityStatic.description"];
      this.activeConstituent = null;
    },

    totalTooHigh: Ember.computed("total", function () {
      return parseFloat(this.total) > 100.001;
    }),
    searchedConstituent: null,
    // eslint-disable-next-line ember/no-observers
    _searchedConstituentDidChange: Ember.observer("searchedConstituent", function () {
      //check if it is in the array of modelConstituents already and return that
      const indexConstituent = this.searchedConstituent;

      if (indexConstituent) {
        let foundConstituent = false;

        if (this.model.investmentModelConstituents.length > 0) {
          for (const constituent of this.model.investmentModelConstituents) {
            if (constituent.securityStatic.id === indexConstituent.securityStatic.id) {
              this.set("isNewConstituent", false);
              this.set("activeConstituent", constituent);
              foundConstituent = true;
            }
          }
        } // else create new constituent for this model based on the index


        if (!foundConstituent) {
          this.set("isNewConstituent", true);
          const investmentModelConstituent = {
            targetWeight: indexConstituent.weightPercentage,
            securityStatic: indexConstituent.securityStatic,
            targetMaxTreshold: 25,
            targetMinTreshold: 25
          };
          this.set("activeConstituent", investmentModelConstituent);
          this.set("searchedConstituent", null);
        }
      } // else; do nothing on changes to null

    }),
    // eslint-disable-next-line ember/no-observers
    _setSunburst: Ember.observer("model.investmentModelConstituents.@each.targetWeight", function () {
      const hasIndexConstituent = this.model.indexStatic && this.model.indexStatic.indexConstituents && this.model.indexStatic.indexConstituents.length > 0;
      const constituents = hasIndexConstituent ? this.model.indexStatic.indexConstituents : this.model.investmentModelConstituents;
      const nodes = [],
            trbcEconomicSectors = {},
            businessSectors = {},
            industryGroups = {},
            industries = {},
            activities = {};
      let levelOne, levelTwo, levelThree, levelFour, levelOneCode, levelThreeCode;

      if (this.model.category === "GICS") {
        levelFour = "gicsSector";
        levelThree = "gicsIndustryGroup";
        levelTwo = "gicsIndustry";
        levelOne = "gicsIndustrySub";
        levelOneCode = "industrySubCode";
        levelThreeCode = "sectorCode";
      } else {
        levelFour = "trbcBusinessSector";
        levelThree = "trbcIndustryGroup";
        levelTwo = "trbcIndustry";
        levelOne = "trbcActivity";
        levelOneCode = "activityCode";
        levelThreeCode = "businessCode";
      }

      this.set("total", 0); // eslint-disable-next-line complexity

      constituents.forEach(indexOrModelConstituent => {
        let constituent = indexOrModelConstituent;
        let weightPercentage;

        if (hasIndexConstituent) {
          weightPercentage = parseFloat(constituent.weightPercentage);
        } else {
          weightPercentage = parseFloat(constituent.targetWeight);
        } // check for override in this model's investmentModelConstituents


        if (hasIndexConstituent) {
          for (const modelConstituent of this.model.investmentModelConstituents) {
            if (modelConstituent.securityStatic.id === constituent.securityStatic.id) {
              constituent = modelConstituent;
              weightPercentage = parseFloat(modelConstituent.targetWeight);
              break;
            }
          }
        }

        this.set("total", this.total + weightPercentage);
        const issuer = constituent.securityStatic.issuerStatic;
        const activity = issuer[levelOne];
        const trbcActivityDescription = activity ? activity.description : "Unknown activity";
        const activityCode = activity ? activity[levelOneCode] : "UNKNOWN";

        if (weightPercentage > this.largestInvestmentInPercentage) {
          this.largestInvestmentInPercentage = weightPercentage * 2;
        }

        const newSecurity = {
          id: constituent.securityStatic.id,
          securityStatic: constituent.securityStatic,
          name: constituent.securityStatic.description,
          size: weightPercentage,
          weightPercentage,
          belongsTo: activityCode
        };
        const industry = activity ? activity[levelTwo] : "Unknown industry";
        const industryCode = industry ? industry.industryCode : "UNKNOWN";

        if (activities.hasOwnProperty(activityCode)) {
          activities[activityCode].children.pushObject(newSecurity);
        } else {
          const newActivity = {
            id: activityCode,
            name: trbcActivityDescription,
            children: [newSecurity],
            belongsTo: industryCode,
            leaf: true
          };
          activities[activityCode] = newActivity;
          const trbcIndustryDescription = industry.description;
          const industryGroup = industry ? industry[levelThree] : "Unknown industry";
          const industryGroupCode = industryGroup ? industryGroup.industryGroupCode : "UNKNOWN";

          if (!industries.hasOwnProperty(industryCode)) {
            const newIndustry = {
              id: industryCode,
              name: trbcIndustryDescription,
              children: [],
              belongsTo: industryGroupCode
            };
            industries[industryCode] = newIndustry;
            nodes.push(newIndustry);
            const trbcIndustryGroupDescription = industryGroup ? industryGroup.description : "Unknown industry group";
            const businessSector = industryGroup ? industryGroup[levelFour] : "Unknown business sector";
            const businessCode = businessSector ? businessSector[levelThreeCode] : "UNKNOWN";

            if (!industryGroups.hasOwnProperty(industryGroupCode)) {
              const newIndustryGroup = {
                id: industryGroupCode,
                name: trbcIndustryGroupDescription,
                children: [],
                belongsTo: businessCode
              };
              industryGroups[industryGroupCode] = newIndustryGroup;
              nodes.push(newIndustryGroup);
              const trbcBusinessSectorDescription = businessSector ? businessSector.description : "Unknown business sector";
              const economicSector = businessSector.trbcEconomicSector || null;
              const sectorCode = economicSector ? economicSector.sectorCode : null;

              if (!businessSectors.hasOwnProperty(businessCode)) {
                const newBusinessSector = {
                  id: businessCode,
                  name: trbcBusinessSectorDescription,
                  children: [],
                  belongsTo: this.model.category === "TRBC" ? sectorCode : "root"
                };
                businessSectors[businessCode] = newBusinessSector;
                nodes.push(newBusinessSector);

                if (this.model.category === "TRBC") {
                  const trbcEconomicSectorDescription = businessSector.trbcEconomicSector.description; // eslint-disable-next-line max-depth

                  if (!trbcEconomicSectors.hasOwnProperty(sectorCode)) {
                    const newEconomicSector = {
                      id: sectorCode,
                      name: trbcEconomicSectorDescription,
                      children: [],
                      belongsTo: "root"
                    };
                    trbcEconomicSectors[sectorCode] = newEconomicSector;
                    nodes.push(newEconomicSector);
                  }
                }
              }
            }
          }
        }
      }); //add all activities, with all children, to the nodes list

      const trbcActivityKeys = Object.keys(activities);
      trbcActivityKeys.forEach(key => {
        nodes.pushObject(activities[key]);
      });
      this.set("sunburstData", this._generateSunburstTreeData(nodes));
    }),
    _generateSunburstTreeData: function (nodes) {
      const newSunburst = {
        name: this.get("model.description"),
        children: this._generateChildArray(nodes, "root", [], 0)
      };
      return newSunburst;
    },
    _generateChildArray: function (nodes, belongsTo, fetched, depth) {
      const self = this;

      if (depth > 10) {
        return;
      }

      const children = [];
      nodes.forEach(node => {
        if (node.belongsTo === belongsTo) {
          if (!node.leaf) {
            const nodesNotOnParentLevel = nodes.filter(item => {
              //TODO filter more?
              return item.belongsTo !== belongsTo;
            });
            node.children = self._generateChildArray(nodesNotOnParentLevel, node.id, fetched, depth + 1);
          }

          children.push(node);
        }
      });
      return children;
    },
    actions: {
      save: function () {// this.get("model")
        // 	.save()
        // 	.then(() => {});
      },
      cancel: function () {// const self = this;
        // this.get("model").rollbackAttributes();
        // self.transitionToRoute("ordermanagement.investmentmodels");
      },
      discardActiveConstituent: function () {
        this.set("activeConstituent", null);
      },
      saveActiveConstituent: function (constituent) {
        if (this.isNewConstituent) this.model.investmentModelConstituents.pushObject(constituent);
        this.set("isNewConstituent", null);
        this.set("activeConstituent", null);
        const model = this.model; // TODO insert or update!

        const mutationData = {
          targetMaxTreshold: constituent.targetMaxTreshold,
          targetMinTreshold: constituent.targetMinTreshold,
          targetWeight: constituent.targetWeight,
          investmentModelId: model.id,
          securityStaticId: constituent.securityStatic.id
        };

        if (constituent.hasOwnProperty("id")) {
          return this.apollo.mutate({
            mutation: _updateInvestmentModelConstituent.default,
            variables: {
              id: constituent.id,
              changes: mutationData
            }
          }, "update_investmentModelConstituents").then(() => {
            this.paperToaster.show("Constituent updated.");
          }).catch(error => {
            console.error(error);
          });
        } else {
          return this.apollo.mutate({
            mutation: _insertInvestmentModelConstituent.default,
            variables: {
              objects: [mutationData]
            }
          }, "insert_investmentModelConstituents").then(gqlResult => {
            constituent.id = gqlResult.returning[0].id;
            this.paperToaster.show("Constituent added.");
          }).catch(error => {
            console.error(error);
          });
        }
      },
      addAccountMaster: function () {
        this.set("addingAccountMaster", true);
      },

      editModelConstituent(modelConstituent) {
        this.set("activeConstituent", modelConstituent);
      },

      // unlinkAccountMaster: function(acm) {
      // 	this.get("model.accountMasters").removeObject(acm);
      // },
      // generateOrders: function() {
      // 	this.transitionToRoute("ordermanagement.orders.entry");
      // },
      showConstituent() {//TODO
      },

      // depending on the type of model we start from the beginning
      editModel() {
        if (this.model.focus === "Country Fundamentals") {
          this.transitionToRoute("client.models.country-fundamental", this.model);
        }
      }

    }
  });

  _exports.default = _default;
});