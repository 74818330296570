define("atcm-client/controllers/client/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    profile: null,
    genders: null,
    languages: null,
    paperToaster: Ember.inject.service("paperToaster"),

    init() {
      this._super(...arguments);

      this.set("genders", ["m", "f", "other"]);
    },

    actions: {
      saveUser() {
        this.model.save().then(() => {
          this.paperToaster.show("User saved.");
        });
      }

    }
  });

  _exports.default = _default;
});