define("atcm-client/controllers/client/marketanalyses/assetmanagers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Currency",
        displayField: "currencies",
        queryField: "currency",
        displayType: "typeahead",
        queryFields: ["description", "isoCode"],
        isTypeahead: true
      }, {
        displayName: "Country",
        displayField: "countries",
        queryField: "listedCountry",
        displayType: "typeahead",
        queryFields: ["description", "isoTwo", "isoThree"],
        isTypeahead: true
      }];
      this.tableFields = [{
        name: "Description",
        valuePath: "description",
        alignLeft: true
      }, {
        name: "Description Short",
        valuePath: "descriptionShort"
      }, {
        name: "Legal Entity Identifier",
        valuePath: "legalEntityIdentifier"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short description",
        displayField: "descriptionShort",
        required: true
      }];
      this.actionColumns = [{
        name: "Managed Funds",
        action: "showManagedFunds"
      }];
    },

    actions: {
      processActionColumnAction: function (row, action) {
        this.send(action, row);
      },
      showManagedFunds: function (assetManager) {
        this.transitionToRoute("client.marketanalyses.assetmanagers.funds", assetManager);
        return false;
      }
    }
  });

  _exports.default = _default;
});