define("atcm-client/models/un-global-compact", ["exports", "atcm-client/models/base-model", "moment"], function (_exports, _baseModel, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceString: Ember.computed("description", "joined", function () {
      return this.description + " - " + this.joined;
    }),
    niceJoinedDate: Ember.computed("joined", function () {
      if (this.joined) {
        return (0, _moment.default)(this.joined).format("DD-MM-YYYY");
      }
    })
  });

  _exports.default = _default;
});