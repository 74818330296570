define("atcm-client/controllers/client/compliance/compliancerules/index", ["exports", "atcm-client/gql/queries/complianceRules"], function (_exports, _complianceRules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Origin",
        displayField: "reason",
        alignLeft: true,
        flex: 10
      }, {
        displayName: "Main Category",
        displayField: "complianceCategoryMain.description",
        alignLeft: true,
        flex: 5
      }, {
        displayName: "Sub category",
        displayField: "complianceCategorySub.description",
        alignLeft: true,
        flex: 10
      }, {
        displayName: "Basket",
        displayField: "complianceBasket.description",
        alignLeft: true,
        flex: 15
      }, {
        displayName: "Severity",
        displayField: "severity",
        alignLeft: true,
        flex: 10
      }, {
        displayName: "Full Description",
        displayField: "fullDescription",
        alignLeft: true,
        flex: 45
      }];
      this.tableFilters = [{
        displayName: "Search description",
        displayField: ["fullDescription", "severity"],
        displayType: "text",
        isText: true
      }, {
        displayName: "Compliance Basket",
        displayField: "complianceBaskets",
        queryField: "complianceBasket",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Compliance Category Main",
        displayField: "complianceCategoryMains",
        queryField: "complianceCategoryMain",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Compliance Category Sub",
        displayField: "complianceCategorySubs",
        queryField: "complianceCategorySub",
        displayType: "typeahead",
        isTypeahead: true
      }];
    },

    editRoute: null,
    actions: {
      editModel(index, row) {
        this.transitionToRoute("client.compliance.compliancerules.edit", row);
      },

      addAction() {
        this.transitionToRoute("client.compliance.compliancerules.entry");
      }

    }
  });

  _exports.default = _default;
});