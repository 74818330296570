define("atcm-client/models/account-master-fundamental", ["exports", "ember-data", "atcm-client/models/base-model"], function (_exports, _emberData, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    accountMaster: _emberData.default.belongsTo("account-master", {
      async: true
    }),
    ratio: _emberData.default.belongsTo("ratio", {
      async: true
    }),
    value: _emberData.default.attr("number"),
    // -1 /  1 / 0
    valueDate: _emberData.default.attr("date"),
    description: _emberData.default.attr("string"),
    //containing the background of the value provided.
    niceString: Ember.computed("accountMaster", "ratio", function () {
      return this.accountMaster + " - " + this.ratio;
    })
  });

  _exports.default = _default;
});