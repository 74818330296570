define("atcm-client/templates/client/core/impactobjectivesubs/indicators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XrEUcLCO",
    "block": "{\"symbols\":[],\"statements\":[[8,\"simple-table\",[],[[\"@content\",\"@title\",\"@type\",\"@fields\",\"@filters\",\"@presetFilters\",\"@formFields\",\"@back\"],[[34,0],[34,1],\"impactObjectiveIndicator\",[34,2],[34,3],[34,4],[34,5],\"client.core.impactobjectivesubs.index\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"indicators\",\"title\",\"tableFields\",\"tableFilters\",\"presetFilters\",\"formFields\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/core/impactobjectivesubs/indicators.hbs"
    }
  });

  _exports.default = _default;
});