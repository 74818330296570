define("atcm-client/routes/client/transactions/orders/edit", ["exports", "atcm-client/routes/secure"], function (_exports, _secure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    afterModel: function (model) {
      if (!this.controllerFor("ordermanagement.orders.entry").orderComplete(model)) {
        this.controllerFor("ordermanagement.orders.entry").set("typeOfEntry", "manual");
        this.controllerFor("ordermanagement.orders.entry").set("model", model);
        this.transitionTo("ordermanagement.orders.entry");
      }
    }
  });

  _exports.default = _default;
});