define("atcm-client/routes/client/marketanalyses/assetmanagers/funds", ["exports", "atcm-client/routes/secure", "atcm-client/gql/queries/managedFunds", "atcm-client/gql/queries/assetManagers"], function (_exports, _secure, _managedFunds, _assetManagers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    apollo: Ember.inject.service(),

    model() {
      const params = this.paramsFor("client.marketanalyses.assetmanagers.funds");
      const impactVariables = {
        id: params.issuer_impact_id
      };
      return this.apollo.query({
        query: _assetManagers.default,
        impactVariables
      }).then(gqlresult => {
        return gqlresult["assetManagers"] && gqlresult["assetManagers"].length ? gqlresult["assetManagers"][0] : null;
      }).catch(error => {
        throw error;
      });
    },

    setupController: function (controller) {
      const params = this.paramsFor("client.marketanalyses.assetmanagers.funds");
      const variables = {
        orderBy: {
          description: "asc"
        },
        assetManager: params.asset_manager_id
      };
      controller.set("dataVariables", variables);
      controller.set("query", _managedFunds.default);
    }
  });

  _exports.default = _default;
});