define("atcm-client/routes/client/transactions/securitytransactions/entry", ["exports", "atcm-client/routes/secure"], function (_exports, _secure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    model: function () {
      return this.store.createRecord("securityTransaction", {
        active: true
      });
    }
  });

  _exports.default = _default;
});