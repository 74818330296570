define("atcm-client/controllers/client/transactions/securitytransactions/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Account Master",
        displayField: "accountMaster.descriptionShort"
      }, {
        displayName: "Security",
        displayField: "securityStatic.ricCode"
      }, {
        displayName: "Event",
        displayField: "eventType.descriptionShort"
      }, {
        displayName: "Quantity",
        displayField: "transactionQuantity"
      }, {
        displayName: "Price (Acc Crry)",
        displayField: "unitPriceAccountMasterCurrency"
      }, {
        displayName: "Currency (Acc)",
        displayField: "accountMasterCurrency.isoCode"
      }, {
        displayName: "Gross Amount (Acc Crry)",
        displayField: "grossAmountAccountCurrency"
      }, {
        displayName: "Currency (Sec)",
        displayField: "securityStaticCurrency.isoCode"
      }, {
        displayName: "Net Amount (Sec)",
        displayField: "netAmountSecurityCurrency"
      }, {
        displayName: "Currency (Fee)",
        displayField: "feeCurrency.isoCode"
      }, {
        displayName: "Fee Amount",
        displayField: "feeAmountFeeCurrency"
      }, {
        displayName: "Counterparty",
        displayField: "counterpartyStatic.descriptionShort"
      }];
      this.tableFilters = [{
        displayName: "Account Master",
        displayField: "accountMasters",
        queryField: "accountMaster",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }, {
        displayName: "Security",
        displayField: "securityStatics",
        queryField: "securityStatic",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort", "ricCode", "tickerCode"],
        isTypeahead: true
      }, {
        displayName: "Event",
        displayField: "eventTypes",
        queryField: "eventType",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }, {
        displayName: "Counterparty",
        displayField: "counterpartyStatics",
        queryField: "counterpartyStatic",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }];
      this.tableFieldsSecurityTransactionFills = [{
        displayName: "ID Fill",
        displayField: "fillNumber"
      }, {
        displayName: "Quantity Fill",
        displayField: "fillPrice"
      }, {
        displayName: "Price Fill",
        displayField: "fillPrice"
      }, {
        displayName: "Price Average",
        displayField: "fillPriceAverage"
      }, {
        displayName: "Time Fill",
        displayField: "fillTime"
      }];
    },

    activeSecurityTransaction: null,
    securityTransactionFillsForSecurityTransactionLabel: Ember.computed("activeSecurityTransaction", function () {
      return "Fills of " + this.get("activeSecurityTransaction.niceString");
    }),
    actions: {
      showSecurityTransactionFills: function (securityTransaction) {
        this.set("activeSecurityTransaction", securityTransaction);
        return false;
      },
      clearActiveSecurityTransaction: function () {
        this.set("activeSecurityTransaction", null);
        return false;
      }
    }
  });

  _exports.default = _default;
});