define("atcm-client/controllers/client/transactions/securitytransactionfills/entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      save: function () {
        const self = this;
        this.content.save().then(securityTransactionFill => {
          self.transitionToRoute("trading.securitytransactionfills.edit", securityTransactionFill);
        });
      },
      rollback: function () {
        const self = this;
        this.content.rollbackAttributes();
        self.transitionToRoute("trading.securitytransactions");
      }
    }
  });

  _exports.default = _default;
});