define("atcm-client/helpers/format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatCurrency = formatCurrency;

  function formatCurrency([quantity, decimals, sign, separator], namedArgs) {
    const newSign = sign !== undefined ? sign : namedArgs.sign !== undefined ? namedArgs.sign : "$";
    const newDecimals = decimals !== undefined ? decimals : namedArgs.decimals !== undefined ? namedArgs.decimals : 2;
    const newSeparator = separator !== undefined ? separator : namedArgs.separator !== undefined ? namedArgs.separator : ", ";
    const amount = parseFloat(quantity);
    const amount_rounded = amount.toFixed(newDecimals);
    const decimal_part = amount_rounded.toString().split(".")[1];
    const number_part = amount_rounded.toString().split(".")[0];
    const number = number_part.replace(/\B(?=(\d{3})+(?!\d))/g, newSeparator);
    return newSign.toString() + " " + number + "." + decimal_part;
  }

  var _default = Ember.Helper.helper(formatCurrency);

  _exports.default = _default;
});