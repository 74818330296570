define("atcm-client/controllers/client/transactions/orders/entry", ["exports", "jquery", "atcm-client/controllers/config/environment", "atcm-client/enums/target-bases", "atcm-client/enums/instruction-types"], function (_exports, _jquery, _environment, _targetBases, _instructionTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.required = ["assetClass.id", "instrumentType.id", "securityStatic.id", "eventType.id", "orderDate", "targetBase", "instructionType", "preferredTradeDate"];
      this.errors = [];
      this.investmentModelQueryFields = ["description"];
      this.fieldsToClearAssetClass = ["instrumentType", "securityStatic"];
    },

    isSaving: false,
    loading: false,
    securityStaticQueryParams: null,
    instrumentTypeQueryParams: null,
    selectedSecurityStatic: null,
    targetBases: _targetBases.default,
    instructionTypes: _instructionTypes.default,
    typeOfEntry: "models",
    isBasedOnModel: Ember.computed("typeOfEntry", function () {
      const isModel = this.typeOfEntry === "models";

      if (!isModel) {
        this.set("accountMasterInvestments", []);
        this.set("model", this.store.createRecord("securityOrderMain", {}));
      } else {
        if (this.get("model.constructor.modelName") === "security-order-main") {
          this.set("model", this.store.createRecord("investmentModelOrder", {}));
        }
      }

      return isModel;
    }),
    readyToCheckCompliance: false,
    searchedinvestmentModel: null,
    _searchedIMDidChange: Ember.observer("searchedinvestmentModel", function () {
      const self = this;

      if (this.searchedinvestmentModel) {
        //get the model with constituents (FIXME)
        self.set("loading", true);
        this.store.query("investmentModelConstituent", {
          investmentModel: this.searchedinvestmentModel.get("id"),
          limit: -1
        }).then(() => {
          self.set("loading", false);
        });
        self.get("model.accountMasterInvestments").forEach(accountMasterInvestment => {
          const accountMasterInvestmentModel = self.get("store").createRecord("accountMasterInvestmentModel", {
            accountMasterInvestment: accountMasterInvestment,
            investmentModel: self.get("searchedinvestmentModel"),
            percentage: 0
          });
          accountMasterInvestmentModel.save().then(accountMasterInvestmentModel => {
            accountMasterInvestment.get("accountMasterInvestmentModels").pushObject(accountMasterInvestmentModel);
            self.set("readyToCheckCompliance", true);
          });
        });
        Ember.run.next(self, function () {
          this.set("searchedinvestmentModel", null);
        });
      }
    }),
    searchedAccountMaster: null,
    _searchedAccountMasterDidChange: Ember.observer("searchedAccountMaster", function () {
      const self = this;

      if (this.searchedAccountMaster) {
        this.model.save().then(investmentModelOrder => {
          const accountMasterInvestment = self.get("store").createRecord("accountMasterInvestment", {
            investmentModelOrder: investmentModelOrder,
            accountMaster: self.get("searchedAccountMaster"),
            amount: 0
          });
          accountMasterInvestment.save().then(accountMasterInvestment => {
            self.get("model.accountMasterInvestments").pushObject(accountMasterInvestment);
          });
          Ember.run.next(self, function () {
            this.set("searchedAccountMaster", null);
          });
        });
      }
    }),
    orderComplete: function (order) {
      //is at trading?
      if (parseInt(order.get("statusTransaction.id")) === 8) {
        return true;
      }

      const required = this.required;
      let i, requiredField;

      if (order.get("isSaving")) {
        return false;
      }

      for (i = 0; i < required.get("length"); i++) {
        requiredField = required.objectAt(i);

        if (Ember.isEmpty(order.get(requiredField))) {
          return false;
        }
      }

      return true;
    },
    canAddAllocations: Ember.computed("model.{isSaving, assetClass.id, instrumentType.id, eventType.id, orderDate, targetBase, instructionType, securityStatic.id, preferredTradeDate}", function () {
      return this.orderComplete(this.model);
    }),
    _assetClassSelected: Ember.observer("model.assetClass.id", function () {
      if (!Ember.isNone(this.get("model.assetClass.id"))) {
        this.set("instrumentTypeQueryParams", {
          assetClass: this.get("model.assetClass.id"),
          limit: -1
        });
      } else {
        //clear other fields
        this.set("selectedSecurityStatic", null);
        this.set("errors", []);
      }
    }),
    _instrumentTypeSelected: Ember.observer("model.instrumentType.id", function () {
      if (!Ember.isNone(this.get("model.instrumentType.id"))) {
        this.set("securityStaticQueryParams", {
          instrumentType: this.get("model.instrumentType.id"),
          limit: -1
        });
      } else {
        //clear other fields
        this.set("selectedSecurityStatic", null);
        this.set("errors", []);
      }
    }),
    _securitySelected: Ember.observer("selectedSecurityStatic", function () {
      if (this.get("selectedSecurityStatic.id") && !this.isSaving) {
        const self = this;
        this.store.findRecord("securityStatic", this.get("selectedSecurityStatic.id")).then(securityStatic => {
          self.set("model.securityStatic", securityStatic); // save now to do compliance check on selected security

          self.send("save", false); //schedule job to fetch latests price, we will receive it back thourgh the socket

          self.getLastMarketPrice(self.get("model.securityStatic.id"));
        });
      }
    }),
    getLastMarketPrice: function (securityStaticId) {
      _jquery.default.ajax({
        crossdomain: true,
        type: "POST",
        headers: {
          token: JSON.parse(_jquery.default.cookie("token")).token
        },
        url: _environment.default.APP.HOST + "/run-job/calculateLastMarketPrice",
        data: {
          jobParams: {
            securityStaticId: securityStaticId
          }
        },
        success: function () {//TODO
        },
        error: function () {//TODO
        }
      });
    },
    actions: {
      save: function (goToAllocationsAfterSuccess) {
        const newGoToAllocationsAfterSuccess = typeof goToAllocationsAfterSuccess !== "undefined" ? goToAllocationsAfterSuccess : true;
        const self = this;
        this.set("isSaving", true);
        this.store.findRecord("statusTransaction", 6).then(status => {
          self.get("model").set("statusTransaction", status);
          self.get("model").save().then(() => {
            self.set("isSaving", false);
            self.set("errors", []);

            if (newGoToAllocationsAfterSuccess) {
              self.transitionToRoute("ordermanagement.orders.edit", self.get("model"));
            }
          }, error => {
            self.set("errors", []);

            if (error.responseJSON && error.responseJSON.errors) {
              self.set("errors", error.responseJSON.errors);

              for (let i = 0; i < error.responseJSON.errors.length; i++) {
                const err = error.responseJSON.errors[i];
                (0, _jquery.default)("." + err["field"]).addClass("has-error");
              }
            }

            self.set("isSaving", false);
          });
        });
      },
      next: function () {
        const self = this,
              prms = [];
        self.get("model.accountMasterInvestments").forEach(accountMasterInvestment => {
          accountMasterInvestment.save().then(accountMasterInvestment => {
            accountMasterInvestment.get("accountMasterInvestmentModels").forEach(accountMasterInvestmentModel => {
              prms.pushObject(accountMasterInvestmentModel.save());
            });
          });
        });
        Ember.RSVP.all(prms).then(() => {
          const model = self.get("model");
          self.get("store").findRecord("statusTransaction", 7).then(status => {
            model.set("statusTransaction", status);
            model.save().then(newModel => {
              self.set("model", null);
              self.transitionToRoute("ordermanagement.orders.compliance", newModel);
            });
          });
        });
      }
    }
  });

  _exports.default = _default;
});