define("atcm-client/routes/client/staticdata/holidaycalendarmains/holidaycalendarsubs", ["exports", "atcm-client/routes/secure"], function (_exports, _secure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    model: function (params) {
      return this.store.findRecord("holidayCalendarMain", params.holidayCalendarMain_id);
    },
    setupController: function (controller, model) {
      return this.store.query("holidayCalendarSub", {
        filter: {
          holidayCalendarMainId: model.get("id")
        }
      }).then(holidaycalendarsubs => {
        controller.set("holidaycalendarsubs", holidaycalendarsubs);
        controller.set("model", model);
        return;
      });
    }
  });

  _exports.default = _default;
});