define("atcm-client/services/apollo", ["exports", "ember-apollo-client/services/apollo", "@apollo/client/link/context"], function (_exports, _apollo, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OverriddenApollo = (_dec = Ember.inject.service, (_class = class OverriddenApollo extends _apollo.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);
    }

    link() {
      let httpLink = super.link();
      let authLink = (0, _context.setContext)((request, context) => {
        return this._runAuthorize(request, context);
      });
      return authLink.concat(httpLink);
    }

    _runAuthorize() {
      if (!this.session.isAuthenticated) {
        return {};
      }

      return new Ember.RSVP.Promise(success => {
        const {
          idToken
        } = this.session.data.authenticated;
        const headers = {
          Authorization: `Bearer ${idToken}`
        };
        success({
          headers
        });
      }); //   this.get('session').authorize(
      //     'authorizer:oauth2',
      //     (headerName, headerContent) => {
      //       let headers = {};
      //       headers[headerName] = headerContent;
      //       success({ headers });
      //     }
      //   );
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OverriddenApollo;
});