define("atcm-client/controllers/client/core/impactobjectivemains/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Impact Objective",
        displayField: "impactObjective.descriptionShort",
        allignLeft: true
      }, {
        displayName: "Impact Objective",
        displayField: "descriptionShort",
        allignLeft: true
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Impact Objective",
        displayField: "impactObjectives",
        queryField: "impactObjective",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short",
        displayField: "descriptionShort",
        required: true
      }, {
        displayName: "Impact Objective",
        selectionField: "impactObjective",
        displayField: "description",
        model: "impactObjectives",
        queryFields: ["description", "descriptionShort"],
        required: true
      }];
    },

    actions: {
      showImpactObjectiveSubs: function (impactObjectiveMain) {
        this.transitionToRoute("client.core.impactobjectivemains.impactobjectivesubs", impactObjectiveMain);
        return false;
      }
    }
  });

  _exports.default = _default;
});