define("atcm-client/components/date-input", ["exports", "ember-paper/components/paper-input", "moment"], function (_exports, _paperInput, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperInput.default.extend({
    init() {
      this._super(...arguments);

      this.updateValue();
      this.date = this.get("object." + this.displayField);

      if (this.get("requires.length") && this.object) {
        const cpStringForExpansion = "requires.@each,object," + "object.%@".fmt(this.requires.join(","));
        const self = this;
        Ember.defineProperty(this, "visible", Ember.computed(cpStringForExpansion, function () {
          // Here access to this.get('listOne'), this.get('listTwo'), etc.;
          let ret = true;
          this.requires.forEach(requiredProperty => {
            if (!self.get("object." + requiredProperty)) {
              ret = false;
            }
          });
          return ret;
        }));
      }
    },

    date: null,
    object: null,
    displayField: null,
    requires: null,
    visible: true,

    /* manual binding due to lack of binding knowledge ;-) */
    _propagateValueChange: Ember.observer("object", "date", "displayField", function () {
      if (!Ember.isEmpty(this.object) && !Ember.isEmpty(this.displayField)) {
        this.set("object." + this.displayField, this.date);
      }
    }),
    type: "date",
    hasFocus: false,
    placeholderBinding: "dateFormat",
    dateFormat: Ember.computed(() => {
      return "YYYY-MM-DD";
    }),
    updateDate: Ember.observer("value", function () {
      const ms = _moment.default.utc(this.value, this.dateFormat);

      if (ms && ms.isValid()) {
        return this.set("date", ms);
      }
    }),
    updateValue: Ember.observer("date", function () {
      if (this.hasFocus) {
        return;
      }

      const date = this.date;

      if (date) {
        return this.set("value", _moment.default.isMoment(date) ? date.utc().format(this.dateFormat) : _moment.default.utc(date));
      }
    }),
    focusIn: function () {
      return this.set("hasFocus", true);
    },
    focusOut: function () {
      this.set("hasFocus", false);
      return this.updateValue();
    }
  });

  _exports.default = _default;
});