define("atcm-client/controllers/client/impact/issuerassessments/index", ["exports", "atcm-client/gql/queries/issuerAssessments"], function (_exports, _issuerAssessments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Issuer",
        displayField: "issuerStatic.description",
        alignLeft: true,
        flex: 5
      }, {
        displayName: "Analyse date",
        displayField: "analyseDate",
        flex: 5
      }, {
        displayName: "Apperent intent",
        displayField: "apparentIntent",
        flex: 5
      }, {
        displayName: "Exclusion Test",
        displayField: "exclusionTest",
        flex: 5
      }, {
        displayName: "Health & Wellbeing",
        displayField: "healthAndWellbeing",
        flex: 5
      }, {
        displayName: "Climate Change",
        displayField: "climateChange",
        flex: 5
      }, {
        displayName: "Eco Solutions",
        displayField: "ecoSolutions",
        flex: 5
      }, {
        displayName: "Analyst",
        displayField: "analyst",
        flex: 5
      }];
      this.tableFilters = [{
        displayName: "Issuer",
        displayField: "issuerStatics",
        queryField: "issuerStatic",
        displayType: "typeahead",
        isTypeahead: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true,
        flex: 5
      }, {
        displayName: "Issuer",
        selectionField: "issuerStatic",
        displayField: "description",
        model: "issuerStatics",
        required: true,
        flex: 5
      }, {
        displayName: "Analyse date",
        displayField: "analyseDate",
        disabled: false,
        date: true,
        hideOnAdd: false,
        flex: 5
      }, {
        displayName: "Analysist",
        displayField: "analyst",
        disabled: false,
        date: true,
        hideOnAdd: false
      }, {
        displayName: "Exclusion Test",
        displayField: "exclusionTest",
        disabled: false,
        date: true,
        hideOnAdd: false
      }, {
        displayName: "Exclusion Test",
        displayField: "healthAndWellbeing",
        disabled: false,
        date: true,
        hideOnAdd: false
      }, {
        displayName: "Exclusion Test",
        displayField: "climateChange",
        disabled: false,
        date: true,
        hideOnAdd: false
      }, {
        displayName: "Exclusion Test",
        displayField: "ecoSolutions",
        disabled: false,
        date: true,
        hideOnAdd: false
      }, {
        displayName: "Inclusion",
        displayField: "inclusion",
        disabled: false,
        date: true,
        hideOnAdd: false
      }];
    },

    fetchMore() {
      if (this.get("model.issuerAssessments.length") % 10 === 0) {
        const variables = {
          limit: 10,
          offset: this.get("model.issuerAssessments.length")
        };
        this.apollo.query({
          query: _issuerAssessments.default,
          variables
        }).then(result => {
          this.get("model.issuerAssessments").pushObjects(result.issuerAssessments);
        });
      }
    },

    editRoute: null
  });

  _exports.default = _default;
});