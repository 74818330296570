define("atcm-client/controllers/client/staticdata/securityprices/index", ["exports", "atcm-client/gql/queries/securityPrices"], function (_exports, _securityPrices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Security Static",
        displayField: "securityListing.securityStatic.description",
        alignLeft: true
      }, {
        displayName: "Currency",
        displayField: "securityListing.securityStatic.currency.isoCode"
      }, {
        displayName: "Price",
        displayField: "price"
      }, {
        displayName: "Value Date",
        displayField: "nicePriceTimestamp"
      }, {
        displayName: "Trade Volume",
        displayField: "tradingVolume"
      }, {
        displayName: "Pricing Option",
        displayField: "pricingOption.descriptionShort"
      }, {
        displayName: "Datavendor",
        displayField: "datavendorStatic.descriptionShort"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Security",
        displayField: "securityListings",
        queryField: "securityListing",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort", "ricCode", "tickerCode"],
        isTypeahead: true
      }, {
        displayName: "Pricing Option",
        displayField: "pricingOptions",
        queryField: "pricingOption",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }, {
        displayName: "Datavendor",
        displayField: "datavendorStatics",
        queryField: "dataVendor",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Security listing",
        selectionField: "securityListing",
        displayField: "description",
        model: "securityListings",
        queryFields: ["description", "ricCode", "tickerCode"],
        required: true
      }, {
        displayName: "Datavendor",
        selectionField: "datavendorStatic",
        displayField: "description",
        model: "datavendorStatics",
        queryFields: ["description", "descriptionShort"],
        required: true
      }, {
        displayName: "Pricing option",
        selectionField: "pricingOption",
        displayField: "description",
        model: "pricingOption",
        queryFields: ["description", "descriptionShort"],
        required: true
      }, {
        displayName: "Timestamp Price",
        displayField: "priceTimestamp",
        date: true
      }, {
        displayName: "Trading Volume",
        displayField: "tradingVolume"
      }, {
        displayName: "Price",
        displayField: "price",
        required: true
      }];
    },

    fetchMore() {
      if (this.get("model.securityPrices.length") % 10 === 0) {
        const variables = {
          limit: 10,
          offset: this.get("model.securityPrices.length")
        };
        this.apollo.query({
          query: _securityPrices.default,
          variables
        }).then(result => {
          this.get("model.securityPrices").pushObjects(result.securityPrices);
        });
      }
    },

    editRoute: null
  });

  _exports.default = _default;
});