define("atcm-client/templates/client/compliance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7vhlCuGG",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-sidenav-container\",[],[[\"@class\"],[\"inner-sidenav\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-sidenav\",[],[[\"@class\",\"@lockedOpen\",\"@open\",\"@onToggle\"],[\"site-sidenav\",\"gt-sm\",[34,0],[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"paper-list\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"menu-item\",[],[[\"@active\",\"@onClick\"],[[30,[36,4],[\"client.compliance\",[35,3]],null],[30,[36,5],[\"client.compliance\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Dashboard\"]],\"parameters\":[]}]]],[2,\"\\n          \"],[8,\"menu-item\",[],[[\"@active\",\"@onClick\"],[[30,[36,4],[\"client.compliance.compliancebaskets\",[35,3]],null],[30,[36,5],[\"client.compliance.compliancebaskets\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Compliance Baskets\"]],\"parameters\":[]}]]],[2,\"\\n          \"],[8,\"menu-item\",[],[[\"@active\",\"@onClick\"],[[30,[36,4],[\"client.compliance.compliancerules\",[35,3]],null],[30,[36,5],[\"client.compliance.compliancerules\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Compliance Rules\"]],\"parameters\":[]}]]],[2,\"\\n\"],[2,\"      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex layout-column bgwhite\"],[14,\"tabindex\",\"-1\"],[14,\"role\",\"main\"],[12],[2,\"\\n    \"],[1,[30,[36,7],[[30,[36,6],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isSidenavOpen\",\"mut\",\"action\",\"currentRouteName\",\"is-active\",\"transition-to\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/compliance.hbs"
    }
  });

  _exports.default = _default;
});