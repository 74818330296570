define("atcm-client/routes/client/impact/index", ["exports", "atcm-client/routes/secure", "atcm-client/routes/client/impact/sdg", "atcm-client/routes/client/impact/sdg-positions", "atcm-client/routes/client/impact/sdg-leicode"], function (_exports, _secure, _sdg, _sdgPositions, _sdgLeicode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    init() {
      this._super(...arguments);

      this.sdgImages = ["assets/images/sdg/small/1.png", "assets/images/sdg/small/2.png", "assets/images/sdg/small/3.png", "assets/images/sdg/small/4.png", "assets/images/sdg/small/5.png", "assets/images/sdg/small/6.png", "assets/images/sdg/small/7.png", "assets/images/sdg/small/8.png", "assets/images/sdg/small/9.png", "assets/images/sdg/small/10.png", "assets/images/sdg/small/11.png", "assets/images/sdg/small/12.png", "assets/images/sdg/small/13.png", "assets/images/sdg/small/14.png", "assets/images/sdg/small/15.png", "assets/images/sdg/small/16.png", "assets/images/sdg/small/17.png", "assets/images/sdg/small/18.png"];
    },

    apollo: Ember.inject.service(),
    model: function () {
      const positionData = this.apollo.query({
        query: _sdgPositions.default
      });
      const manualSDGData = this.apollo.query({
        query: _sdgLeicode.default
      });
      const sdgDataUn = this.apollo.query({
        query: _sdg.default
      });
      return Ember.RSVP.hash({
        positionData,
        manualSDGData,
        sdgDataUn
      }).then(hashedResult => {
        const unData = hashedResult.sdgDataUn.impactObjectiveMains;
        const manualData = hashedResult.manualSDGData.impactObjectiveMains;

        for (const main of manualData) {
          if (unData.hasOwnProperty(main.id)) {
            unData.latestCompanyImpacts.pushObjects(main.latestCompanyImpacts);
          } else {
            unData.latestCompanyImpacts = main.latestCompanyImpacts;
          }
        }

        const totalPosition = parseFloat(hashedResult.positionData["securityPositions_aggregate"].aggregate.sum.ifrsBookValueAccountCurrency);

        const dataSet = this._createTotals(unData.reverse(), totalPosition);

        dataSet.totalPosition = totalPosition;
        dataSet.nonSDGPositions = hashedResult.positionData.securityPositions;
        let totalNoSDG = 0;
        const issuersWithoutSDG = new Map();

        for (const position of dataSet.nonSDGPositions) {
          totalNoSDG += position.ifrsBookValueAccountCurrency;
          const issuer = position.securityListing.securityStatic.issuerStatic;
          dataSet.issuers.set(issuer.id, issuer);

          if (issuersWithoutSDG.has(issuer.id)) {
            // add position to it
            const currentValue = issuersWithoutSDG.get(issuer.id)[1];
            issuersWithoutSDG.set(issuer.id, [issuer.description, position.ifrsBookValueAccountCurrency + currentValue, issuer.id]);
          } else {
            issuersWithoutSDG.set(issuer.id, [issuer.description, position.ifrsBookValueAccountCurrency, issuer.id]);
          }
        }

        const noSDGArray = Array.from(issuersWithoutSDG.values());
        noSDGArray.unshift(["Issuer", "Total investment", "issuerStaticId"]);
        dataSet.securityChartData[18] = noSDGArray;
        dataSet.issuersWithoutSDG = issuersWithoutSDG;
        dataSet.sdgPieChart.push({
          sdgId: 18,
          name: "No linked SDG",
          total: totalNoSDG,
          percentage: parseInt(totalNoSDG / totalPosition * 100),
          img: this.sdgImages[17]
        });
        return dataSet;
      });
    },

    _createTotals(data, totalPosition) {
      const chartData = [];
      const securityChartData = {};
      const issuers = new Map();
      let totalSDG = 0;
      data.forEach(impactObjectiveMain => {
        let subTotal = 0;
        const issuersPerGoal = [["Issuer", "Total investment", "issuerStaticId"]];
        const issuersImpactAndLei = [];
        const ids = []; // console.log(impactObjectiveMain.latestCompanyImpacts);

        impactObjectiveMain.latestCompanyImpacts.forEach(companyImpact => {
          if (companyImpact.unGlobalCompact) {
            companyImpact.unGlobalCompact.issuerCompacts.forEach(compact => {
              if (!ids.includes(compact.issuerStatic.id)) {
                issuersImpactAndLei.push(compact.issuerStatic);
                ids.push(compact.issuerStatic.id);
              }
            }); // console.log("pushing from unGlobal", allIssuerStatics);
            // issuersImpactAndLei.pushObjects(allIssuerStatics);
          } else {
            // use lei
            if (!ids.includes(companyImpact.issuerStaticLeiCode.id)) {
              issuersImpactAndLei.push(companyImpact.issuerStaticLeiCode);
              ids.push(companyImpact.issuerStaticLeiCode);
            }
          }
        }); // console.log("found issuers with impact in db", issuersImpactAndLei);

        issuersImpactAndLei.forEach(issuerWithImpact => {
          let issuerTotal = 0;
          let issuer; // console.warn("found issuer", issuerWithImpact.description);

          if (issuers.has(issuerWithImpact.id)) {
            // console.warn("exists in map");
            issuer = issuers.get(issuerWithImpact.id);
            issuerTotal = issuer.total;
            const goal = {
              id: impactObjectiveMain.id,
              descriptionShort: impactObjectiveMain.descriptionShort,
              description: impactObjectiveMain.description,
              img: this.sdgImages[impactObjectiveMain.id - 1]
            }; // console.log("goal", goal);

            issuer.goals.push(goal); // each issuer we find has the same position value (with same id) no need to recalculate the subtotal

            subTotal += issuerTotal; // console.warn("New goal exisitig sec", securityStatic.id, impactObjectiveMain.descriptionShort);
            // console.log("existing issuer, goals", issuer);
          } else {
            // console.log("Did not find issuer, pushing new", issuerWithImpact);
            // console.warn("does not exist in map");
            issuer = {
              id: issuerWithImpact.id,
              description: issuerWithImpact.description,
              gicsIndustrySub: issuerWithImpact.gicsIndustrySub,
              positions: [],
              goals: [{
                id: impactObjectiveMain.id,
                descriptionShort: impactObjectiveMain.descriptionShort,
                description: impactObjectiveMain.description,
                img: this.sdgImages[impactObjectiveMain.id - 1]
              }],
              total: 0
            }; // console.log("new issuer, goals", issuer);

            issuerWithImpact.securityStatics.forEach(securityStatic => {
              securityStatic.securityListings.forEach(securityListing => {
                securityListing.securityPositions.forEach(position => {
                  subTotal += position.ifrsBookValueAccountCurrency;
                  issuerTotal += position.ifrsBookValueAccountCurrency;
                  issuer.positions.push(position);
                });
              });
            });
            issuer.total = issuerTotal;
            issuers.set(issuerWithImpact.id, issuer);
            totalSDG += issuerTotal; // console.warn("New security", securityStatic.id, security);
          }

          issuersPerGoal.push([issuer.description, issuerTotal, issuer.id]);
        });
        chartData.push({
          name: impactObjectiveMain.descriptionShort,
          total: subTotal,
          percentage: parseInt(subTotal / totalPosition * 100),
          sdgId: impactObjectiveMain.id
        });
        securityChartData[impactObjectiveMain.id] = issuersPerGoal;
      });
      return {
        sdgPieChart: chartData,
        raw: data,
        securityChartData,
        issuers,
        totalSDG
      };
    },

    setupController: function (controller, model) {
      // console.warn(model);
      this._super(...arguments);

      controller.set("activePieDataSet", model.sdgPieChart);
      controller.set("activeSecurityDataSet", model.securityChartData[1]); // controller.set("selectedSDG", {
      // 	descriptionShort: model.raw[0].descriptionShort,
      // 	description: model.raw[0].description,
      // 	id: model.raw[0].id,
      // 	img: this.sdgImages[0],
      // });
      // console.warn("model.securityChartData[1]", model.securityChartData[1][1]);
      // controller.set("selectedIssuer", model.issuers.get(model.securityChartData[1][1][2]));
    }
  });

  _exports.default = _default;
});