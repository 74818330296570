define("atcm-client/controllers/client/impact/companyimpactperformances/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Company",
        displayField: "companies",
        queryField: "company",
        displayType: "typeahead",
        queryFields: ["description", "legalEntityIdentifier"],
        isTypeahead: true
      }];
      this.tableFields = [{
        name: "Description",
        valuePath: "description",
        alignLeft: true
      }, {
        name: "Legal Entity Identifier",
        valuePath: "legalEntityIdentifier"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "LegalEntityIdentifier",
        displayField: "legalEntityIdentifier",
        required: true
      }]; // this.actionColumns = [
      // 	{
      // 		name: "Managed Funds",
      // 		action: "showManagedFunds",
      // 	},
      // ];
    } // actions: {
    // 	processActionColumnAction: function(row, action) {
    // 		this.send(action, row);
    // 	},
    // 	showManagedFunds: function(assetManager) {
    // 		this.transitionToRoute("client.marketanalyses.assetmanagers.funds", assetManager);
    // 		return false;
    // 	},
    // },


  });

  _exports.default = _default;
});