define("atcm-client/controllers/client/core/gicsindustrygroups/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Industry Group Code",
        displayField: "industryGroupCode",
        allignLeft: true
      }, {
        displayName: "Description",
        displayField: "description",
        allignLeft: true
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Industry Group Code",
        displayField: "industryGroupCode",
        required: true
      }];
    },

    editRoute: null
  });

  _exports.default = _default;
});