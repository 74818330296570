define("atcm-client/controllers/client/compliance/compliancerules/entry", ["exports", "atcm-client/enums/severities", "atcm-client/enums/reasons"], function (_exports, _severities, _reasons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    searchService: Ember.inject.service("search"),
    severities: _severities.default,
    reasons: _reasons.default,

    init() {
      this._super(...arguments);

      this.set("model", this.store.createRecord("compliance-rule", {}));
    },

    actions: {
      save: function () {
        this.model.save().then(complianceRule => {
          this.transitionToRoute("client.compliance.compliancerules.edit", complianceRule);
        });
      },
      cancel: function () {
        this.model.rollbackAttributes();
        this.transitionToRoute("client.compliance.compliancerules");
      },
      search: function (modelName, query) {
        return this.searchService.search(modelName, query);
      }
    }
  });

  _exports.default = _default;
});