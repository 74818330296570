define("atcm-client/templates/client/models/investmentprofiles/investmentprofileratios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OScLDNQA",
    "block": "{\"symbols\":[],\"statements\":[[8,\"simple-table\",[],[[\"@content\",\"@title\",\"@type\",\"@fields\",\"@filters\",\"@formFields\",\"@back\"],[[34,0],[34,1],\"investmentProfileRatio\",[34,2],[34,3],[34,4],\"client.models.investmentprofiles.index\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"investmentprofileratios\",\"title\",\"tableFields\",\"tableFilters\",\"formFields\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/models/investmentprofiles/investmentprofileratios.hbs"
    }
  });

  _exports.default = _default;
});