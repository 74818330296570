define("atcm-client/models/account-master", ["exports", "atcm-client/models/base-model", "moment"], function (_exports, _baseModel, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceDateOfOpeningStamp: Ember.computed("dateOfOpening", function () {
      if (this.dateOfOpening) {
        return (0, _moment.default)(this.dateOfOpening).format("DD-MM-YYYY");
      }
    }),
    niceString: Ember.computed("description", "descriptionShort", function () {
      return this.description + " - " + this.descriptionShort;
    })
  });

  _exports.default = _default;
});