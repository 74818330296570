define("atcm-client/controllers/client/staticdata/datavendorstatics/index", ["exports", "atcm-client/gql/queries/datavendors"], function (_exports, _datavendors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true
      }, {
        displayName: "Description short",
        displayField: "descriptionShort"
      }, {
        displayName: "Country",
        displayField: "country.isoThree"
      }, {
        displayName: "City",
        displayField: "city.description"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Country",
        displayField: "countries",
        queryField: "country",
        displayType: "typeahead",
        queryFields: ["description", "isoTwo", "isoThree"],
        isTypeahead: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short",
        displayField: "descriptionShort",
        required: true
      }, {
        displayName: "Country",
        selectionField: "country",
        displayField: "description",
        model: "countries",
        queryFields: ["description", "isoThree", "isoTwo"],
        required: true
      }, {
        displayName: "City",
        selectionField: "city",
        displayField: "description",
        model: "cities",
        queryParams: {
          country: "country.id",
          limit: -1
        },
        required: true
      }];
    },

    fetchMore() {
      if (this.get("model.datavendorStatics.length") % 10 === 0) {
        const variables = {
          limit: 10,
          offset: this.get("model.datavendorStatics.length")
        };
        this.apollo.query({
          query: _datavendors.default,
          variables
        }).then(result => {
          this.get("model.datavendorStatics").pushObjects(result.datavendorStatics);
        });
      }
    },

    editRoute: null
  });

  _exports.default = _default;
});