define("atcm-client/initializers/toastr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "toastr",
    initialize: function () {// toastr.options = {
      // 	"closeButton": true,
      // 	"debug": false,
      // 	"progressBar": true,
      // 	"positionClass": "toast-bottom-left",
      // 	"onclick": null,
      // 	"showDuration": "100",
      // 	"hideDuration": "100",
      // 	"timeOut": "4000",
      // 	"extendedTimeOut": "1000",
      // 	"preventDuplicates": true
      // };
    }
  };
  _exports.default = _default;
});