define("atcm-client/controllers/client/transactions/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Assetclass",
        displayField: "assetClass.description"
      }, {
        displayName: "InstrumentType",
        displayField: "assetClass.instrumentType.description"
      }, {
        displayName: "RicCode",
        displayField: "securityStatic.ricCode"
      }, {
        displayName: "First order time",
        displayField: "firstTimestamp"
      }, {
        displayName: "Total quantity",
        displayField: "quantity"
      }];
    }

  });

  _exports.default = _default;
});