define("atcm-client/controllers/client/staticdata/issuerstatics/impacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.include = ["assessmentSource", "impactObjectiveMain"];
      this.tableFields = [{
        displayName: "Impact Objective",
        displayField: "impactObjectiveMain.descriptionShort",
        alignLeft: true
      }, {
        displayName: "Description",
        displayField: "description"
      }, {
        displayName: "Source",
        displayField: "assessmentSource.description"
      }, {
        displayName: "Date",
        displayField: "createdAt"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true
      }, {
        displayName: "Source",
        selectionField: "assessmentSource",
        displayField: "description",
        model: "assessmentSources",
        queryFields: ["description"]
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Impact objectives defined by issuer " + this.get("model.description");
    })
  });

  _exports.default = _default;
});