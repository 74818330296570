define("atcm-client/controllers/client/analyses/getPositions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "getSecurityPositions"
      },
      "variableDefinitions": [{
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "orderBy"
          }
        },
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "securityPositions_order_by"
              }
            }
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "limit"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "offset"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "search"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "custodianStatic"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "accountMaster"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "securityStatic"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "assetClass"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "instrumentType"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "economicSector"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "industryGroup"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "country"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "currency"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "securityPositions_aggregate"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "where"
            },
            "value": {
              "kind": "ObjectValue",
              "fields": [{
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "_and"
                },
                "value": {
                  "kind": "ListValue",
                  "values": [{
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "accountMaster"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "description"
                          },
                          "value": {
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "_ilike"
                              },
                              "value": {
                                "kind": "Variable",
                                "name": {
                                  "kind": "Name",
                                  "value": "search"
                                }
                              }
                            }]
                          }
                        }]
                      }
                    }]
                  }, {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "custodianStaticId"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "_eq"
                          },
                          "value": {
                            "kind": "Variable",
                            "name": {
                              "kind": "Name",
                              "value": "custodianStatic"
                            }
                          }
                        }]
                      }
                    }]
                  }, {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "accountMasterId"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "_eq"
                          },
                          "value": {
                            "kind": "Variable",
                            "name": {
                              "kind": "Name",
                              "value": "accountMaster"
                            }
                          }
                        }]
                      }
                    }]
                  }, {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "securityListing"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "securityStatic"
                          },
                          "value": {
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "_and"
                              },
                              "value": {
                                "kind": "ListValue",
                                "values": [{
                                  "kind": "ObjectValue",
                                  "fields": [{
                                    "kind": "ObjectField",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    },
                                    "value": {
                                      "kind": "ObjectValue",
                                      "fields": [{
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "_eq"
                                        },
                                        "value": {
                                          "kind": "Variable",
                                          "name": {
                                            "kind": "Name",
                                            "value": "securityStatic"
                                          }
                                        }
                                      }]
                                    }
                                  }]
                                }, {
                                  "kind": "ObjectValue",
                                  "fields": [{
                                    "kind": "ObjectField",
                                    "name": {
                                      "kind": "Name",
                                      "value": "assetClassId"
                                    },
                                    "value": {
                                      "kind": "ObjectValue",
                                      "fields": [{
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "_eq"
                                        },
                                        "value": {
                                          "kind": "Variable",
                                          "name": {
                                            "kind": "Name",
                                            "value": "assetClass"
                                          }
                                        }
                                      }]
                                    }
                                  }]
                                }, {
                                  "kind": "ObjectValue",
                                  "fields": [{
                                    "kind": "ObjectField",
                                    "name": {
                                      "kind": "Name",
                                      "value": "instrumentTypeId"
                                    },
                                    "value": {
                                      "kind": "ObjectValue",
                                      "fields": [{
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "_eq"
                                        },
                                        "value": {
                                          "kind": "Variable",
                                          "name": {
                                            "kind": "Name",
                                            "value": "instrumentType"
                                          }
                                        }
                                      }]
                                    }
                                  }]
                                }, {
                                  "kind": "ObjectValue",
                                  "fields": [{
                                    "kind": "ObjectField",
                                    "name": {
                                      "kind": "Name",
                                      "value": "currencyId"
                                    },
                                    "value": {
                                      "kind": "ObjectValue",
                                      "fields": [{
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "_eq"
                                        },
                                        "value": {
                                          "kind": "Variable",
                                          "name": {
                                            "kind": "Name",
                                            "value": "currency"
                                          }
                                        }
                                      }]
                                    }
                                  }]
                                }, {
                                  "kind": "ObjectValue",
                                  "fields": [{
                                    "kind": "ObjectField",
                                    "name": {
                                      "kind": "Name",
                                      "value": "issuerStatic"
                                    },
                                    "value": {
                                      "kind": "ObjectValue",
                                      "fields": [{
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "hqCountryId"
                                        },
                                        "value": {
                                          "kind": "ObjectValue",
                                          "fields": [{
                                            "kind": "ObjectField",
                                            "name": {
                                              "kind": "Name",
                                              "value": "_eq"
                                            },
                                            "value": {
                                              "kind": "Variable",
                                              "name": {
                                                "kind": "Name",
                                                "value": "country"
                                              }
                                            }
                                          }]
                                        }
                                      }]
                                    }
                                  }]
                                }, {
                                  "kind": "ObjectValue",
                                  "fields": [{
                                    "kind": "ObjectField",
                                    "name": {
                                      "kind": "Name",
                                      "value": "issuerStatic"
                                    },
                                    "value": {
                                      "kind": "ObjectValue",
                                      "fields": [{
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "gicsIndustrySub"
                                        },
                                        "value": {
                                          "kind": "ObjectValue",
                                          "fields": [{
                                            "kind": "ObjectField",
                                            "name": {
                                              "kind": "Name",
                                              "value": "gicsIndustry"
                                            },
                                            "value": {
                                              "kind": "ObjectValue",
                                              "fields": [{
                                                "kind": "ObjectField",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "gicsIndustryGroupId"
                                                },
                                                "value": {
                                                  "kind": "ObjectValue",
                                                  "fields": [{
                                                    "kind": "ObjectField",
                                                    "name": {
                                                      "kind": "Name",
                                                      "value": "_eq"
                                                    },
                                                    "value": {
                                                      "kind": "Variable",
                                                      "name": {
                                                        "kind": "Name",
                                                        "value": "industryGroup"
                                                      }
                                                    }
                                                  }]
                                                }
                                              }]
                                            }
                                          }]
                                        }
                                      }]
                                    }
                                  }]
                                }, {
                                  "kind": "ObjectValue",
                                  "fields": [{
                                    "kind": "ObjectField",
                                    "name": {
                                      "kind": "Name",
                                      "value": "issuerStatic"
                                    },
                                    "value": {
                                      "kind": "ObjectValue",
                                      "fields": [{
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "gicsIndustrySub"
                                        },
                                        "value": {
                                          "kind": "ObjectValue",
                                          "fields": [{
                                            "kind": "ObjectField",
                                            "name": {
                                              "kind": "Name",
                                              "value": "gicsIndustry"
                                            },
                                            "value": {
                                              "kind": "ObjectValue",
                                              "fields": [{
                                                "kind": "ObjectField",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "gicsIndustryGroup"
                                                },
                                                "value": {
                                                  "kind": "ObjectValue",
                                                  "fields": [{
                                                    "kind": "ObjectField",
                                                    "name": {
                                                      "kind": "Name",
                                                      "value": "gicsSectorId"
                                                    },
                                                    "value": {
                                                      "kind": "ObjectValue",
                                                      "fields": [{
                                                        "kind": "ObjectField",
                                                        "name": {
                                                          "kind": "Name",
                                                          "value": "_eq"
                                                        },
                                                        "value": {
                                                          "kind": "Variable",
                                                          "name": {
                                                            "kind": "Name",
                                                            "value": "economicSector"
                                                          }
                                                        }
                                                      }]
                                                    }
                                                  }]
                                                }
                                              }]
                                            }
                                          }]
                                        }
                                      }]
                                    }
                                  }]
                                }]
                              }
                            }]
                          }
                        }]
                      }
                    }]
                  }]
                }
              }]
            }
          }],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "aggregate"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "count"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }]
          }
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "securityPositions"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "order_by"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "orderBy"
              }
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "limit"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "limit"
              }
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "offset"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "offset"
              }
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "where"
            },
            "value": {
              "kind": "ObjectValue",
              "fields": [{
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "_and"
                },
                "value": {
                  "kind": "ListValue",
                  "values": [{
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "accountMaster"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "description"
                          },
                          "value": {
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "_ilike"
                              },
                              "value": {
                                "kind": "Variable",
                                "name": {
                                  "kind": "Name",
                                  "value": "search"
                                }
                              }
                            }]
                          }
                        }]
                      }
                    }]
                  }, {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "custodianStaticId"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "_eq"
                          },
                          "value": {
                            "kind": "Variable",
                            "name": {
                              "kind": "Name",
                              "value": "custodianStatic"
                            }
                          }
                        }]
                      }
                    }]
                  }, {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "accountMasterId"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "_eq"
                          },
                          "value": {
                            "kind": "Variable",
                            "name": {
                              "kind": "Name",
                              "value": "accountMaster"
                            }
                          }
                        }]
                      }
                    }]
                  }, {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "securityListing"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "securityStatic"
                          },
                          "value": {
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "_and"
                              },
                              "value": {
                                "kind": "ListValue",
                                "values": [{
                                  "kind": "ObjectValue",
                                  "fields": [{
                                    "kind": "ObjectField",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    },
                                    "value": {
                                      "kind": "ObjectValue",
                                      "fields": [{
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "_eq"
                                        },
                                        "value": {
                                          "kind": "Variable",
                                          "name": {
                                            "kind": "Name",
                                            "value": "securityStatic"
                                          }
                                        }
                                      }]
                                    }
                                  }]
                                }, {
                                  "kind": "ObjectValue",
                                  "fields": [{
                                    "kind": "ObjectField",
                                    "name": {
                                      "kind": "Name",
                                      "value": "assetClassId"
                                    },
                                    "value": {
                                      "kind": "ObjectValue",
                                      "fields": [{
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "_eq"
                                        },
                                        "value": {
                                          "kind": "Variable",
                                          "name": {
                                            "kind": "Name",
                                            "value": "assetClass"
                                          }
                                        }
                                      }]
                                    }
                                  }]
                                }, {
                                  "kind": "ObjectValue",
                                  "fields": [{
                                    "kind": "ObjectField",
                                    "name": {
                                      "kind": "Name",
                                      "value": "instrumentTypeId"
                                    },
                                    "value": {
                                      "kind": "ObjectValue",
                                      "fields": [{
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "_eq"
                                        },
                                        "value": {
                                          "kind": "Variable",
                                          "name": {
                                            "kind": "Name",
                                            "value": "instrumentType"
                                          }
                                        }
                                      }]
                                    }
                                  }]
                                }, {
                                  "kind": "ObjectValue",
                                  "fields": [{
                                    "kind": "ObjectField",
                                    "name": {
                                      "kind": "Name",
                                      "value": "currencyId"
                                    },
                                    "value": {
                                      "kind": "ObjectValue",
                                      "fields": [{
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "_eq"
                                        },
                                        "value": {
                                          "kind": "Variable",
                                          "name": {
                                            "kind": "Name",
                                            "value": "currency"
                                          }
                                        }
                                      }]
                                    }
                                  }]
                                }, {
                                  "kind": "ObjectValue",
                                  "fields": [{
                                    "kind": "ObjectField",
                                    "name": {
                                      "kind": "Name",
                                      "value": "issuerStatic"
                                    },
                                    "value": {
                                      "kind": "ObjectValue",
                                      "fields": [{
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "hqCountryId"
                                        },
                                        "value": {
                                          "kind": "ObjectValue",
                                          "fields": [{
                                            "kind": "ObjectField",
                                            "name": {
                                              "kind": "Name",
                                              "value": "_eq"
                                            },
                                            "value": {
                                              "kind": "Variable",
                                              "name": {
                                                "kind": "Name",
                                                "value": "country"
                                              }
                                            }
                                          }]
                                        }
                                      }]
                                    }
                                  }]
                                }, {
                                  "kind": "ObjectValue",
                                  "fields": [{
                                    "kind": "ObjectField",
                                    "name": {
                                      "kind": "Name",
                                      "value": "issuerStatic"
                                    },
                                    "value": {
                                      "kind": "ObjectValue",
                                      "fields": [{
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "gicsIndustrySub"
                                        },
                                        "value": {
                                          "kind": "ObjectValue",
                                          "fields": [{
                                            "kind": "ObjectField",
                                            "name": {
                                              "kind": "Name",
                                              "value": "gicsIndustry"
                                            },
                                            "value": {
                                              "kind": "ObjectValue",
                                              "fields": [{
                                                "kind": "ObjectField",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "gicsIndustryGroupId"
                                                },
                                                "value": {
                                                  "kind": "ObjectValue",
                                                  "fields": [{
                                                    "kind": "ObjectField",
                                                    "name": {
                                                      "kind": "Name",
                                                      "value": "_eq"
                                                    },
                                                    "value": {
                                                      "kind": "Variable",
                                                      "name": {
                                                        "kind": "Name",
                                                        "value": "industryGroup"
                                                      }
                                                    }
                                                  }]
                                                }
                                              }]
                                            }
                                          }]
                                        }
                                      }]
                                    }
                                  }]
                                }, {
                                  "kind": "ObjectValue",
                                  "fields": [{
                                    "kind": "ObjectField",
                                    "name": {
                                      "kind": "Name",
                                      "value": "issuerStatic"
                                    },
                                    "value": {
                                      "kind": "ObjectValue",
                                      "fields": [{
                                        "kind": "ObjectField",
                                        "name": {
                                          "kind": "Name",
                                          "value": "gicsIndustrySub"
                                        },
                                        "value": {
                                          "kind": "ObjectValue",
                                          "fields": [{
                                            "kind": "ObjectField",
                                            "name": {
                                              "kind": "Name",
                                              "value": "gicsIndustry"
                                            },
                                            "value": {
                                              "kind": "ObjectValue",
                                              "fields": [{
                                                "kind": "ObjectField",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "gicsIndustryGroup"
                                                },
                                                "value": {
                                                  "kind": "ObjectValue",
                                                  "fields": [{
                                                    "kind": "ObjectField",
                                                    "name": {
                                                      "kind": "Name",
                                                      "value": "gicsSectorId"
                                                    },
                                                    "value": {
                                                      "kind": "ObjectValue",
                                                      "fields": [{
                                                        "kind": "ObjectField",
                                                        "name": {
                                                          "kind": "Name",
                                                          "value": "_eq"
                                                        },
                                                        "value": {
                                                          "kind": "Variable",
                                                          "name": {
                                                            "kind": "Name",
                                                            "value": "economicSector"
                                                          }
                                                        }
                                                      }]
                                                    }
                                                  }]
                                                }
                                              }]
                                            }
                                          }]
                                        }
                                      }]
                                    }
                                  }]
                                }]
                              }
                            }]
                          }
                        }]
                      }
                    }]
                  }]
                }
              }]
            }
          }],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "id"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "postTransactionQuantity"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "ifrsBookValueAccountCurrency"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "ifrsBookValueSecurityCurrency"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "accountMaster"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "descriptionShort"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "currency"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "isoCode"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "custodianStatic"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "description"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "securityListing"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "currency"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "isoCode"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "securityStatic"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "tickerCode"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "isinCode"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "description"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "assetClass"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "descriptionShort"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "instrumentType"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "descriptionShort"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }]
                  }
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 2511
    }
  };
  var _default = doc;
  _exports.default = _default;
});