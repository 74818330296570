define("atcm-client/models/compliance-rule", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceString: Ember.computed("complianceCategoryMain", "complianceCategorySub", function () {
      return this.complianceCategoryMain + " - " + this.complianceCategorySub;
    })
  });

  _exports.default = _default;
});