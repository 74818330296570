define("atcm-client/controllers/client/core/organisationtypes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true
      }, {
        displayName: "Short Description",
        displayField: "descriptionShort"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
    },

    editRoute: null
  });

  _exports.default = _default;
});