define("atcm-client/templates/client/compliance/compliancerulevalues/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XXBgJwki",
    "block": "{\"symbols\":[],\"statements\":[[8,\"simple-table\",[],[[\"@content\",\"@addLabel\",\"@title\",\"@type\",\"@fields\"],[[34,0],\"Compliance Rule Value entry\",\"Compliance Rule Values\",\"complianceRuleValue\",[34,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"tableFields\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/compliance/compliancerulevalues/index.hbs"
    }
  });

  _exports.default = _default;
});