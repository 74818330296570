define("atcm-client/templates/client/reports/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vUf7Pp1Q",
    "block": "{\"symbols\":[\"card\",\"title\",\"text\"],\"statements\":[[8,\"paper-card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"text\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"headline\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Reporting\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,3,[\"subhead\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Make the magic happen\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n   \"],[8,[32,1,[\"content\"]],[],[[\"@class\"],[\"layout-row layout-align-space-between\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Right?\\n   \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/reports/index.hbs"
    }
  });

  _exports.default = _default;
});