define("atcm-client/routes/client/transactions/orders/index", ["exports", "atcm-client/routes/secure"], function (_exports, _secure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    model: function () {
      const store = this.store;
      return store.query("security-order-main", {
        "statusTransaction.id": [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15]
      }).then(() => {
        return store.filter("security-order-main", order => {
          return order.get("statusTransaction.id") !== 7;
        });
      });
    }
  });

  _exports.default = _default;
});