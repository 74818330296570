define("atcm-client/templates/components/ibox-with-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fvimMU+S",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"wrapper wrapper-content animated fadeInRight\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-lg-12\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ibox float-e-margins\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"ibox-title\"],[12],[2,\"\\n          \"],[10,\"h5\"],[12],[10,\"span\"],[12],[1,[34,0]],[13],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"ibox-content\"],[12],[2,\"\\n          \"],[18,1,null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/components/ibox-with-content.hbs"
    }
  });

  _exports.default = _default;
});