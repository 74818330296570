define("atcm-client/controllers/client/core/indicatorratiojoins/index", ["exports", "atcm-client/gql/queries/indicatorRatioJoins"], function (_exports, _indicatorRatioJoins) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Ratio",
        displayField: "ratios",
        queryField: "ratio",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Impact Indicator",
        displayField: "impactObjectiveIndicators",
        queryField: "impactObjectiveIndicator",
        displayType: "typeahead",
        isTypeahead: true
      }];
      this.tableFields = [{
        displayName: "Impact Objective Indicator",
        displayField: "impactObjectiveIndicator.description"
      }, {
        displayName: "Ratio",
        displayField: "ratio.description"
      }, {
        displayName: "Weight (%)",
        displayField: "weight"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Select Impact Objective Indicator",
        selectionField: "impactObjectiveIndicator",
        displayField: "description",
        model: "impactObjectiveIndicators",
        required: true
      }, {
        displayName: "Select Ratio",
        selectionField: "ratio",
        displayField: "description",
        model: "ratios",
        required: true
      }, {
        displayName: "Weight (in %)",
        displayField: "weight"
      }];
    },

    fetchMore() {
      if (this.get("model.indicatorRatioJoins.length") % 10 === 0) {
        const variables = {
          limit: 10,
          offset: this.get("model.indicatorRatioJoins.length")
        };
        this.apollo.query({
          query: _indicatorRatioJoins.default,
          variables
        }).then(result => {
          this.get("model.indicatorRatioJoins").pushObjects(result.indicatorRatioJoins);
        });
      }
    }

  });

  _exports.default = _default;
});