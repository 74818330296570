define("atcm-client/components/gql-table", ["exports", "ember-apollo-client", "@glimmer/component"], function (_exports, _emberApolloClient, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GqlTable = (_dec = Ember.inject.service, _dec2 = Ember.inject.service("search"), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = class GqlTable extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "classNames", ["simple-table"]);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "apollo", _descriptor2, this);

      _initializerDefineProperty(this, "searchService", _descriptor3, this);

      _defineProperty(this, "nextPage", null);

      _defineProperty(this, "lastPage", null);

      _defineProperty(this, "version", null);

      _defineProperty(this, "sortProperties", null);

      _defineProperty(this, "addLabel", null);

      _defineProperty(this, "addLink", null);

      _defineProperty(this, "editRoute", null);

      _defineProperty(this, "formFields", null);

      _defineProperty(this, "originalContent", null);

      _defineProperty(this, "editContent", null);

      _defineProperty(this, "pagedContent", null);

      _defineProperty(this, "firstContent", null);

      _defineProperty(this, "include", null);

      _defineProperty(this, "splitIndex", 10000);

      _defineProperty(this, "showAutocompleteFilters", false);

      _defineProperty(this, "filterClass", null);

      _defineProperty(this, "showMoreTrigger", true);

      _defineProperty(this, "_addFormFields", null);

      _defineProperty(this, "editAction", null);

      _defineProperty(this, "addAction", null);

      _defineProperty(this, "query", null);

      _defineProperty(this, "searching", false);

      _defineProperty(this, "sortAscending", true);

      _defineProperty(this, "canAdd", true);

      _defineProperty(this, "rowCount", null);

      _initializerDefineProperty(this, "limit", _descriptor4, this);

      _defineProperty(this, "limitOptions", Ember.A([10, 15, 25, 50, 100]));

      _initializerDefineProperty(this, "page", _descriptor5, this);

      _defineProperty(this, "filters", null);

      if (Ember.isNone(this.newModels)) {
        this.newModels = [];
      }

      this.self = this;

      if (Ember.isNone(this.columns)) {
        this.columns = [0, 1, 2, 3, 4, 5];
      }

      if (Ember.isNone(this.args.sortProperties) && (Ember.isNone(this.args.dataVariables) || Ember.isNone(this.args.dataVariables.orderBy))) {
        // default order
        this.sortProperty = "id";
      } // if (isNone(this.limit) && (isNone(this.args.dataVariables) || isNone(this.args.dataVariables.limit))) {
      // 	// default order
      // 	this.limit = 15;
      // }

    }

    // get activeFilters() {
    // 	return this.filters ? this.filters.filter(filter => {
    // 		return !isNone(filter.selected);
    // 	}) : [];
    // }
    //multiple actioncolumns
    // get actionColumns() {
    // 	return [
    // 		{
    // 			name: this.actionColumnName,
    // 			action: this.actionColumnAction,
    // 		},
    // 	];
    // }
    // hasAutocompleteFilter: computed("filters.@each.isTypeahead", function() {
    // 	let hasFilter = false;
    // 	if (this.get("filters.length") > 0) {
    // 		this.filters.forEach(filter => {
    // 			if (filter.isTypeahead) {
    // 				hasFilter = true;
    // 			}
    // 		});
    // 	}
    // 	return hasFilter;
    // }),
    get firstItem() {
      return this.offset + 1;
    }

    get lastItem() {
      return this.page * this.limit;
    }

    get offset() {
      return (this.page - 1) * this.limit || 0;
    }

    get dataContent() {
      const variables = this.args.dataVariables || {};
      if (!variables.limit) variables.limit = this.limit;
      variables.offset = this.offset;

      if (this.searchValue !== undefined) {
        variables.search = "%" + this.searchValue + "%";
      } else {
        variables.search = "%%";
      }

      if (this.filters && this.filters.length > 0) {
        this.filters.forEach(filter => {
          if (!Ember.isNone(filter.selected)) {
            variables[filter.queryField] = filter.selected.id;
          } else {
            delete variables[filter.queryField];
          }
        });
      }

      if (!Ember.isNone(this.presetFilters)) {
        Object.assign(variables, this.presetFilters);
      } // Todo, sort on server (we now sort on given sort property)
      // if (this.get("sortProperties")) {
      // 	// new sort property set by user, let's get them in the query
      // 	const orderBy = {};
      // 	const orderRelations = this.get("sortProperties")
      // 		.objectAt(0)
      // 		.split(".");
      // 	// TODO sorting up to three nested relations (need more? make this recursive or something fancy :)
      // 	const i = orderRelations.get("length");
      // 	if (i === 1) {
      // 		orderBy[orderRelations.objectAt(0)] = this.get("sortAscending") ? "asc" : "desc";
      // 	} else if (i === 2) {
      // 		orderBy[orderRelations.objectAt(0)] = {};
      // 		orderBy[orderRelations.objectAt(0)][orderRelations.objectAt(1)] = this.get("sortAscending") ? "asc" : "desc";
      // 	} else if (i === 3) {
      // 		orderBy[orderRelations.objectAt(0)] = {};
      // 		orderBy[orderRelations.objectAt(0)][orderRelations.objectAt(1)] = {};
      // 		orderBy[orderRelations.objectAt(0)][orderRelations.objectAt(1)][orderRelations.objectAt(2)] = this.get(
      // 			"sortAscending"
      // 		)
      // 			? "asc"
      // 			: "desc";
      // 	}
      // 	// merge the variables we got with the new sort variables
      // 	variables.orderBy = orderBy;
      // }


      return this.apollo.query({
        query: this.args.dataQuery,
        variables
      }).then(gqlresult => {
        const aggregateProperty = this.args.type + "_aggregate";
        let rowCount; // eslint-disable-next-line no-prototype-builtins

        if (gqlresult.hasOwnProperty(aggregateProperty)) {
          rowCount = gqlresult[aggregateProperty].aggregate.count;
        }

        let resultSet;

        if (this.args.mapToType) {
          resultSet = gqlresult[this.args.type].map(resultObject => {
            return this.args.mapToType.create(resultObject);
          });
        } else {
          resultSet = gqlresult[this.args.type];
        }

        return {
          rows: resultSet,
          rowCount
        };
      }).catch(error => {
        console.error(error);
      });
    }

    fixAutoCompleters() {
      if (this.args.formFields) {
        this.args.formFields.forEach(formField => {
          if (!Ember.isNone(formField.model) || !Ember.isNone(formField.data)) {// set(formField, "selected", this.get("editContent." + formField.selectionField));
          }
        });
      }
    } // show edit in dialog


    showEditDialog(clickedRow) {
      this.set("editContent", clickedRow);
      this.set("canAdd", false);
      this.fixAutoCompleters();
      return false;
    }

    closeDialog() {
      this.set("editContent", null);
    }

    search(modelName, query) {
      // search should send params to the gql query
      return this.searchService.search(modelName, query);
    }

    add() {
      if (this.args.addAction) {
        this.sendAction("addAction");
      } else {
        this.set("splitIndex", 10000); //set editContent for new model

        this.set("editContent", Ember.Object.create({}));
        this.fixAutoCompleters();
        this.set("canAdd", false);
      }
    }

    editModel(index, row) {
      if (this.args.editAction) {
        this.sendAction("editAction", row);
      } else if (this.args.editModel) {
        this.sendAction("editModel", index, row);
      } else {
        if (this.args.formFields) {
          this.showEditDialog(row);
        }
      }
    }

    back() {
      this.router.transitionTo(this.back);
    } // @action
    // actionColumnAction(row, action) {
    // 	this.sendAction("actionColumnAction", row, action);
    // }


    saveRow() {
      //set the new selected fields back to the ember object
      this.args.formFields.forEach(formField => {
        this.set("editContent." + formField.selectionField, formField.selected);
      });
      const isNew = this.editContent.isNew;
      return this.editContent.save().then(savedModel => {
        this.set("editContent", null);

        if (isNew) {
          this.newModels.pushObject(savedModel);
        }

        this.set("canAdd", true);
        return false;
      });
    } // TODO what is a 'discard'? Can we do a reset or refreh on the apollo object or query?


    discardRow() {
      // this.get("editContent").rollbackAttributes();
      this.set("editContent", null);
      this.set("canAdd", true);
      return false;
    }

    decrementPage() {
      const page = this.page;

      if (page > 1) {
        this.page = this.page - 1;
      }
    }

    async incrementPage() {
      const data = await this.dataContent;
      const pages = [];
      const pageCount = data.rowCount / this.limit;

      for (let i = 1; i <= pageCount; i++) {
        pages.push(i);
      }

      const page = this.page;
      const max = pages.reduce((prev, curr) => curr > prev ? curr : prev, 0);

      if (page < max) {
        this.page = this.page + 1;
      }
    }

    firstPage() {
      this.page = 1;
    }

    async lastPage() {
      const data = await this.dataContent;
      this.page = Math.ceil(data.rowCount / this.limit);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "searchService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 15;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "closeDialog", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "closeDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editModel", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "editModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "back", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveRow", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "saveRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discardRow", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "discardRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "decrementPage", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "decrementPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "incrementPage", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "incrementPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "firstPage", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "firstPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "lastPage", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "lastPage"), _class.prototype)), _class));
  _exports.default = GqlTable;
});