define("atcm-client/templates/components/type-ahead-prev", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xOUv+oDc",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n    \"],[8,\"input\",[[16,0,[34,0]]],[[\"@disabled\",\"@value\"],[true,[34,3]]],null],[2,\"\\n    \"],[10,\"span\"],[14,0,\"input-group-addon\"],[12],[2,\"\\n      \"],[11,\"i\"],[24,0,\"fa fa-close text-warning\"],[4,[38,4],[[32,0],\"clear\"],null],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"input\",[[16,0,[34,0]],[16,\"placeholder\",[34,1]]],[[\"@disabled\"],[[34,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"class\",\"placeholder\",\"disabled\",\"selectedDisplayValue\",\"action\",\"selection\",\"if\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/components/type-ahead-prev.hbs"
    }
  });

  _exports.default = _default;
});