define("atcm-client/templates/client/analyses/blends", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f0SlJLHn",
    "block": "{\"symbols\":[],\"statements\":[[2,\"Blends\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/analyses/blends.hbs"
    }
  });

  _exports.default = _default;
});