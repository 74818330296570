define("atcm-client/controllers/client/staticdata/securitystatics/entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.currencyQueryFields = ["description", "isoCode"];
      this.countryQueryFields = ["description", "isoThree", "isoTwo"];
      this.issuerStaticQueryFields = ["description", "reutersCode"];
      this.fieldsToClearInstrumentType = ["couponFrequency", "couponDaycount"];
    },

    isFix: Ember.computed("model.instrumentType.assetClass.descriptionShort", function () {
      if (this.get("model.instrumentType.assetClass.descriptionShort") === "FIX") {
        return true;
      } else {
        this.set("model.couponFrequency", null);
        this.set("model.couponDaycount", null);
        return false;
      }
    }),
    actions: {
      save: function () {
        const self = this;
        this.model.save().then(securityStatic => {
          self.transitionToRoute("staticdata.securitystatics.edit", securityStatic);
        });
      },
      rollback: function () {
        const self = this;
        this.model.rollbackAttributes();
        self.transitionToRoute("staticdata.securitystatics");
      }
    }
  });

  _exports.default = _default;
});