define("atcm-client/templates/components/d3-sunburst", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E/qwhJvm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,1,\"explanation\"],[14,0,\"layout-row\"],[12],[2,\"\\n    \"],[1,[34,0]],[2,\" / \"],[1,[34,1]],[2,\"%\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"sunburst text-center\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"visibleName\",\"visiblePercentage\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/components/d3-sunburst.hbs"
    }
  });

  _exports.default = _default;
});