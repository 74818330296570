define("atcm-client/components/modal-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    title: null,
    subtitle: null,
    id: null,
    appendModal: true
  });

  _exports.default = _default;
});