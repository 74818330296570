define("atcm-client/templates/client/reports/accountingjournals/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tat6Y4aW",
    "block": "{\"symbols\":[],\"statements\":[[8,\"simple-table\",[],[[\"@content\",\"@title\",\"@type\",\"@fields\",\"@filters\",\"@formFields\"],[[34,0],\"Accounting Journals\",\"accountingJournal\",[34,1],[34,2],[34,3]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"tableFields\",\"tableFilters\",\"formFields\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/reports/accountingjournals/index.hbs"
    }
  });

  _exports.default = _default;
});