define("atcm-client/models/issuer-impact", ["exports", "atcm-client/models/base-model", "moment"], function (_exports, _baseModel, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceReportDate: Ember.computed("reportDate", function () {
      if (this.reportDate) {
        return (0, _moment.default)(this.reportDate).format("DD-MM-YYYY");
      }
    }),
    formReportDate: Ember.computed("observationDate", function () {
      if (this.reportDate) {
        return (0, _moment.default)(this.reportDate).format("YYYY-MM-DD");
      }
    })
  });

  _exports.default = _default;
});