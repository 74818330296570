define("atcm-client/models/fiscal-country", ["exports", "atcm-client/models/country"], function (_exports, _country) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _country.default.extend({});

  _exports.default = _default;
});