define("atcm-client/controllers/client/staticdata/referencerates/intereststatics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.formFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true
      }, {
        displayName: "Short Description",
        displayField: "descriptionShort",
        alignLeft: true
      }, {
        displayName: "Tenor",
        displayField: "tenor.description"
      }, {
        displayName: "RIC Code",
        displayField: "ricCode"
      }, {
        displayName: "Ticker Code",
        displayField: "tickerCode"
      }, {
        displayName: "Currency",
        displayField: "currency.isoCode"
      }, {
        displayName: "Daycount convention",
        displayField: "couponDaycount.descriptionShort"
      }, {
        displayName: "Coupon Frequency",
        displayField: "couponFrequency.descriptionShort"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short",
        displayField: "descriptionShort",
        required: true
      }, {
        displayName: "ISIN Code",
        displayField: "isinCode"
      }, {
        displayName: "Figi Code",
        displayField: "figiCode"
      }, {
        displayName: "RIC Code",
        displayField: "ricCode"
      }, {
        displayName: "Ticker Code",
        displayField: "tickerCode"
      }, {
        displayName: "Tenor",
        selectionField: "tenor",
        displayField: "description",
        model: "currencies",
        queryFields: ["description", "tenorCode"],
        required: true
      }, {
        displayName: "Currency",
        selectionField: "currency",
        displayField: "isoCode",
        model: "currencies",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Coupon daycount",
        selectionField: "couponDaycount",
        displayField: "description",
        model: "couponDaycounts",
        required: true
      }, {
        displayName: "Coupon frequency",
        selectionField: "couponFrequency",
        displayField: "descriptionShort",
        model: "couponFrequencies",
        required: true
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Interest points of curve: " + this.get("model.descriptionShort");
    })
  });

  _exports.default = _default;
});