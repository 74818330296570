define("atcm-client/controllers/client/staticdata/exchangerates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Tenor",
        displayField: "tenor.tenorCode",
        alignLeft: true
      }, {
        displayName: "Pricing Option",
        displayField: "pricingOption.descriptionShort"
      }, {
        displayName: "Dominant Currency",
        displayField: "dominantCurrency.isoCode"
      }, {
        displayName: "Counter Currency",
        displayField: "counterCurrency.isoCode"
      }, {
        displayName: "Spot Rate",
        displayField: "spotRate"
      }, {
        displayName: "Value Date",
        displayField: "niceRateTimestamp"
      }, {
        displayName: "Data Vendor",
        displayField: "datavendorStatic.descriptionShort"
      }];
      this.tableFilters = [{
        displayName: "Dominant Currency",
        displayField: "currencies",
        queryField: "dominantCurrency",
        displayType: "typeahead",
        queryFields: ["description", "isoCode"],
        isTypeahead: true
      }, {
        displayName: "Counter Currency",
        displayField: "currencies",
        queryField: "counterCurrency",
        displayType: "typeahead",
        queryFields: ["description", "isoCode"],
        isTypeahead: true
      }, {
        displayName: "Tenor",
        displayField: "tenors",
        queryField: "tenor",
        displayType: "typeahead",
        queryFields: ["description", "tenorCode"],
        isTypeahead: true
      }, {
        displayName: "Datavendor",
        displayField: "datavendorStatics",
        queryField: "datavendorStatic",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }, {
        displayName: "Pricing Option",
        displayField: "pricingOptions",
        queryField: "pricingOption",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Tenor",
        selectionField: "tenor",
        displayField: "description",
        model: "tenors",
        queryFields: ["description"],
        required: true
      }, {
        displayName: "Pricing option",
        selectionField: "pricingOption",
        displayField: "description",
        model: "pricingOptions",
        queryFields: ["description", "descriptionShort"],
        required: true
      }, {
        displayName: "Dominant currency",
        selectionField: "dominantCurrency",
        displayField: "description",
        model: "currencies",
        queryFields: ["description", "isoCode"],
        required: true
      }, {
        displayName: "Counter currency",
        selectionField: "counterCurrency",
        displayField: "description",
        model: "currencies",
        queryFields: ["description", "isoCode"],
        required: true
      }, {
        displayName: "Spot rate",
        displayField: "spotRate",
        required: true
      }, {
        displayName: "Inverse spot rate",
        displayField: "calculatedSpotRateInverse"
      }, {
        displayName: "Timestamp rate",
        displayField: "niceRateTimestamp",
        date: true
      }, {
        displayName: "Datavendor",
        selectionField: "datavendorStatic",
        displayField: "description",
        model: "datavendorStatics",
        queryFields: ["description", "descriptionShort"],
        required: true
      }];
    },

    editRoute: null
  });

  _exports.default = _default;
});