define("atcm-client/gql/queries/competitorsAnalysis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "variableDefinitions": [],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "ibor_assetManagers"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "id"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "description"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "managedFunds"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "description"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "managedFundHoldings"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "where"
                    },
                    "value": {
                      "kind": "ObjectValue",
                      "fields": [{
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "legalEntityIdentifier"
                        },
                        "value": {
                          "kind": "ObjectValue",
                          "fields": [{
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "_eq"
                            },
                            "value": {
                              "kind": "StringValue",
                              "value": "PY6ZZQWO2IZFZC3IOL08",
                              "block": false
                            }
                          }]
                        }
                      }]
                    }
                  }],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "assetClass"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "instrumentType"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "description"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "marketValueAccountCurrency"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "currencyIso"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "currency"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "isoCode"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "company"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "description"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "legalEntityIdentifier"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "sectorActivity"
                          },
                          "arguments": [],
                          "directives": [],
                          "selectionSet": {
                            "kind": "SelectionSet",
                            "selections": [{
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "description"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "sectorIndustrySub"
                              },
                              "arguments": [],
                              "directives": [],
                              "selectionSet": {
                                "kind": "SelectionSet",
                                "selections": [{
                                  "kind": "Field",
                                  "name": {
                                    "kind": "Name",
                                    "value": "id"
                                  },
                                  "arguments": [],
                                  "directives": []
                                }, {
                                  "kind": "Field",
                                  "name": {
                                    "kind": "Name",
                                    "value": "description"
                                  },
                                  "arguments": [],
                                  "directives": []
                                }, {
                                  "kind": "Field",
                                  "name": {
                                    "kind": "Name",
                                    "value": "sectorIndustry"
                                  },
                                  "arguments": [],
                                  "directives": [],
                                  "selectionSet": {
                                    "kind": "SelectionSet",
                                    "selections": [{
                                      "kind": "Field",
                                      "name": {
                                        "kind": "Name",
                                        "value": "id"
                                      },
                                      "arguments": [],
                                      "directives": []
                                    }, {
                                      "kind": "Field",
                                      "name": {
                                        "kind": "Name",
                                        "value": "description"
                                      },
                                      "arguments": [],
                                      "directives": []
                                    }, {
                                      "kind": "Field",
                                      "name": {
                                        "kind": "Name",
                                        "value": "sectorGroup"
                                      },
                                      "arguments": [],
                                      "directives": [],
                                      "selectionSet": {
                                        "kind": "SelectionSet",
                                        "selections": [{
                                          "kind": "Field",
                                          "name": {
                                            "kind": "Name",
                                            "value": "id"
                                          },
                                          "arguments": [],
                                          "directives": []
                                        }, {
                                          "kind": "Field",
                                          "name": {
                                            "kind": "Name",
                                            "value": "description"
                                          },
                                          "arguments": [],
                                          "directives": []
                                        }]
                                      }
                                    }]
                                  }
                                }]
                              }
                            }]
                          }
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "companyFundamentalsByLEI"
                          },
                          "arguments": [{
                            "kind": "Argument",
                            "name": {
                              "kind": "Name",
                              "value": "where"
                            },
                            "value": {
                              "kind": "ObjectValue",
                              "fields": []
                            }
                          }],
                          "directives": [],
                          "selectionSet": {
                            "kind": "SelectionSet",
                            "selections": [{
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "value"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "ratio"
                              },
                              "arguments": [],
                              "directives": [],
                              "selectionSet": {
                                "kind": "SelectionSet",
                                "selections": [{
                                  "kind": "Field",
                                  "name": {
                                    "kind": "Name",
                                    "value": "id"
                                  },
                                  "arguments": [],
                                  "directives": []
                                }, {
                                  "kind": "Field",
                                  "name": {
                                    "kind": "Name",
                                    "value": "description"
                                  },
                                  "arguments": [],
                                  "directives": []
                                }, {
                                  "kind": "Field",
                                  "name": {
                                    "kind": "Name",
                                    "value": "ratioGroupSub"
                                  },
                                  "arguments": [],
                                  "directives": [],
                                  "selectionSet": {
                                    "kind": "SelectionSet",
                                    "selections": [{
                                      "kind": "Field",
                                      "name": {
                                        "kind": "Name",
                                        "value": "id"
                                      },
                                      "arguments": [],
                                      "directives": []
                                    }, {
                                      "kind": "Field",
                                      "name": {
                                        "kind": "Name",
                                        "value": "description"
                                      },
                                      "arguments": [],
                                      "directives": []
                                    }, {
                                      "kind": "Field",
                                      "name": {
                                        "kind": "Name",
                                        "value": "ratioGroupMain"
                                      },
                                      "arguments": [],
                                      "directives": [],
                                      "selectionSet": {
                                        "kind": "SelectionSet",
                                        "selections": [{
                                          "kind": "Field",
                                          "name": {
                                            "kind": "Name",
                                            "value": "id"
                                          },
                                          "arguments": [],
                                          "directives": []
                                        }, {
                                          "kind": "Field",
                                          "name": {
                                            "kind": "Name",
                                            "value": "description"
                                          },
                                          "arguments": [],
                                          "directives": []
                                        }, {
                                          "kind": "Field",
                                          "name": {
                                            "kind": "Name",
                                            "value": "ratioGroup"
                                          },
                                          "arguments": [],
                                          "directives": [],
                                          "selectionSet": {
                                            "kind": "SelectionSet",
                                            "selections": [{
                                              "kind": "Field",
                                              "name": {
                                                "kind": "Name",
                                                "value": "id"
                                              },
                                              "arguments": [],
                                              "directives": []
                                            }, {
                                              "kind": "Field",
                                              "name": {
                                                "kind": "Name",
                                                "value": "description"
                                              },
                                              "arguments": [],
                                              "directives": []
                                            }]
                                          }
                                        }]
                                      }
                                    }]
                                  }
                                }]
                              }
                            }]
                          }
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "latestCompanyImpactTotals"
                          },
                          "arguments": [],
                          "directives": [],
                          "selectionSet": {
                            "kind": "SelectionSet",
                            "selections": [{
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "year"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "reportDate"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "impactObjectiveMain"
                              },
                              "arguments": [],
                              "directives": [],
                              "selectionSet": {
                                "kind": "SelectionSet",
                                "selections": [{
                                  "kind": "Field",
                                  "name": {
                                    "kind": "Name",
                                    "value": "id"
                                  },
                                  "arguments": [],
                                  "directives": []
                                }, {
                                  "kind": "Field",
                                  "name": {
                                    "kind": "Name",
                                    "value": "description"
                                  },
                                  "arguments": [],
                                  "directives": []
                                }]
                              }
                            }]
                          }
                        }]
                      }
                    }]
                  }
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 1116
    }
  };
  var _default = doc;
  _exports.default = _default;
});