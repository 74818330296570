define("atcm-client/controllers/client/staticdata/securitylistings/entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFilters = [{
        displayName: "Security Static",
        displayField: "securityStatic",
        queryField: "securityStatic",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort", "marketIdCode", "figiCode"],
        isTypeahead: true
      }, {
        displayName: "Exchange",
        displayField: "exchangeStatic",
        queryField: "exchangeStatic",
        displayType: "typeahead",
        queryFields: ["description", "marketIdCode"],
        isTypeahead: true
      }, {
        displayName: "Currency",
        displayField: "currency",
        queryField: "currency",
        displayType: "typeahead",
        queryFields: ["isoCode", "description"],
        isTypeahead: true
      }];
    },

    securityStaticPlaceholder: Ember.computed("securityStatic", function () {
      return Ember.isNone(this.get("securityStatic.id")) ? "Search for an security static..." : this.get("securityStatic.niceString");
    }),
    exchangeStaticPlaceholder: Ember.computed("exchangeStatic", function () {
      return Ember.isNone(this.get("exchangeStatic.id")) ? "Search for an exchange..." : this.get("exchangeStatic.niceString");
    }),
    currencyPlaceholder: Ember.computed("currency", function () {
      return Ember.isNone(this.get("currency.id")) ? "Search for a currency..." : this.get("currency.niceString");
    }),
    actions: {
      save: function () {
        const self = this;
        this.model.save().then(() => {
          self.transitionToRoute("staticdata.securitystatics");
        });
      },
      rollback: function () {
        const self = this;
        this.model.rollbackAttributes();
        self.transitionToRoute("staticdata.securitystatics");
      }
    }
  });

  _exports.default = _default;
});