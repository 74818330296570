define("atcm-client/controllers/client/marketanalyses/companyimpactperformances/index", ["exports", "atcm-client/gql/search/companyImpactPerformances"], function (_exports, _companyImpactPerformances) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
      this.tableFields = [{
        name: "Company",
        valuePath: "company.description"
      }, {
        name: "Year",
        valuePath: "year"
      }, // {
      // 	name: "Framework",
      // 	valuePath: "impactObjectiveMain.impactObjective.descriptionShort",
      // },
      {
        name: "Impact Objective",
        valuePath: "impactObjectiveMain.descriptionShort"
      }, // {
      // 	name: "Legal Enitity Identifier",
      // 	valuePath: "legalEntityIdentifier",
      // },
      {
        name: "Document",
        valuePath: "document"
      }, {
        name: "Text identified",
        valuePath: "description"
      }, {
        name: "Page",
        valuePath: "pageId"
      }, {
        name: "Score",
        valuePath: "score"
      }, {
        name: "Diversity",
        valuePath: "diversity"
      } // {
      // 	name: "Paragraph",
      // 	valuePath: "pageId",
      // },
      ];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Legal Entity Identifier",
        displayField: "legalEntityIdentifier",
        required: true
      }];
    },

    actions: {
      fetchMore() {
        if (this.get("model.companyImpactPerformances.length") % 10 === 0) {
          const variables = {
            limit: 10,
            offset: this.get("model.companyImpactPerformances.length")
          };
          this.apollo.query({
            query: _companyImpactPerformances.default,
            variables
          }).then(result => {
            this.get("model.companyImpactPerformances").pushObjects(result.companyImpactPerformances);
          });
        }
      }

    }
  });

  _exports.default = _default;
});