define("atcm-client/controllers/client/staticdata/funds/securityratings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Rating",
        displayField: "ratingStatic.description",
        flex: 15,
        alignLeft: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true
      }, {
        displayName: "Rating",
        selectionField: "ratingStatic",
        displayField: "description",
        model: "ratingStatics",
        queryFields: ["description", "descriptionShort"]
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Security Ratings of " + this.get("model.descriptionShort");
    })
  });

  _exports.default = _default;
});