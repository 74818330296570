define("atcm-client/controllers/client/core", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AdminCoreController = Ember.Controller.extend({
    expandedItem: null,
    actions: {
      toggleExpandedItem(value, ev) {
        let newValue = value;

        if (this.expandedItem === value) {
          newValue = null;
        }

        this.set("expandedItem", newValue);
        ev.stopPropagation();
      }

    },
    userAccessExpanded: Ember.computed.equal("expandedItem", "userAccess"),
    esgExpanded: Ember.computed.equal("expandedItem", "esgConfig"),
    sectorExpanded: Ember.computed.equal("expandedItem", "sectorConfig"),
    valuationStaticsExpanded: Ember.computed.equal("expandedItem", "valuationStatics")
  });
  var _default = AdminCoreController;
  _exports.default = _default;
});