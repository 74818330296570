define("atcm-client/controllers/client/staticdata/exchangestatics/index", ["exports", "atcm-client/gql/queries/exchanges"], function (_exports, _exchanges) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true
      }, {
        displayName: "Short",
        displayField: "descriptionShort"
      }, {
        displayName: "Market ID",
        displayField: "marketIdCode"
      }, {
        displayName: "Country",
        displayField: "country.isoThree"
      }, {
        displayName: "City",
        displayField: "city.description"
      }, {
        displayName: "Exchange Type",
        displayField: "exchangeType"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Country",
        displayField: "countries",
        queryField: "country",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "City",
        displayField: "cities",
        queryField: "city",
        displayType: "typeahead",
        isTypeahead: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short",
        displayField: "descriptionShort",
        required: true
      }, {
        displayName: "Country",
        selectionField: "country",
        displayField: "description",
        model: "countries",
        required: true
      }, {
        displayName: "City",
        selectionField: "city",
        displayField: "description",
        model: "cities",
        queryParams: {
          calculate: true,
          country: "country.id",
          limit: -1
        },
        required: true
      }, {
        displayName: "Market ID Code",
        displayField: "marketIdCode"
      }, {
        displayName: "Swift Code",
        displayField: "swiftCode"
      }, {
        displayName: "Decimals Pricing",
        displayField: "decimalsPricing"
      }, {
        displayName: "Exchange Type",
        displayField: "exchangeType"
      }, {
        displayName: "Website",
        displayField: "website"
      }, {
        displayName: "Information",
        displayField: "information"
      }];
    },

    fetchMore() {
      if (this.get("model.exchangeStatics.length") % 10 === 0) {
        const variables = {
          limit: 10,
          offset: this.get("model.exchangeStatics.length")
        };
        this.apollo.query({
          query: _exchanges.default,
          variables
        }).then(result => {
          this.get("model.exchangeStatics").pushObjects(result.exchangeStatics);
        });
      }
    },

    editRoute: null
  });

  _exports.default = _default;
});