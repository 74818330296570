define("atcm-client/models/foreign-exchange-contract", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    calculatedExchangeRateContract: Ember.computed("payAmount", "receiveAmount", function () {
      if (!Ember.isNone(this.payAmount) && !Ember.isNone(this.receiveAmount)) {
        return (parseFloat(this.receiveAmount) / parseFloat(this.payAmount)).toFixed(12);
      }

      return;
    }),
    calculatedExchangeRateContractAvailable: Ember.observer("calculatedExchangeRateContract", function () {
      if (!Ember.isNone(this.calculatedExchangeRateContract)) {
        this.set("exchangeRate", this.calculatedExchangeRateContract);
      }
    }),
    niceString: Ember.computed("accountMaster", "instrumentType", "contractNumber", function () {
      return this.accountMaster + " - " + this.instrumentType + " - " + this.contractNumber;
    })
  });

  _exports.default = _default;
});