define("atcm-client/initializers/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    Ember.Debug.registerDeprecationHandler((message, options, next) => {
      if (options && options.until && (options.until === "4.0.0" || options.until === "3.0.0")) {
        return;
      } else {
        next(message, options);
      }
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});