define("atcm-client/routes/client/marketanalyses/index", ["exports", "atcm-client/routes/secure"], function (_exports, _secure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const variables = {
    orderBy: {
      description: "asc"
    }
  };

  var _default = _secure.default.extend({
    model: function () {
      return;
    },
    setupController: function (controller) {
      controller.set("dataVariables", variables);
    }
  });

  _exports.default = _default;
});