define("atcm-client/models/ratio", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceString: Ember.computed("description", "descriptionShort", function () {
      return this.description + " - " + this.descriptionShort;
    }),
    fullString: Ember.computed("description", "ratioGroupSub.{ratioGroupMain.ratioGroup.descriptionShort,ratioGroupMain.descriptionShort,descriptionShort}", function () {
      return this.description + " - (" + this.get("ratioGroupSub.ratioGroupMain.ratioGroup.descriptionShort") + " - " + this.get("ratioGroupSub.ratioGroupMain.descriptionShort") + " - " + this.get("ratioGroupSub.descriptionShort") + " - " + this.get("ratio.description)");
    })
  });

  _exports.default = _default;
});