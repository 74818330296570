define("atcm-client/controllers/client/staticdata/futuresstatics/futuresstaticdeliverymonths", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Futures Static",
        displayField: "futuresStatic.descriptionShort",
        alignLeft: true
      }, {
        displayName: "Delivery Year",
        displayField: "deliveryYear"
      }, {
        displayName: "Delivery Month",
        displayField: "deliveryMonth"
      }, {
        displayName: "Ticker code",
        displayField: "tickerCode"
      }, {
        displayName: "Figi Code",
        displayField: "figiCode"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true
      }, {
        displayName: "Delivery Year",
        displayField: "deliveryYear"
      }, {
        displayName: "Delivery Month",
        displayField: "deliveryMonth"
      }, {
        displayName: "Ticker Code",
        displayField: "tickerCode"
      }, {
        displayName: "Figi Code",
        displayField: "figiCode"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Futures Delivery Months of " + this.get("model.descriptionShort");
    })
  });

  _exports.default = _default;
});