define("atcm-client/controllers/client/transactions/orders/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Status",
        displayField: "statusTransaction.descriptionShort"
      }, {
        displayName: "Order ID",
        displayField: "id"
      }, {
        displayName: "Assetclass",
        displayField: "assetClass.descriptionShort"
      }, {
        displayName: "Instrument Type",
        displayField: "instrumentType.descriptionShort"
      }, {
        displayName: "Event",
        displayField: "eventType.descriptionShort"
      }, {
        displayName: "Security Identifier",
        displayField: "securityStatic.ricCode"
      }];
    }

  });

  _exports.default = _default;
});