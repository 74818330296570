define("atcm-client/controllers/client/staticdata/issuerstatics/ratings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.include = ["ratingStatic"];
      this.tableFields = [{
        displayName: "Date",
        displayField: "createdAt",
        alignLeft: true
      }, {
        displayName: "Long Rating",
        displayField: "longRatingStatic.description"
      }, {
        displayName: "Short Rating",
        displayField: "shortRatingStatic.description"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true
      }, {
        displayName: "Rating",
        selectionField: "ratingStatic",
        displayField: "description",
        model: "ratingStatics",
        queryFields: ["description", "descriptionShort"]
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Ratings of issuer " + this.model.description;
    })
  });

  _exports.default = _default;
});