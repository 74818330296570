define("atcm-client/controllers/client/transactions/foreignexchangecontracts/entry", ["exports", "atcm-client/enums/deal-purposes"], function (_exports, _dealPurposes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.currencyQueryFields = ["description", "isoCode"];
      this.fieldsToClearCounterpartyStatic = ["lastCounterpartyStaticRating"];
    },

    dealPurposes: _dealPurposes.default,
    instrumentTypeQueryParams: null,
    lastCounterpartyStaticRating: null,
    lastExchangeRate: null,
    _assetClassSelected: Ember.observer("model.assetClass.id", function () {
      if (!Ember.isNone(this.get("model.assetClass.id"))) {
        this.set("instrumentTypeQueryParams", {
          assetClass: this.get("model.assetClass.id"),
          csType: "Contract",
          limit: -1
        });
      } else {
        //clear other fields
        this.set("selectedInstrumentType", null);
        this.set("errors", []);
      }
    }),
    _instrumentTypeSelected: Ember.observer("model.instrumentType.id", function () {
      if (!Ember.isNone(this.get("model.instrumentType.id"))) {
        this.set("securityStaticQueryParams", {
          instrumentType: this.get("model.instrumentType.id"),
          limit: -1
        });
      } else {
        //clear other fields
        //  this.set('selectedPayCurrency', null);
        this.set("errors", []);
      }
    }),
    _currenciesSelected: Ember.observer("model.payCurrency.id", "model.receiveCurrency.id", function () {
      if (this.get("model.payCurrency.id") && this.get("model.receiveCurrency.id")) {
        this.getLastExchangeRate(this.get("model.payCurrency.id"), this.get("model.receiveCurrency.id")).then(exchangeRates => {
          if (exchangeRates.get("length") === 1) {
            this.set("model.lastExchangeRate", exchangeRates.objectAt(0).get("spotRate"));
          }
        });
      } else {
        //clear the retrieved rating field
        this.set("lastExchangeRate", null);
      }
    }),
    _counterpartyStaticSelected: Ember.observer("model.counterpartyStatic.id", function () {
      if (this.get("model.counterpartyStatic.id")) {
        this.getCounterpartyStaticRating(this.get("model.counterpartyStatic.id")).then(counterpartyStaticRatings => {
          if (counterpartyStaticRatings.get("length") === 1) {
            this.set("model.lastCounterpartyStaticRating", counterpartyStaticRatings.objectAt(0).get("shortRatingStatic.description"));
          }
        });
      } else {
        //clear the retrieved rating field
        this.set("lastCounterpartyStaticRating", null);
      }
    }),
    getCounterpartyStaticRating: function (counterpartyStaticId) {
      return this.store.find("counterpartyStaticRating", {
        counterpartyStatic: counterpartyStaticId,
        orderBy: "ratingDate",
        order: "desc",
        limit: 1
      });
    },
    isForeignExchangeForward: Ember.computed("model.instrumentType.descriptionShort", function () {
      if (this.get("model.instrumentType.descriptionShort") === "Fwd") {
        return true;
      } else {
        this.set("model.maturityDate", null);
        return false;
      }
    }),
    getLastExchangeRate: function (payCurrencyId, receiveCurrencyId) {
      return this.store.query("exchangeRate", {
        dominantCurrency: payCurrencyId,
        counterCurrency: receiveCurrencyId,
        orderBy: "rateTimestamp",
        order: "desc",
        limit: 1
      });
    },
    actions: {
      save: function () {
        const self = this;
        this.content.save().then(foreignExchangeContract => {
          self.transitionToRoute("trading.foreignexchangecontracts.edit", foreignExchangeContract);
        });
      },
      rollback: function () {
        const self = this;
        this.content.rollbackAttributes();
        self.transitionToRoute("trading.foreignexchangecontracts");
      }
    }
  });

  _exports.default = _default;
});