define("atcm-client/controllers/client/staticdata/holidaycalendarmains/holidaycalendarsubs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.formFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true
      }, {
        displayName: "Non Business Day",
        displayField: "niceNonBusinessDay"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Non Business day",
        displayField: "nonBusinessDay",
        required: true,
        date: true
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Business holiday's in calendar of: " + this.get("model.descriptionShort");
    })
  });

  _exports.default = _default;
});