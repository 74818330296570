define("atcm-client/controllers/client/staticdata/holidaycalendarmains/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        flex: 15,
        alignLeft: true
      }, {
        displayName: "Short description",
        displayField: "descriptionShort"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short description",
        displayField: "descriptionShort",
        required: true
      }, {
        displayName: "Reg. Closed Monday",
        displayField: "regularClosedMonday",
        checkbox: true
      }, {
        displayName: "Reg. Closed Tuesday",
        displayField: "regularClosedTuesday",
        checkbox: true
      }, {
        displayName: "Reg. Closed Wednesday",
        displayField: "regularClosedWednesday",
        checkbox: true
      }, {
        displayName: "Reg. Closed Thursday",
        displayField: "regularClosedThursday",
        checkbox: true
      }, {
        displayName: "Reg. Closed Friday",
        displayField: "regularClosedFriday",
        checkbox: true
      }, {
        displayName: "Reg. Closed Saturday",
        displayField: "regularClosedSaturday",
        checkbox: true
      }, {
        displayName: "Reg. Closed Sunday",
        displayField: "regularClosedSunday",
        checkbox: true
      }];
    },

    actions: {
      showHolidayCalendarSubs: function (holidayCalendarMain) {
        this.transitionToRoute("client.staticdata.holidaycalendarmains.holidaycalendarsubs", holidayCalendarMain);
        return false;
      }
    }
  });

  _exports.default = _default;
});