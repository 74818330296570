define("atcm-client/controllers/client/staticdata/brokerstatics/index", ["exports", "atcm-client/enums/broker-types", "atcm-client/gql/queries/brokers"], function (_exports, _brokerTypes, _brokers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.brokerTypes = _brokerTypes.default;
      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true
      }, {
        displayName: "Short",
        displayField: "descriptionShort",
        alignLeft: true
      }, {
        displayName: "Country",
        displayField: "country.description",
        alignLeft: true
      }, {
        displayName: "City",
        displayField: "city.description",
        alignLeft: true
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Country",
        displayField: "countries",
        queryField: "country",
        displayType: "typeahead",
        isTypeahead: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short description",
        displayField: "descriptionShort",
        required: true
      }, {
        displayName: "Swift - Execution",
        displayField: "executionSwiftCode"
      }, {
        displayName: "BIC - Execution",
        displayField: "executionBicCode"
      }, {
        displayName: "FIX - Execution",
        displayField: "executionFixCode"
      }, {
        displayName: "FINS - Execution",
        displayField: "executionFinsCode"
      }, {
        displayName: "OASYS - Execution",
        displayField: "executionOasysCode"
      }, {
        displayName: "OASYS - Execution (credit)",
        displayField: "executionOasysCode"
      }, {
        displayName: "Country",
        selectionField: "country",
        displayField: "description",
        model: "countries",
        queryFields: ["description", "isoThree", "isoTwo"],
        required: true,
        fieldsToClear: ["city"]
      }, {
        displayName: "City",
        selectionField: "city",
        displayField: "description",
        model: "cities",
        queryFields: ["description"],
        queryParams: {
          calculate: true,
          country: "country.id",
          limit: -1
        },
        required: true,
        requires: ["country"]
      }];
    }

  });

  _exports.default = _default;
});