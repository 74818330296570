define("atcm-client/components/d3-sunburst", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      this.clickedOuterItem = null;
    },

    data: null,
    percentage: null,
    pathPercentage: null,
    displayName: null,
    pathName: null,
    width: 450,
    height: 500,
    elementIsInserted: false,
    partition: null,
    svg: null,
    color: null,
    x: d3.scale.linear().range([0, 2 * Math.PI]),
    visibleName: Ember.computed("displayName", "pathName", function () {
      if (this.pathName) {
        return this.pathName;
      } else {
        return this.displayName;
      }
    }),
    visiblePercentage: Ember.computed("percentage", "pathPercentage", function () {
      if (!Ember.isNone(this.pathPercentage)) {
        return this.pathPercentage;
      } else {
        return this.percentage;
      }
    }),
    radius: Ember.computed("width", "height", function () {
      return Math.min(this.width, this.height) / 2;
    }),
    y: Ember.computed("radius", function () {
      return d3.scale.sqrt().range([0, this.radius]);
    }),
    didInsertElement: function () {
      this.set("color", d3.scale.category20c());
      this.set("partition", d3.layout.partition().sort(null).value(d => {
        return d.size;
      }));
      this.set("elementIsInserted", true);
      this.draw();
    },
    // eslint-disable-next-line ember/no-observers
    draw: Ember.observer("data", function () {
      const self = this;
      let node, path;

      if (!Ember.isNone(this.data) && this.elementIsInserted) {
        // eslint-disable-next-line ember/no-jquery
        (0, _jquery.default)("svg").remove();
        this.set("svg", d3.select("div.sunburst").append("svg").attr("width", this.width).attr("height", this.height).attr("preserveAspectRatio", "xMidYMid").attr("viewBox", "0 0 " + this.width + " " + this.height).append("g").attr("transform", "translate(" + this.width / 2 + "," + (this.height / 2 + 10) + ")")); // Keep track of the node that is currently being displayed as the root.

        node = this.data;
        const root = node;
        path = this.svg.datum(root).selectAll("path").data(self.get("partition").nodes).enter().append("path").attr("d", this.arc).style("fill", d => {
          return self.get("color")((d.children ? d : d.parent).name);
        }).style("opacity", 1).on("mouseover", mouseover).on("click", click).each(this.stash);
        self.set("totalSize", path.node().__data__.value);
        d3.select(".sunburst").on("mouseleave", mouseleave);
        d3.select(self.frameElement).style("height", this.height + "px");
      }

      function mouseleave() {
        d3.selectAll("path").on("mouseover", null); // Transition each segment to full opacity and then reactivate it.

        d3.selectAll("path").transition().duration(1000).style("opacity", 1).each("end", function () {
          d3.select(this).on("mouseover", mouseover);
        });
        self.set("pathPercentage", null);
        self.set("pathName", null);
      } // Fade all but the current sequence, and show it in the breadcrumb trail.


      function mouseover(e) {
        self.set("pathPercentage", (100 * e.value / self.get("totalSize")).toPrecision(4));
        self.set("pathName", e.name);
      }

      function click(d) {
        if (d.hasOwnProperty("children") && d.children.length > 0) {
          node = d;
          path.transition().duration(1000).attrTween("d", self.arcTweenZoom(d));
        } else {
          //remove it, if exists, to add it to the top
          self.set("clickedOuterItem", d);
        }
      }
    }),
    arc: Ember.computed("x", "y", function () {
      const self = this;
      return d3.svg.arc().startAngle(d => {
        return Math.max(0, Math.min(2 * Math.PI, self.get("x")(d.x)));
      }).endAngle(d => {
        return Math.max(0, Math.min(2 * Math.PI, self.get("x")(d.x + d.dx)));
      }).innerRadius(d => {
        return Math.max(0, self.get("y")(d.y));
      }).outerRadius(d => {
        return Math.max(0, self.get("y")(d.y + d.dy));
      });
    }),
    // Setup for switching data: stash the old values for transition.
    stash: function (d) {
      d.x0 = d.x;
      d.dx0 = d.dx;
    },
    // When switching data: interpolate the arcs in data space.
    arcTweenData: function (a
    /*, i*/
    ) {
      const oi = d3.interpolate({
        x: a.x0,
        dx: a.dx0
      }, a);

      function tween(t) {
        const b = oi(t);
        a.x0 = b.x;
        a.dx0 = b.dx;
        return this.arc(b);
      }

      return tween;
    },
    // When zooming: interpolate the scales.
    arcTweenZoom: function (d) {
      const self = this;
      const xd = d3.interpolate(this.x.domain(), [d.x, d.x + d.dx]),
            yd = d3.interpolate(this.y.domain(), [d.y, 1]),
            yr = d3.interpolate(this.y.range(), [d.y ? 20 : 0, this.radius]);
      return function (d, i) {
        if (i) {
          return function () {
            return self.get("arc")(d);
          };
        } else {
          return function (t) {
            self.get("x").domain(xd(t));
            self.get("y").domain(yd(t)).range(yr(t));
            return self.get("arc")(d);
          };
        }
      };
    }
  });

  _exports.default = _default;
});