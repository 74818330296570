define("atcm-client/routes/client/analyses/getPositionPerAssetClass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "getPositionsPerAssetClasses"
      },
      "variableDefinitions": [{
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "economicSector"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "industryGroup"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "assetClass"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "instrumentType"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "country"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "currency"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "assetClasses"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "where"
            },
            "value": {
              "kind": "ObjectValue",
              "fields": [{
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "positions"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": []
                }
              }]
            }
          }],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "id"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "description"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "positions_aggregate"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "where"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "_and"
                    },
                    "value": {
                      "kind": "ListValue",
                      "values": [{
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "assetClassId"
                          },
                          "value": {
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "_eq"
                              },
                              "value": {
                                "kind": "Variable",
                                "name": {
                                  "kind": "Name",
                                  "value": "assetClass"
                                }
                              }
                            }]
                          }
                        }]
                      }, {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "instrumentTypeId"
                          },
                          "value": {
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "_eq"
                              },
                              "value": {
                                "kind": "Variable",
                                "name": {
                                  "kind": "Name",
                                  "value": "instrumentType"
                                }
                              }
                            }]
                          }
                        }]
                      }, {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "gicsIndustryGroupId"
                          },
                          "value": {
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "_eq"
                              },
                              "value": {
                                "kind": "Variable",
                                "name": {
                                  "kind": "Name",
                                  "value": "industryGroup"
                                }
                              }
                            }]
                          }
                        }]
                      }, {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "gicsSectorId"
                          },
                          "value": {
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "_eq"
                              },
                              "value": {
                                "kind": "Variable",
                                "name": {
                                  "kind": "Name",
                                  "value": "economicSector"
                                }
                              }
                            }]
                          }
                        }]
                      }, {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "countryId"
                          },
                          "value": {
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "_eq"
                              },
                              "value": {
                                "kind": "Variable",
                                "name": {
                                  "kind": "Name",
                                  "value": "country"
                                }
                              }
                            }]
                          }
                        }]
                      }, {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "currencyId"
                          },
                          "value": {
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "_eq"
                              },
                              "value": {
                                "kind": "Variable",
                                "name": {
                                  "kind": "Name",
                                  "value": "currency"
                                }
                              }
                            }]
                          }
                        }]
                      }]
                    }
                  }]
                }
              }],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "aggregate"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "sum"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "sumOfIfrsMarketValueAccountCurrency"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }]
                  }
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 640
    }
  };
  var _default = doc;
  _exports.default = _default;
});