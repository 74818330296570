define("atcm-client/models/impact-observation", ["exports", "atcm-client/models/base-model", "moment"], function (_exports, _baseModel, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    _setBio: Ember.observer("questionSix", "questionSeven", function () {
      this.set("bio", this.questionSix || this.questionSeven);
    }),
    niceString: Ember.computed("issuerStatic", "observationDate", function () {
      return this.issuerStatic + " - " + this.observationDate;
    }),
    niceObservationTimestamp: Ember.computed("observationDate", function () {
      if (this.observationDate) {
        return (0, _moment.default)(this.observationDate).format("DD-MM-YYYY");
      }
    }),
    formObservationTimestamp: Ember.computed("observationDate", function () {
      if (this.observationDate) {
        return (0, _moment.default)(this.observationDate).format("YYYY-MM-DD");
      }
    }),
    acceptanceStatus: Ember.computed("approved", "declined", function () {
      if (this.approved) {
        return true;
      } else if (this.declined) {
        return false;
      } else {
        return;
      }
    })
  });

  _exports.default = _default;
});