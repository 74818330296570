define("atcm-client/models/security-price", ["exports", "atcm-client/models/base-model", "moment"], function (_exports, _baseModel, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceString: Ember.computed("price", "priceTimestamp", function () {
      return this.price + " - " + this.priceTimestamp;
    }),
    nicePriceTimestamp: Ember.computed("priceTimestamp", function () {
      if (this.priceTimestamp) {
        return (0, _moment.default)(this.priceTimestamp).format("DD-MM-YYYY hh:mm:ss");
      }
    })
  });

  _exports.default = _default;
});