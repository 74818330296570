define("atcm-client/controllers/client/transactions/foreignexchangecontracts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Account Master",
        displayField: "accountMaster.description"
      }, {
        displayName: "Contract Type",
        displayField: "instrumentType.descriptionShort"
      }, {
        displayName: "Counterparty",
        displayField: "counterpartyStatic.descriptionShort"
      }, {
        displayName: "Pay Amount",
        displayField: "payAmount"
      }, {
        displayName: "Pay Currency",
        displayField: "payCurrency.isoCode"
      }, {
        displayName: "Receive Amount",
        displayField: "receiveAmount"
      }, {
        displayName: "Receive Currency",
        displayField: "receiveCurrency.isoCode"
      }, {
        displayName: "Exchange Rate ",
        displayField: "exchangeRate"
      }, {
        displayName: "Trade Date",
        displayField: "tradeDate"
      }, {
        displayName: "Maturity Date",
        displayField: "maturityDate"
      }];
      this.tableFilters = [{
        displayName: "Account Master",
        displayField: "accountMasters",
        queryField: "accountMaster",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }, {
        displayName: "Contract Type",
        displayField: "instrumentTypes",
        queryField: "instrumentType",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }, {
        displayName: "Counterparty",
        displayField: "counterpartyStatics",
        queryField: "counterpartyStatic",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }];
    },

    editRoute: null
  });

  _exports.default = _default;
});