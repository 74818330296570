define("atcm-client/models/contract-position", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceString: Ember.computed("accountMaster", "instrumentType", "contractNumber", function () {
      return this.accountMaster + " - " + this.instrumentType + " - " + this.contractNumber;
    })
  });

  _exports.default = _default;
});