define("atcm-client/gql/validations/validateCompaniesIssuersAndCompacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "variableDefinitions": [],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "companies"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "where"
            },
            "value": {
              "kind": "ObjectValue",
              "fields": [{
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "description"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "_ilike"
                    },
                    "value": {
                      "kind": "StringValue",
                      "value": "%Amgen%",
                      "block": false
                    }
                  }]
                }
              }]
            }
          }],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "id"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "descriptionShort"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "legalEntityIdentifier"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "issuerStatics"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "description"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "organisationId"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "leiCode"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "securityStatics"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "instrumentType"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "description"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "assetClass"
                          },
                          "arguments": [],
                          "directives": [],
                          "selectionSet": {
                            "kind": "SelectionSet",
                            "selections": [{
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "description"
                              },
                              "arguments": [],
                              "directives": []
                            }]
                          }
                        }]
                      }
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "description"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "securityListings"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "exchangeStatic"
                          },
                          "arguments": [],
                          "directives": [],
                          "selectionSet": {
                            "kind": "SelectionSet",
                            "selections": [{
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "marketIdCode"
                              },
                              "arguments": [],
                              "directives": []
                            }]
                          }
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "currency"
                          },
                          "arguments": [],
                          "directives": [],
                          "selectionSet": {
                            "kind": "SelectionSet",
                            "selections": [{
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "description"
                              },
                              "arguments": [],
                              "directives": []
                            }]
                          }
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "securityPositions"
                          },
                          "arguments": [],
                          "directives": [],
                          "selectionSet": {
                            "kind": "SelectionSet",
                            "selections": [{
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "postTransactionQuantity"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "ifrsBookValueAccountCurrency"
                              },
                              "arguments": [],
                              "directives": []
                            }]
                          }
                        }]
                      }
                    }]
                  }
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "unGlobalCompacts"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "where"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [{
                    "kind": "ObjectField",
                    "name": {
                      "kind": "Name",
                      "value": "companyImpacts"
                    },
                    "value": {
                      "kind": "ObjectValue",
                      "fields": []
                    }
                  }]
                }
              }],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "description"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "leiCode"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "companyImpacts"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "year"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "impactObjectiveMain"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "descriptionShort"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "reportUrl"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "companyImpactPerformances"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "description"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }]
                  }
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 821
    }
  };
  var _default = doc;
  _exports.default = _default;
});