define("atcm-client/controllers/client/impact/unglobalcompacts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFields = [{
        name: "Company",
        valuePath: "description" // alignLeft: true,

      }, // {
      // 	name: "Joined since",
      // 	valuePath: "niceJoinedDate",
      // },
      {
        name: "Country",
        valuePath: "country.isoThree"
      }, {
        name: "Sector",
        valuePath: "unGlobalSector.description"
      }, {
        name: "Type",
        valuePath: "unGlobalType.description"
      }, // {
      // 	name: "Engagement Trier",
      // 	valuePath: "engagementTrier.description",
      // },
      // {
      // 	name: "Ownership",
      // 	valuePath: "ownershipType.description",
      // },
      {
        name: "Status",
        valuePath: "statusUnGlobalCompact.description"
      }];
      this.formFields = [{
        displayName: "Company",
        displayField: "description",
        required: true
      }, // {
      // 	displayName: "Joined since",
      // 	displayField: "joined",
      // 	required: true,
      // },
      {
        displayName: "Country",
        selectionField: "country",
        displayField: "description",
        model: "countries",
        queryFields: ["description", "isoThree", "isoTwo"],
        required: true
      }, {
        displayName: "Sector",
        selectionField: "unGlobalSector",
        displayField: "description",
        model: "unGlobalSectors",
        queryFields: ["description"]
      }, {
        displayName: "Type",
        selectionField: "unGlobalType",
        displayField: "description",
        model: "unGlobalTypes",
        queryFields: ["description"]
      }, // {
      // 	displayName: "Engagement Trier",
      // 	selectionField: "engagementTrier",
      // 	displayField: "description",
      // 	model: "engagementTriers",
      // 	queryFields: ["description"],
      // },
      // {
      // 	displayName: "Ownership Type",
      // 	selectionField: "ownershipType",
      // 	displayField: "description",
      // 	model: "ownershipTypes",
      // 	queryFields: ["description"],
      // },
      {
        displayName: "Status",
        selectionField: "statusUnGlobalCompact",
        displayField: "description",
        model: "statusUnGlobalCompacts",
        queryFields: ["description"]
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Sector",
        displayField: "unGlobalSectors",
        queryField: "unGlobalSector",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Country",
        displayField: "countries",
        queryField: "country",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Type",
        displayField: "unGlobalTypes",
        queryField: "unGlobalType",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Ownership",
        displayField: "ownershipTypes",
        queryField: "ownershipType",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Engagement Trier",
        displayField: "engagementTriers",
        queryField: "engagementTrier",
        displayType: "typeahead",
        isTypeahead: true
      }];
    },

    actions: {
      processActionColumnAction: function (row, action) {
        this.send(action, row);
      },
      showHoldings: function (managedFunds) {
        this.transitionToRoute("client.marketanalyses.managedfunds.holdings", managedFunds);
        return false;
      },

      fetchMore() {
        if (this.get("model.managedFunds.length") % 10 === 0) {
          const variables = {
            limit: 10,
            offset: this.get("model.managedFunds.length")
          };
          this.apollo.query({
            query,
            variables
          }).then(result => {
            this.get("model.managedFunds").pushObjects(result.managedFunds);
          });
        }
      }

    }
  });

  _exports.default = _default;
});