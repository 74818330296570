define("atcm-client/controllers/client/susty/issuerfundamentals/index", ["exports", "atcm-client/gql/queries/issuerFundamentals"], function (_exports, _issuerFundamentals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Issuer",
        displayField: "issuerStatic.description",
        alignLeft: true
      }, {
        displayName: "Ratio",
        displayField: "ratio.description"
      }, {
        displayName: "Value",
        displayField: "value"
      }, {
        displayName: "Datavendor",
        displayField: "ratio.ratioGroupSub.ratioGroupMain.descriptionShort"
      }, {
        displayName: "Source",
        displayField: "ratio.ratioGroupSub.ratioGroupMain.datavendorStatic.descriptionShort"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Issuer",
        displayField: "issuerStatics",
        queryField: "issuerStatic",
        displayType: "typeahead",
        isTypeahead: true
      }, {
        displayName: "Ratio",
        displayField: "ratios",
        queryField: "ratio",
        displayType: "typeahead",
        isTypeahead: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description"
      }, {
        displayName: "Issuer",
        selectionField: "issuerStatic",
        displayField: "description",
        model: "issuerStatics",
        required: true
      }, {
        displayName: "Ratio",
        selectionField: "ratio",
        displayField: "description",
        model: "ratios",
        required: true
      }, {
        displayName: "Value",
        displayField: "value",
        required: true
      }];
    },

    fetchMore() {
      if (this.get("model.issuerFundamentals.length") % 10 === 0) {
        const variables = {
          limit: 10,
          offset: this.get("model.issuerFundamentals.length")
        };
        this.apollo.query({
          query: _issuerFundamentals.default,
          variables
        }).then(result => {
          this.get("model.issuerFundamentals").pushObjects(result.issuerFundamentals);
        });
      }
    },

    editRoute: null
  });

  _exports.default = _default;
});