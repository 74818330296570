define("atcm-client/controllers/client/core/impactobjectivemains/impactobjectivesubs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Impact Objective",
        displayField: "impactObjectiveMain.impactObjective.descriptionShort",
        allignLeft: true
      }, {
        displayName: "Main",
        displayField: "impactObjectiveMain.descriptionShort"
      }, {
        displayName: "Sub",
        displayField: "descriptionShort"
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }, {
        displayName: "Impact Objective",
        displayField: "impactObjectiveMains",
        queryField: "impactObjectiveMain",
        displayType: "typeahead",
        queryFields: ["description", "descriptionShort"],
        isTypeahead: true
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true,
        hideOnAdd: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Short",
        displayField: "descriptionShort",
        required: true
      }, {
        displayName: "Impact Objective",
        selectionField: "impactObjectiveMain",
        displayField: "description",
        model: "impactObjectiveMains",
        queryFields: ["description", "descriptionShort"],
        required: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Impact targets of " + this.get("model.descriptionShort");
    })
  });

  _exports.default = _default;
});