define("atcm-client/models/security-transaction", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceString: Ember.computed("internalTransactionNumber", function () {
      return this.internalTransactionNumber;
    }),
    calculatedNetAmountAccountCurrency: Ember.computed("transactionQuantity", "unitPriceAccountMasterCurrency", function () {
      if (!Ember.isNone(this.transactionQuantity) && !Ember.isNone(this.unitPriceAccountMasterCurrency)) {
        return (this.transactionQuantity * this.unitPriceAccountMasterCurrency).toFixed(2);
      }

      return;
    }),
    netAmountAccountCurrencyAvailable: Ember.observer("calculatedNetAmountAccountCurrency", function () {
      if (!Ember.isNone(this.calculatedNetAmountAccountCurrency)) {
        this.set("netAmountAccountCurrency", this.calculatedNetAmountAccountCurrency);
      }
    }),
    calculatedFeeAmountAccountCurrency: Ember.computed("calculatedNetAmountAccountCurrency", function () {
      if (!Ember.isNone(this.calculatedNetAmountAccountCurrency)) {
        return (this.calculatedNetAmountAccountCurrency * "0.01").toFixed(2);
      }

      return;
    }),
    feeAmountAccountCurrencyAvailable: Ember.observer("calculatedFeeAmountAccountCurrency", function () {
      if (!Ember.isNone(this.calculatedFeeAmountAccountCurrency)) {
        this.set("feeAmountAccountCurrency", this.calculatedFeeAmountAccountCurrency);
      }
    }),
    calculatedGrossAmountAccountCurrency: Ember.computed("feeAmountAccountCurrency", "netAmountAccountCurrency", function () {
      if (!Ember.isNone(this.feeAmountAccountCurrency) && !Ember.isNone(this.netAmountAccountCurrency)) {
        return (parseFloat(this.feeAmountAccountCurrency) + parseFloat(this.netAmountAccountCurrency)).toFixed(2);
      }

      return;
    }),
    grossAmountAccountCurrencyAvailable: Ember.observer("calculatedGrossAmountAccountCurrency", function () {
      if (!Ember.isNone(this.calculatedGrossAmountAccountCurrency)) {
        this.set("grossAmountAccountCurrency", this.calculatedGrossAmountAccountCurrency);
      }
    })
  });

  _exports.default = _default;
});