define("atcm-client/models/currency", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceString: Ember.computed("isoCode", "description", function () {
      return this.isoCode + " - " + this.description;
    }),
    symbol: Ember.computed("unicodeDecimal", function () {
      return Ember.isNone(this.unicodeDecimal) ? "" : Ember.String.htmlSafe("&#" + this.unicodeDecimal + ";");
    })
  });

  _exports.default = _default;
});