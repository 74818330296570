define("atcm-client/models/security-position", ["exports", "atcm-client/models/base-model"], function (_exports, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    niceString: Ember.computed("settlementDate", "securityStaticRicsCode", function () {
      return this.settlementDate + " - " + this.securityStaticRicsCode;
    }),
    securityPositionAccountCurrency: Ember.computed("postTransactionQuantity", "postTransactionAveragePriceAccountCurrency", function () {
      return (this.postTransactionQuantity * this.postTransactionAveragePriceAccountCurrency).toFixed(2);
    }),
    securityPositionSecurityCurrency: Ember.computed("postTransactionQuantity", "postTransactionAveragePriceSecurityCurrency", function () {
      return (this.postTransactionQuantity * this.postTransactionAveragePriceSecurityCurrency).toFixed(2);
    }),
    securityPositionAverageCostsPrice: Ember.computed("postTransactionAveragePriceAccountCurrency", function () {
      return this.postTransactionAveragePriceAccountCurrency.toFixed(5);
    })
  });

  _exports.default = _default;
});