define("atcm-client/routes/client/staticdata/futuresstatics/futuresstaticdeliverymonths", ["exports", "atcm-client/routes/secure"], function (_exports, _secure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secure.default.extend({
    model: function (params) {
      return this.store.findRecord("futuresStatic", params.futuresStatic_id);
    },
    setupController: function (controller, model) {
      return this.store.query("futuresStaticDeliveryMonth", {
        filter: {
          futuresStaticId: model.get("id")
        }
      }).then(futuresstaticdeliverymonths => {
        controller.set("futuresstaticdeliverymonths", futuresstaticdeliverymonths);
        controller.set("model", model);
        return;
      });
    }
  });

  _exports.default = _default;
});