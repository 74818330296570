define("atcm-client/templates/client/impact/companyimpacts/performances", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aNr/+Dbd",
    "block": "{\"symbols\":[],\"statements\":[[8,\"gql-table\",[],[[\"@dataQuery\",\"@dataVariables\",\"@title\",\"@type\",\"@fields\",\"@filters\",\"@formFields\",\"@back\",\"@data\"],[[34,0],[34,1],[34,2],\"impact_companyImpactPerformances\",[34,3],[34,4],[34,5],\"client.impact.companyimpacts.index\",[34,6]]],null]],\"hasEval\":false,\"upvars\":[\"query\",\"dataVariables\",\"title\",\"tableFields\",\"tableFilters\",\"formFields\",\"data\"]}",
    "meta": {
      "moduleName": "atcm-client/templates/client/impact/companyimpacts/performances.hbs"
    }
  });

  _exports.default = _default;
});