define("atcm-client/controllers/client/staticdata/custodianstatics/entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.countryQueryFields = ["description", "isoThree", "isoTwo"];
      this.cityQueryFields = ["description"];
    },

    _hqCountrySelected: Ember.observer("model.hqCountry.id", function () {
      if (!Ember.isNone(this.get("model.hqCountry.id"))) {
        this.set("cityQueryParams", {
          country: this.get("model.hqCountry.id"),
          limit: -1
        });
      }
    }),
    actions: {
      save: function () {
        const self = this;
        this.content.save().then(custodianStatic => {
          self.transitionToRoute("staticdata.custodianstatics.edit", custodianStatic);
        });
      },
      rollback: function () {
        const self = this;
        this.content.rollbackAttributes();
        self.transitionToRoute("staticdata.custodianstatics");
      }
    }
  });

  _exports.default = _default;
});