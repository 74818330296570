define("atcm-client/controllers/client/models/investmentprofiles/investmentprofileratios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.tableFields = [{
        displayName: "Description",
        displayField: "description",
        alignLeft: true
      }, {
        displayName: "Value",
        displayField: "value"
      }];
      this.formFields = [{
        displayName: "Created at",
        displayField: "createdAt",
        disabled: true,
        date: true
      }, {
        displayName: "Description",
        displayField: "description",
        required: true
      }, {
        displayName: "Value",
        displayField: "value",
        required: true,
        date: true
      }];
      this.tableFilters = [{
        displayName: "Search...",
        isText: true
      }];
    },

    title: Ember.computed("model", function () {
      return "Ratios profile: " + this.get("model.description");
    })
  });

  _exports.default = _default;
});