define("atcm-client/controllers/client/compliance/compliancerulevalues/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.complianceRules = [];
      this.tableFields = [{
        displayName: "Description",
        displayField: "description"
      }, {
        displayName: "Desc Short",
        displayField: "descriptionShort"
      }, {
        displayName: "Value",
        displayField: "value"
      }];
    },

    editRoute: null
  });

  _exports.default = _default;
});